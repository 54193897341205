<template>
  <div data-v-62445eaa="" id="frame">
    <div data-v-62445eaa="" class="content">
      <div data-v-62445eaa="" class="messages">
        <ul data-v-62445eaa="" class="p-0 m-0">
          <li data-v-62445eaa="" class="replies">
            <img data-v-62445eaa="" src="images/chat-user.png" alt="" class="avatar" />
            <div data-v-62445eaa="" class="container message">
              <div data-v-62445eaa="" class="row message-text">
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-00"
                    src="https://www.poltronesofa.com/upload/assistenze/2023/2023-AS-24579-10-3868440-20230413-760.jpg"
                  />
                </div>
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-10"
                    src="https://www.poltronesofa.com/upload/assistenze/2023/2023-AS-24579-10-3868440-20230413-758.png"
                  />
                </div>
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-20"
                    src="https://www.poltronesofa.com/upload/assistenze/2023/2023-AS-24579-10-3868440-20230413-759.jpeg"
                  />
                </div>
                <p data-v-62445eaa="">test</p>
              </div>
              <div data-v-62445eaa="" class="message-data text-right mt-1">
                <span data-v-62445eaa="" class="d-block fs-14"
                  >13/04/2023 - 12:11:20</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div data-v-62445eaa="" class="content">
      <div data-v-62445eaa="" class="messages">
        <ul data-v-62445eaa="" class="p-0 m-0">
          <li data-v-62445eaa="" class="replies">
            <img data-v-62445eaa="" src="images/chat-user.png" alt="" class="avatar" />
            <div data-v-62445eaa="" class="container message">
              <div data-v-62445eaa="" class="row message-text">
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-01"
                    src="https://www.poltronesofa.com/upload/assistenze/2023/2023-AS-24579-10-3869217-20230413-962.jpg"
                  />
                </div>
                <p data-v-62445eaa="">test</p>
              </div>
              <div data-v-62445eaa="" class="message-data text-right mt-1">
                <span data-v-62445eaa="" class="d-block fs-14"
                  >13/04/2023 - 16:18:52</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div data-v-62445eaa="" class="content">
      <div data-v-62445eaa="" class="messages">
        <ul data-v-62445eaa="" class="p-0 m-0">
          <li data-v-62445eaa="" class="replies">
            <img data-v-62445eaa="" src="images/chat-user.png" alt="" class="avatar" />
            <div data-v-62445eaa="" class="container message">
              <div data-v-62445eaa="" class="row message-text">
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-02"
                    src="https://www.poltronesofa.com/upload/assistenze/2023/2023-AS-24579-10-3869372-20230413-008.jpeg"
                  />
                </div>
                <p data-v-62445eaa="">yrdyryrdytyr</p>
              </div>
              <div data-v-62445eaa="" class="message-data text-right mt-1">
                <span data-v-62445eaa="" class="d-block fs-14"
                  >13/04/2023 - 16:49:45</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div data-v-62445eaa="" class="content">
      <div data-v-62445eaa="" class="messages">
        <ul data-v-62445eaa="" class="p-0 m-0">
          <li data-v-62445eaa="" class="replies">
            <img data-v-62445eaa="" src="images/chat-user.png" alt="" class="avatar" />
            <div data-v-62445eaa="" class="container message">
              <div data-v-62445eaa="" class="row message-text">
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-03"
                    src="/images/icons/video.png"
                  />
                </div>
                <div data-v-62445eaa="" class="thumb-box">
                  <img
                    data-v-62445eaa=""
                    class="image-wrapper lightbox-image"
                    id="imageChat-13"
                    src="/images/icons/video.png"
                  />
                </div>
                <p data-v-62445eaa="">test</p>
              </div>
              <div data-v-62445eaa="" class="message-data text-right mt-1">
                <span data-v-62445eaa="" class="d-block fs-14"
                  >13/04/2023 - 16:53:01</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div data-v-62445eaa="" class="content">
      <div data-v-62445eaa="" class="messages">
        <ul data-v-62445eaa="" class="p-0 m-0">
          <li data-v-62445eaa="" class="replies">
            <img data-v-62445eaa="" src="images/chat-user.png" alt="" class="avatar" />
            <div data-v-62445eaa="" class="container message">
              <div data-v-62445eaa="" class="row message-text">
                <p data-v-62445eaa="">trdt</p>
              </div>
              <div data-v-62445eaa="" class="message-data text-right mt-1">
                <span data-v-62445eaa="" class="d-block fs-14"
                  >13/04/2023 - 17:49:15</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const handleArrowKeys = (event, images, currentIndex) => {
  if (event.key === "ArrowLeft") {
    openLightbox(images[(currentIndex - 1 + images.length) % images.length]);
  } else if (event.key === "ArrowRight") {
    openLightbox(images[(currentIndex + 1) % images.length]);
  }
};

const openLightbox = (img) => {
  const lightbox = document.createElement("div");
  lightbox.className = "lightbox";
  lightbox.innerHTML = `
    <div class="lightbox-content">
      <img src="${img.src}" alt="${img.alt}" />
    </div>
    <div class="lightbox-close">&times;</div>
    <div class="lightbox-navigation">
      <div class="lightbox-prev">&lt;</div>
      <div class="lightbox-next">&gt;</div>
    </div>
  `;
  document.body.appendChild(lightbox);

  const closeButton = lightbox.querySelector(".lightbox-close");
  closeButton.addEventListener("click", () => {
    document.body.removeChild(lightbox);
    document.removeEventListener("keydown", handleArrowKeys);
  });

  const container = containerRef.value;
  const images = container.querySelectorAll(".lightbox-image");
  const currentIndex = Array.from(images).indexOf(img);

  const handleKeyDown = (event) => {
    handleArrowKeys(event, images, currentIndex);
  };

  document.addEventListener("keydown", handleKeyDown);

  const prevButton = lightbox.querySelector(".lightbox-prev");
  prevButton.addEventListener("click", () => {
    openLightbox(images[(currentIndex - 1 + images.length) % images.length]);
  });

  const nextButton = lightbox.querySelector(".lightbox-next");
  nextButton.addEventListener("click", () => {
    openLightbox(images[(currentIndex + 1) % images.length]);
  });
};

const containerRef = ref(null);

onMounted(() => {
  containerRef.value = document.getElementById("frame");
  const images = containerRef.value.querySelectorAll(".lightbox-image");
  for (let i = 0; i < images.length; i++) {
    const img = images[i];
    console.log(img);
    img.addEventListener("click", () => {
      openLightbox(img);
    });
  }
});
</script>

<style>
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-content {
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  text-align: center;
}

.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.lightbox-navigation {
  display: flex;
}
</style>
