export default {
  "seo": {
    "index": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteuningsgebied - poltronesofà"])}
    }
  },
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
  "commons": {
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
  },
  "pages": {
    "login": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u hulp nodig?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u een account maken?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "confirm-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesvolle registratie"])},
      "recover-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bent u uw wachtwoord vergeten?"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u geen bevestigingsmail ontvangen?"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Hebt u nog geen account?"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u al een account?"])},
      "back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de login"])},
      "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord bevestigen"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
      "report-a-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een probleem melden"])}
    },
    "register": {
      
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welkom ", _interpolate(_named("nameOfUser"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe kunnen wij u helpen?"])},
      "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar een onderwerp"])},
      "button": {
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestellingen"])},
        "assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw tickets"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw profiel"])},
        "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasinstructies"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "download-warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De garantie downloaden"])},
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
        "home-area-riservata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdmenu"])}
      }
    },
    "orders": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestellingen"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelnummer"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkel"])},
      "total-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaalbedrag"])},
      "to-pay-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterende kosten"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ticket openen"])},
      "ticket-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket geopend"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalen"])},
      "no-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn momenteel geen bestellingen"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "go-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar de berichten"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste bijwerking"])},
      "add-order-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een bestelling aan de lijst toevoegen"])}
    },
    "order": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestellingen"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelnr."])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
      "show-ticket-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk het openstaande ticket"])},
      "message-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle producten die bij de huidige bestelling zijn gekocht, staan hieronder vermeld"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnr."])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid"])},
      "code-article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasinstructies"])},
      "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkel"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ticket openen"])},
      "show-all-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zie alle bestellingen"])}
    },
    "order-payment-response": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling"])},
      "error-contact-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden tijdens de betalingsregistratie. Neem contact op met de referentie shop"])}
    },
    "assistances": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw tickets"])},
      "ticket-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketnummer"])},
      "data-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openingsdatum"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelnummer"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste bijwerking"])},
      "no-assistances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen open ticket"])},
      "inserita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Ingevoerd"])},
      "respinta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Afgewezen"])},
      "chiusa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Gesloten"])},
      "inlavorazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket In verwerking"])},
      "inattesadielementi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket In afwachting van elementen"])},
      "invisione (attesa rientro)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Ter inzage"])}
    },
    "open-assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ticket openen"])},
      "assistance-hour-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er mag geen ticket tussen 01:00 en 04:00 geopend worden"])},
      "global-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u een ticket openen? Probeer eerst <a href='/home' style='text-decoration: underline;'>onze veelgestelde vragen</a> te raadplegen.<br />Het wordt ook aanbevolen om een nieuwe hulpvraag in te voeren vanaf een mobiele telefoon."])},
      "step-one": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de bestelling"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer uw bestelling uit het menu"])},
        "find-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunt u uw bestelling in de bovenstaande lijst niet terugvinden? Klik hier en volg de instructies om uw bestelling op te halen en te koppelen aan uw account"])},
        "has-assistances-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, er is een open ticket op deze bestelling. Het is niet mogelijk om verder te gaan. <u style='color: #d41317'>Ga naar uw servicepagina</u>"])},
        "is-not-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bestelling komt niet in aanmerking voor service omdat deze niet is afgeleverd. Neem contact op met de desbetreffende winkel voor informatie over de bezorging."])}
      },
      "step-two": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Kies het product"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer het product waarvoor een ticket moet worden geopend"])}
      },
      "step-three": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijf het probleem en voeg foto's en video's toe"])},
        "label-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beschrijf het probleem en voeg ten minste ", _interpolate(_named("maxFiles")), " foto's en/of video's toe (maximaal ", _interpolate(_named("maxSize")), ").\nDe volgende formaten zijn toegestaan, ", _interpolate(_named("ext")), "."])},
        "label-relax-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als een ontspanningsmechanisme niet goed werkt, stuur ons dan een video waarop het probleem te zien is."])},
        "label-description-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplicht veld"])},
        "label-warning-file-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, er moeten minstens 3 bestanden worden geüpload."])},
        "label-warning-file-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, de bestandsgrootte overschrijdt de limiet van 30MB totaal"])},
        "label-warning-file-extension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Houd er rekening mee dat de volgende bestandsextensies niet worden ondersteund: ", _interpolate(_named("ext"))])}
      },
      "step-four": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifieer"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw gegevens en verstuur"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de ingevoerde gegevens en verstuur het ticket"])}
      }
    },
    "wash": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasinstructies"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdmenu"])}
    },
    "faq-category": {
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben deze mogelijke oplossingen gevonden"])},
      "button-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar het hoofdmenu"])},
      "call-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je dit niet kunt oplossen, raden we je aan contact op te nemen met de winkel waar je de aankoop hebt gedaan."])}
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw profiel"])},
      "second-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel uw profiel in"])},
      "label-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig de navigatietaal"])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig uw wachtwoord"])}
      },
      "text-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik deze optie om de navigatietaal van uw account te wijzigen"])}
      }
    }
  },
  "header": {
    "menu": {
      "poltronesofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdmenu"])},
      "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestellingen"])},
      "my-tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw tickets"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ticket openen"])},
      "maintenance-booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderhoudsboekje"])},
      "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw profiel"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])}
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meest bekeken vragen"])},
    "text-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà S.p.A., C.F. e P.IVA. 03613140403, Valsamoggia (BO) - Loc. Crespellano, Via Lunga n. 16, Registro delle Imprese di Bologna REA BO – 462239, Capitale sociale i.v. Euro 250.000,00 "])},
    "text-link-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookiebeleid"])},
    "text-link-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])},
    "text-link-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkels"])},
    "text-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © poltronesofa S.p.A 2023 - Alle rechten voorbehouden"])}
  },
  "error": {
    "form-validation": {
      "name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw voornaam is vereist"])},
      "lastname-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw achternaam is vereist"])},
      "telephone-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw telefoonnummer is vereist"])},
      "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres is vereist"])},
      "email-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres is niet geldig"])},
      "email-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De e-mailadressen moeten overeenkomen"])},
      "email-confirm-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bevestiging is niet geldig"])},
      "password-lenght": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord moet minimaal 8 tekens bevatten"])},
      "password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord is vereist"])},
      "old-password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het oude wachtwoord is vereist"])},
      "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wachtwoorden moeten overeenkomen"])},
      "privacy-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" U moet het Privacybeleid aanvaard hebben"])},
      "passwords-match-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoordbevestiging is vereist"])}
    }
  },
  "components": {
    "faq": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was deze informatie nuttig?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "open-assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ticket openen"])}
    },
    "order-row-detail": {
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "other-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overige gegevens"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelcode"])}
    },
    "open-assistance": {
      "open": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voeg ten minste ", _interpolate(_named("maxFiles")), " foto’s en video’s"])}
    },
    "order-row-wash": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasinstructies"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere nuttige informatie"])},
      "composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compositie"])}
    },
    "assistance-chat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketnummer: "])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste update: "])},
      "text-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht invoegen"])},
      "response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ontvangt binnen 4 werkdagen een antwoord."])}
    }
  },
  "form": {
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik uw e-mailadres in de bestelling om het direct te importeren"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud wachtwoord"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
      "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal het nieuwe wachtwoord"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw e-mailadres in en controleer uw inbox. U zal een e-mail met alle instructies ontvangen."])},
      "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstellen"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u zich net hebt geregistreerd en geen e-mail hebt ontvangen, controleer dan uw spamfolder of voer hier het e-mailadres in dat u voor de registratie hebt gebruikt en controleer uw inbox."])},
      "resend-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigingsmail opnieuw sturen"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf u in om op de hoogte te blijven van uw bestellingen of serviceverzoeken."])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u zich inschrijven?"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gsm-nummer"])},
      "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres bevestigen"])},
      "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord bevestigen"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder"])},
      "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzend"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
      "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met opslaan..."])},
      "warranty-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantiecode"])},
      "warrantyCodeTooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U vindt uw garantiecode in uw ", _interpolate(_named("order")), ", in de ", _interpolate(_named("warranty")), " of door contact op te nemen met uw winkel."])},
      "warrantyCodeTooltipOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestelling"])},
      "warrantyCodeTooltipWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["garantie"])},
      "add-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppelen"])},
      "read-understood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb gelezen en begrepen"])},
      "link-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op de link om een probleem te melden"])},
      "contant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het veld is verplicht"])},
      "privacy": {
        "part-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik verklaar dat ik"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["het Privacybeleid"])},
        "part-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heb gelezen en begrepen in overeenstemming met artikels 13 en 14 van de Verordening (EU) 2016/679"])}
      }
    },
    "placeholder": {
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw e-mailadres in"])}
    },
    "field": {
      "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantbestand"])}
    }
  },
  "modal": {
    "assistance": {
      "add-order-number": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling correct gekoppeld"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestelling is nu zichtbaar in uw klantenaccount."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het invoeren van de bestelling"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bestelling kan niet worden teruggevonden"])}
        }
      },
      "post-assistance": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ingevoerd"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We bevestigen dat uw assistentie succesvol is ingevoerd."])}
        },
        "error-response": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij invoeren ticket"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen antwoord van de server ontvangen, niet-identificeerbare fout"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij ticketinvoer"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "post-chat-message": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij ticketinvoer"])}
        }
      }
    },
    "auth": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij gegevensinvoer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "orders": {
      "load-orders": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutmelding:"])}
        }
      },
      "download-warranty": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutmelding:"])}
        }
      },
      "send-order": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verzoek kan niet worden verzonden "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn problemen met de te verzenden gegevens"])}
        }
      }
    },
    "user": {
      "post-registration-user": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruiker is succesvol geregistreerd"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw mailbox om uw registratie te voltooien"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar de login"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij aanmaken gebruiker."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "send-activation": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verzoek is verzonden"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw mailbox of spam en klik op de link die u in het bericht vindt"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password-email": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verzoek is verzonden"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw mailbox of spam en klik op de link die u in het bericht vindt"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het verzenden van gegevens"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "update-language": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het veranderen van taal"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is succesvol gewijzigd"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij wijzigen wachtwoord"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error-no-token-username": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er heeft zich een fout voorgedaan."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag de e-mail voor het wijzigen van het wachtwoord opnieuw aan."])}
        }
      }
    },
    "expire-session": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie verlopen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet inloggen"])}
    }
  },
  "payment_status_be": {
    "authorized": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisatie succesvol afgerond"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De betaling was succesvol. De factuur is betaald.\nGa terug naar het hoofdmenu om verder te surfen."])}
    },
    "canceled": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie mislukt"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde gegevens zijn niet correct. Probeer het opnieuw."])}
    },
    "3ds_authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3DS-authenticatie mislukt"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3DS-authenticatie mislukt: de transactie is beëindigd."])}
    },
    "token_expired": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlopen token"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De token is verlopen"])}
    },
    "transaction_aborted": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione interrotta"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De transactie werd beëindigd door de betaler."])}
    },
    "validation_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validatie mislukt"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "amount_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig bedrag"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrag voldoet niet aan de beperkingen voor deze actie. Het kan bijvoorbeeld het toegestane limietbedrag overschrijden."])}
    },
    "authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie mislukt"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een verkeerd wachtwoord ingevoerd."])}
    },
    "card_check_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige kaart- of CVC-nummer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden. Voer het juiste kaart- of CVC-nummer in."])}
    },
    "card_cvc_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige CVC-nummer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden. Voer het juiste CVC-nummer in."])}
    },
    "card_cvc_required": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC-nummer niet ingevoerd maar vereist"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er is een fout opgetreden. Voer het juiste CVC-nummer in."])}
    },
    "communication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De communicatie met de processor is mislukt."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "currency_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrecte valutacode"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De valuta niet overeenkomt met de valuta van de referentietransactie."])}
    },
    "permission_denied": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geweigerd vergunning"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "transaction_declined": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geweigerd vergunning"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "update_card_information": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Kaartgegevens bijwerken"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kaartgegevens moeten worden bijgewerkt voordat de betaling succesvol kan worden uitgevoerd."])}
    },
    "check_store_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het registreren van de betaling."])}
  }
}