<template>
  <MainHeader />
  <router-view />
  <MainFooter :route="currentRoute" />
</template>

<script setup>
import { useRouter } from "vue-router";
import { computed } from "vue";
import MainHeader from "../layouts/partials/header/MainHeader.vue";
import MainFooter from "../layouts/partials/footer/MainFooter.vue";

const currentRoute = computed(() => {
  return useRouter().currentRoute.value.name;
});
</script>

<script>
export default {
  name: "PublicLayout",
};
</script>
