import { createApp} from "vue";
import App from "./App.vue";
import './registerServiceWorker'
import router from './router'
import store from './store'
import config from './config'
import { storeToRefs } from "pinia";
import { pinia } from './pinia'
import i18n from './i18n';
import { Tooltip } from "bootstrap";
import clickOutSide from './directives/clickOutside';
import filters from './helpers/filters';
import { helpers } from './helpers/helpers'
/*Registro i componenti globali*/
import errorHandler from '@/components/ErrorHandler'
import Vue3MobileDetection from "vue3-mobile-detection";
import 'animate.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//import states from "../assets/data.json";
const app = createApp(App)
            .use(store)
            
            .use(pinia)
            .use(router)            
            .use(i18n)
            
            app.use(Vue3MobileDetection)
            .directive("click-outside", clickOutSide)

            //todo: portare filter in mixin
            //.mixin()
        app.component("error-handler", errorHandler);
        app.mount("#app");
/*
        app.config.errorHandler = (err) => {
            console.log(err);
         }
*/
app.directive("tooltip", (el) => {
    new Tooltip(el);
  });

import { useAuthStore } from '@/stores';

const {
  checkLangBrowser
} = helpers();



const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);
const { token: authUserToken } = storeToRefs(authStore);

//console.log(authUserToken.value);
//console.log(authStore.getLang);
//console.log(checkLangBrowser());
let lang;
if(authStore.getLang  == null){
   lang = checkLangBrowser();
}else{
   lang = authStore.getLang;
}

i18n.global.locale.value = lang;
app.config.globalProperties.authUser = authUser;
app.config.globalProperties.authUserToken = authUserToken;

app.config.globalProperties.config = config
app.config.globalProperties.$filters = filters;




import "bootstrap/dist/js/bootstrap.js";
