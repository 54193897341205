<script setup>
import { onMounted, ref } from "vue";
import { useUser } from "../use/useUser.js";
import { useAuthStore } from "@/stores";
//import { useRegistrationStore } from "@/stores";
import { Form, Field } from "vee-validate";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

onMounted(()=>{
  document.title = t('seo.index.title')
})

/*
const dataFromRegistration = useRegistrationStore();
console.log(dataFromRegistration.getUserData);
*/
const route = useRoute();

const paramFromUrlQuery = route.query.error_msg;
const confirm_registration = route.query.confirm_registration;

const { sendActivation, changePasswordEmail, changeStep, currentStep } = useUser();

const emailSendActivation = ref();
const finishSendActivation = () => {
  sendActivation(emailSendActivation.value);
  emailSendActivation.value = "";
};

const emailChangePassword = ref();
const finishChangePassword = () => {
  changePasswordEmail(emailChangePassword.value);
  emailChangePassword.value = "";
  changeStep(0);
};

const authStore = useAuthStore();
const errorLogin = ref({
  username: '',
  password:''
})
function onSubmit(values, { setErrors }) {
  const { username, password } = values;
  errorLogin.value.username = !username;
  errorLogin.value.password = !password;
  if ((!username) || (!password)){
    return false;
  }
  return authStore
    .login(username.toLowerCase().trim(), password,route.query.next)
    .catch((error) => setErrors({ apiError: error }));
}

const showPassword = ref(false);

</script>

<template>
  <div
    class="d-flex align-items-center overflow-auto"
    style="
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('/images/backgrounds/bg-poltronesofa.jpg');
    "
  >
    <div class="container">
      <div class="row align-items-center vh-100">
        <div class="col-md-6 col-12 mx-auto">
          <div class="card mb-6 shadow-lg-4-white">
            <div class="text-center mt-5 mb-2">
              <a
                href="https://www.poltronesofa.com/"
                target="_blank"
                class="fs-16 fw-bold"
                ><img src="/images/logo.svg" class="normal-logo"
              /></a>
            </div>
            <div v-if="paramFromUrlQuery" class="text-center mb-1">
              {{ paramFromUrlQuery }}
            </div>
            <div class="mt-2 text-center">
              <!-- <router-link to="/register-user" class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2"> -->
              <router-link to="/register-user" style="text-decoration: underline; text-underline-offset: 2px;" class="fs-16 fw-bold fs-6 text-success-400 ">
                {{ $t("pages.login.register") }}
              </router-link>
            </div>
            <div class="card-body px-6 py-0 login" v-if="currentStep === 0">
              <Form @submit="onSubmit">
                <div class="form-group">
                  <div
                    class="text-center alert alert-success my-1"
                    v-if="confirm_registration == 'true'"
                  >
                    {{ $t("pages.login.confirm-registration") }}
                  </div>
                  <label for="title" class="text-heading">
                    {{ $t("form.label.email") }}
                  </label>
                  <Field
                    name="username"
                    type="mail"
                    autocomplete="username email"
                    class="form-control form-control-lg"
                    :class="{ 'is-invalid': errorLogin.username }"
                  />
                  <div class="invalid-feedback">{{ $t("form.label.required") }}</div>
                </div>
                <div class="form-group">
                  <label for="title" class="text-heading">
                    {{ $t("form.label.password") }}</label
                  >
                  <div style="position: relative;">              
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      id="password"
                      autocomplete="current-password"
                      class="form-control form-control-lg"
                      :class="{ 'is-invalid': errorLogin.password }"
                    />                                      
                    <span class="toggle-password" @click="showPassword = !showPassword">
                      <i v-if="!showPassword" class="fas fa-eye"></i>
                      <i v-if="showPassword" class="fas fa-eye-slash"></i>
                    </span>
                  </div>
                  
                  <div class="invalid-feedback">{{ $t("form.label.required") }}</div>
                                    
                </div>
                <div class="text-center">
                  <button
                    :disabled="authStore.isSubmitting"
                    class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2"
                  >
                    {{ $t("form.label.login") }}
                    <span
                      v-show="authStore.isSubmitting"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                    <span class="d-inline-block ml-2 fs-16"
                      ><i class="fal fa-long-arrow-right"></i
                    ></span>
                  </button>
                </div>
              </Form>
              <div class="mt-5 text-center pb-2">
                <a href="#" @click="changeStep(1)" class="fs-16 fw-bold">
                  {{ $t("pages.login.help") }}</a
                ><br />
              </div>

              <div class="text-center pb-5">
                <a href="#" @click="changeStep(2)" class="fs-16 fw-bold">
                  <u>{{ $t("pages.login.recover-password") }}</u>
                </a>
                <br />
              </div>
              
            </div>

            <div class="card-body p-6 recover-password" v-if="currentStep === 1">
              <div id="accordion-style-02" class="accordion accordion-02">
                <div
                  class="card border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-1 pr-5 position-relative"
                >
                  <div class="card-header border-0 bg-white p-0" id="headingFour">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-accordion btn-link fs-16 font-weight-500 p-0 border-0 collapsed"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        {{ $t("pages.login.report-a-problem") }}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordion-style-02"
                  >
                    <div class="my-2 fs-14">{{ $t("form.label.link-to-contact") }}</div>

                    <div class="text-center">
                      <a
                        href="https://www.poltronesofa.com/Contatti/"
                        class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                        >{{ $t("form.label.contant") }}
                        <span class="d-inline-block ml-2 fs-16"
                          ><i class="fal fa-long-arrow-right"></i></span
                      ></a>
                    </div>
                  </div>
                </div>

                <div
                  class="card border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-1 pr-5 position-relative"
                >
                  <div class="card-header border-0 bg-white p-0" id="headingTwo">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-accordion btn-link fs-16 font-weight-500 p-0 border-0 collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        {{ $t("pages.login.email-not-sent") }}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion-style-02"
                  >
                    <div class="my-2 fs-14">
                      {{ $t("form.label.email-not-sent") }}
                    </div>

                    <form
                      ref="sendMessageToChat"
                      id="sendMessageToChat"
                      @submit.prevent="finishSendActivation()"
                    >
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          id="title"
                          v-model="emailSendActivation"
                          :placeholder="$t('form.placeholder.writeEmail')"
                        />
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          :disabled="!!!emailSendActivation"
                          :class="{ disabled: !!!emailChangePassword }"
                          class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                        >
                          {{ $t("form.label.resend-confirmation") }}
                          <span class="d-inline-block ml-2 fs-16"
                            ><i class="fal fa-long-arrow-right"></i
                          ></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="card border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-1 pr-5 position-relative"
                >
                  <div class="card-header border-0 bg-white p-0" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-accordion btn-link fs-16 font-weight-500 p-0 border-0 collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {{ $t("pages.login.recover-password") }}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion-style-02"
                  >
                    <div class="my-2 fs-14">{{ $t("form.label.remember-password") }}</div>
                    <form
                      ref="changePassword"
                      id="changePassword"
                      @submit.prevent="finishChangePassword()"
                    >
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          id="title"
                          v-model="emailChangePassword"
                          :placeholder="$t('form.placeholder.writeEmail')"
                        />
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          :disabled="!!!emailChangePassword"
                          :class="{ disabled: !!!emailChangePassword }"
                          class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                        >
                          {{ $t("form.label.recover") }}
                          <span class="d-inline-block ml-2 fs-16"
                            ><i class="fal fa-long-arrow-right"></i
                          ></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  class="card border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-1 pr-5 position-relative"
                >
                  <div class="card-header border-0 bg-white p-0" id="headingThree">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-accordion btn-link fs-16 font-weight-500 p-0 border-0 collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        {{ $t("pages.login.not-registered") }}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion-style-02"
                  >
                    <div class="my-2 fs-14">{{ $t("form.label.not-registered") }}</div>

                    <div class="text-center">
                      <router-link
                        to="/register-user"
                        class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                        >{{ $t("form.label.register") }}
                        <span class="d-inline-block ml-2 fs-16"
                          ><i class="fal fa-long-arrow-right"></i></span
                      ></router-link>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-5 text-center">
                <a href="#" @click="changeStep(0)" class="fs-16 fw-bold">{{
                  $t("pages.login.back-login")
                }}</a
                ><br />
              </div>
            </div>
            <div class="card-body p-6 recover-password" v-if="currentStep === 2">
              <div class="mb-4 fs-14">{{ $t("form.label.remember-password") }}</div>
              <form
                ref="changePassword"
                id="changePassword"
                @submit.prevent="finishChangePassword()"
              >
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id="title"
                    v-model="emailChangePassword"
                    :placeholder="$t('form.placeholder.writeEmail')"
                  />
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    :disabled="!!!emailChangePassword"
                    :class="{ disabled: !!!emailChangePassword }"
                    class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                  >
                    {{ $t("form.label.recover") }}
                    <span class="d-inline-block ml-2 fs-16"
                      ><i class="fal fa-long-arrow-right"></i
                    ></span>
                  </button>
                </div>
              </form>
              <div class="mt-5 text-center">
                <a href="#" @click="changeStep(0)" class="fs-16 fw-bold">{{
                  $t("pages.login.back-login")
                }}</a
                ><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.toggle-password {
    position: absolute;
    top: 30%;
    right: 1rem;
    cursor: pointer;
    user-select: none;
}
</style>