export default {
  "seo": {
    "index": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area di supporto - poltronesofà"])}
    }
  },
  "commons": {
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])}
  },
  "pages": {
    "login": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha bisogno di aiuto?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuole registrarsi?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedere"])},
      "confirm-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente attivato correttamente"])},
      "recover-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ricorda la password?"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ha ricevuto l'email di conferma?"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è registrato?"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È già registrato?"])},
      "back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornare alla login"])},
      "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermi la password"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
      "report-a-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnali un problema"])}
    },
    "register": {
      
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come possiamo aiutarla,"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("nameOfUser")), "?"])},
      "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercare un argomento"])},
      "button": {
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suoi ordini"])},
        "assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sue richieste d'assistenza"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il suo profilo"])},
        "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni di lavaggio"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "download-warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarichi la garanzia"])},
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esca dalla sua area riservata"])},
        "home-area-riservata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menù principale"])}
      }
    },
    "orders": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suoi ordini"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ordine"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])},
      "total-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo totale"])},
      "to-pay-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo residuo"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedere assistenza"])},
      "ticket-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza aperta"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagare"])},
      "no-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attualmente non ci sono ordini"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "go-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzare i messaggi"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
      "add-order-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un ordine alla lista"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattura"])},
      "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagato"])},
      "payed-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine pagato completamente"])}
    },
    "order": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suoi ordini"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine n°."])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "show-ticket-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di assistenza aperta"])},
      "message-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sotto in elenco sono riportati tutti i prodotti acquistati con l'ordine corrente"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod. articolo"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "code-article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice articolo"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])},
      "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavaggio"])},
      "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garanzia"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedere assistenza"])},
      "show-all-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suoi ordini"])}
    },
    "order-payment-response": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
      "error-contact-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la registrazione del pagamento. Contattare il negozio di riferimento"])}
    },
    "assistances": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sue richieste di assistenza"])},
      "ticket-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero assistenza"])},
      "data-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data apertura"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ordine"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
      "no-assistances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna assistenza aperta"])},
      "inserita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserita"])},
      "respinta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respinta"])},
      "chiusa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusa"])},
      "inlavorazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In lavorazione"])},
      "inattesadielementi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di elementi"])},
      "invisione (attesa rientro)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In visione"])}
    },
    "open-assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedere assistenza"])},
      "assistance-hour-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'apertura di un'assistenza non è disponibile tra le 01:00 e le 04:00"])},
      "global-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuole aprire una richiesta di assistenza? Provi prima a consultare le nostre <router-link to='/home' style='text-decoration: underline;'>FAQ<router-link/><br>È inoltre consigliato inserire una nuova richiesta di assistenza da telefono cellulare, per avere già a disposizione foto e video."])},
      "no-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono ordini selezionabili. La invitiamo a controllare che non sia già stata aperta un' assistenza"])},
      "step-one": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezioni l'ordine del prodotto"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezioni il suo ordine dal menu"])},
        "find-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il suo ordine non è nella lista qui in alto? Clicchi qui e segua le istruzioni per recuperarlo e associarlo al suo utente"])},
        "has-assistances-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, è presente un'assistenza aperta su questo ordine. Non è possibile continuare. Vai alla pagina <u style='color: #d41317'>le tue assistenze.</u>"])},
        "is-not-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine non è idoneo per l'apertura dell'assistenza in quanto non risulta consegnato. Contattare il negozio di riferimento per avere informazioni sulla consegna."])}
      },
      "step-two": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezioni il prodotto"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Selezioni il prodotto per il quale aprire l'assistenza"])}
      },
      "step-three": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriva il problema e alleghi immagini e video"])},
        "label-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Descriva il problema e aggiunga un minimo di ", _interpolate(_named("maxFiles")), " allegati (peso massimo ", _interpolate(_named("maxSize")), "), tra foto e video.\nSono ammesse le  seguenti estensioni: ", _interpolate(_named("ext")), "."])},
        "label-relax-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel caso di malfunzionamento di un meccanismo relax, la invitiamo ad inviarci un video che mostri il problema."])},
        "label-description-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obbligatorio"])},
        "label-warning-file-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, è necessario caricare almeno 3 files."])},
        "label-warning-file-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione, dimensione dei files supera il limite di 30MB totali"])},
        "label-warning-file-extension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attenzione, le seguenti estensioni di file non sono supportati: ", _interpolate(_named("ext"))])}
      },
      "step-four": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlli i suoi dati e invii"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifichi i dati inseriti e invii la richiesta"])}
      }
    },
    "wash": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni per il lavaggio"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menù principale"])}
    },
    "faq-category": {
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo trovato queste possibili soluzioni"])},
      "button-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornare al menu principale"])},
      "call-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel caso in cui non riesca a risolvere, le consigliamo di contattare il negozio dove ha effettuato l'acquisto."])}
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il suo profilo"])},
      "second-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuri il suo profilo"])},
      "label-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiare la lingua"])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare la password"])}
      },
      "text-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzi questa opzione per modificare la lingua della sua area riservata"])}
      }
    }
  },
  "header": {
    "menu": {
      "poltronesofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poltronesofà"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menù principale"])},
      "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suoi ordini"])},
      "my-tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sue assistenze"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di assistenza"])},
      "maintenance-booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libretto manutenzione"])},
      "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il suo profilo"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscire dal profilo"])}
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande più viste"])},
    "text-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà S.p.A., C.F. e P.IVA. 03613140403, Valsamoggia (BO) - Loc. Crespellano, Via Lunga n. 16, Registro delle Imprese di Bologna REA BO – 462239, Capitale sociale i.v. Euro 250.000,00 "])},
    "text-link-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
    "text-link-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "text-link-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnali un problema"])},
    "text-link-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozi"])},
    "text-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © poltronesofa S.p.A 2023 - Tutti I diritti sono riservati​"])}
  },
  "error": {
    "form-validation": {
      "name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome è richiesto"])},
      "lastname-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cognome è richiesto"])},
      "telephone-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telefono è richiesto"])},
      "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email è richiesta"])},
      "email-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email non è valida"])},
      "email-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le email devono coincidere"])},
      "email-confirm-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conferma non è valida"])},
      "password-lenght": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere di minimo 8 caratteri"])},
      "password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è richiesta"])},
      "old-password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vecchia password è richiesta"])},
      "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password devono essere uguali"])},
      "privacy-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accettazione della privacy è richiesta"])},
      "passwords-match-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conferma password è richiesta"])}
    }
  },
  "components": {
    "faq": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queste informazioni sono state utili?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
      "open-assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedere assistenza"])}
    },
    "open-assistance": {
      "open": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aggiunga almeno ", _interpolate(_named("maxFiles")), " allegati tra foto e video"])}
    },
    "store-detail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio negozio: "])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città:"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo:"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono:"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
      "indications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicazioni:"])},
      "timetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari:"])},
      "go-to-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla mappa"])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiama il negozio"])}
    },
    "assistance-chat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero assistenza: "])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento: "])},
      "text-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il messaggio"])},
      "response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverà una risposta entro 4 giorni lavorativi."])}
    },
    "order-row-detail": {
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articolo"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "other-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri dati"])}
    },
    "order-row-wash": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni per il lavaggio"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre informazioni utili"])},
      "composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composizione"])}
    },
    "multiple-image-preview": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione"])}
    }
  },
  "form": {
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisca la stessa e-mail fornita durante l'ordine per importarlo direttamente"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password attuale"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
      "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetere la nuova password"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedere"])},
      "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisca la email e controlli la sua casella di posta per ricevere tutte le istruzioni."])},
      "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperare"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si è appena registrato e non è arrivata l'email, controlli nello spam oppure scriva qui la email che ha usato per la registrazione e controlli la sua casella di posta"])},
      "resend-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinviare la conferma"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceda alla registrazione per essere aggiornato sui suoi ordini o sulle sue assistenze"])},
      "link-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicchi sul link per segnalare un problema"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarsi"])},
      "contant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci contatti"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellulare"])},
      "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma e-mail"])},
      "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
      "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare"])},
      "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvataggio in corso..."])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
      "warranty-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Garanzia"])},
      "warrantyCodeTooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Può trovare il codice garanzia all'interno del tuo ", _interpolate(_named("order")), ", nella ", _interpolate(_named("warranty")), " oppure contattando il tuo negozio di riferimento."])},
      "warrantyCodeTooltipOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordine"])},
      "warrantyCodeTooltipWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["garanzia"])},
      "add-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associare"])},
      "read-understood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto e compreso"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo è obbligatorio"])},
      "privacy": {
        "part-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiaro di avere letto e compreso"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l' informativa sulla privacy"])},
        "part-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ai sensi degli art. 13 e 14 del Regolamento UE 2016/679"])}
      }
    },
    "placeholder": {
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisca l'email"])}
    },
    "field": {
      "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anagrafica"])}
    }
  },
  "payment_status_axepta_fr": {
    "0000": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione completata con successo"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento è avvenuto con successo. La fattura risulta saldata.\nPer continuare a navigare torni al menù principale."])}
    },
    "0200": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione fallita"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati inseriti non risultano corretti. La invitiamo a riprovare."])}
    },
    "0351": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione non riuscita"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite carta superato"])}
    },
    "0305": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione negata"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "0392": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione negata"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di sistema nel centro dati bancario. Riprova più tardi."])}
    }
  },
  "payment_status_be": {
    "authorized": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione completata con successo"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento è avvenuto con successo. La fattura risulta saldata.\nPer continuare a navigare torni al menù principale."])}
    },
    "canceled": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione fallita"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati inseriti non risultano corretti. La invitiamo a riprovare."])}
    },
    "3ds_authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione 3DS fallita"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione 3D-secure fallita: la transazione è stata interrotta."])}
    },
    "token_expired": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token scaduto"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il token è scaduto"])}
    },
    "transaction_aborted": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione interrotta"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transazione è stata interrotta dal pagatore."])}
    },
    "validation_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalida fallita"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "amount_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo non valido"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo non rispetta le restrizioni per questa azione. Ad esempio, potrebbe superare l'importo limite consentito."])}
    },
    "authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione fallita"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stata inserita la password errata."])}
    },
    "card_check_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di carta o CVC non valido"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore. Inserire il numero di carta o CVC corretto."])}
    },
    "card_cvc_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC non valido"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore. Inserire il codice CVC corretto."])}
    },
    "card_cvc_required": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC non inserito ma richiesto"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore. Inserire il codice CVC."])}
    },
    "communication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La comunicazione con il processore è fallita."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "currency_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice valuta errato"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valuta non corrisponde alla valuta della transazione di riferimento."])}
    },
    "permission_denied": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permesso negato"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "check_store_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore nella registrazione del pagamento."])}
  },
  "modal": {
    "assistance": {
      "add-order-number": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine importato correttamente"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il suo ordine ora è visibile nella sua area riservata."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore inserimento ordine"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine non può essere recuperato"])}
        }
      },
      "post-assistance": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza inserita"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le confermiamo che la sua assistenza è stata inserita con successo."])}
        },
        "error-response": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore inserimento del ticket"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile ricevere una risposta dal server, errore non identificabile"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore inserimento del ticket"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "post-chat-message": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore inserimento del ticket"])}
        }
      }
    },
    "auth": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore inserimento dati"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "expire-session": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione scaduta"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario eseguire la login"])}
    },
    "orders": {
      "load-orders": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio di errore:"])}
        }
      },
      "download-warranty": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio di errore:"])}
        }
      },
      "send-order": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua richiesta non può essere inviata "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono problemi con i dati da inviare"])}
        }
      }
    },
    "user": {
      "post-registration-user": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il suo utente è stato registrato con successo"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlli la sua casella di posta elettronica per concludere la sua registrazione"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vada alla login"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore creazione utente."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "send-activation": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua richiesta è stata inviata"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlli la sua casella di posta o nella spam, e clicchi sul link che troverà nel messaggio"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password-email": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua richiesta è stata inviata"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlli la sua casella di posta o nello spam, e clicchi sul link che troverà nel messaggio"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'invio dei dati"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio di errore:"])}
        }
      },
      "update-language": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella modifica della lingua"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio di errore:"])}
        }
      },
      "change-password": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata modifica con successo"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella modifica della password"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error-no-token-username": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieda nuovamente la mail di cambio password."])}
        }
      }
    }
  }
}