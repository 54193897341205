<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import TitleFaqCategories from "../components/TitleFaqCategories.vue";
import FaqAccordion from "../components/FaqAccordion.vue";
const route = useRoute();

const data = ref({});
const title = ref({});
const faqs = ref(null);

const fetchData = async () => {
  try {
    const response = await fetch(process.env.VUE_APP_BASE_URL_STRAPI+'api/categories/' + route.params.idCategory + '?populate=*');
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    let res = await response.json();
    data.value = res.data;
    title.value = res.data.attributes;
    faqs.value = res.data.attributes.faqs;
  } catch (e) {
    console.log(e)
  } 
};

onMounted(()=>{
  fetchData();
})
</script>

<template>
  <div>
    <TitleFaqCategories
      :title="title.value"
      :subtitle="$t('pages.faq-category.sub-title')"
      :url = "title.url_image"
    />
    <section class="text-center mb-7">
      <router-link
        to="/home"
        class="btn btn-outline-primary btn-lg rounded-lg rounded-50"
        @click="scrollToTop"
      >
        {{ $t("pages.faq-category.button-title") }}</router-link
      >
    </section>
    <div class="container mt-5">
      <div class="row" v-if="true">
        <div class="col-sm-12">
          <div
            class="accordion accordion-02"
            id="faq-list"
            v-for="faq in faqs"
            :key="faq.id"
          >
            <FaqAccordion
              :faqs="faq"
              :openFaq="openFaq"
              :openFaqCanvans="openFaqCanvans"
              suf="page"
            />
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <NoRecords messageError="Non ci sono faq disponibili" />
      </div>
    </div>
  </div>
</template>

<style>

.image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

</style>