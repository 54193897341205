import{ref} from 'vue';
import {http,headers} from '../services/http';
import { useAuthStore } from "@/stores";
export  function useFaqs(){

const faqs = ref([]);
const faqsFiltered = ref([]);
const faqsToCategory = ref({});
const faqsToCategoryCount = ref("");
const categories = ref([]);
const showFaqClass = ref("");
const faq = ref([]);
const importants = ref([]);

const authStore = useAuthStore();




const allFaqsCategories = () =>{

    const url = `api/categories/?locale=${authStore.lang}&sort=value%3Aasc&populate=*`;
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header)
    .get(url)
      .then(res => {
        
        faqs.value = {...res.data.data};
    })
    .catch(error => {
        if (!error.response) {
            console.log("Please check your internet connection. (allFaqscategorie)");
        }
      });
}

const filterFaqs = (term) =>{
    const url = `api/faqs/?locale=${authStore.lang}&filters[$or][0][question][$containsi]=${term}&filters[$or][1][answer][$containsi]=${term}`;
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header)
    .get(url)
      .then(res => {
        
        faqsFiltered.value = {...res.data.data};
    })
    .catch(error => {
        if (!error.response) {
            console.log("Please check your internet connection. (allFaqs)");
        }
      });
}

const faqsCategories = () =>{
    const url = `api/categories/?locale=${authStore.lang}&sort=value%3Aasc`;
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header)
    .get(url)
    .then(res => {
        categories.value = {...res.data}
    })
    .catch(error => {
        if (!error.response) {
            console.log("Please check your internet connection. (faqsCategories)");
        }
      });
}

const faqsCategory = (idCategory) =>{
    const url = 'api/categories/' + idCategory + '?populate=*';
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header)
    .get(url)
    .then(res => {
        faqsToCategoryCount.value = res.data.data.attributes.faqs.data.length;
        // let a=[], b = res.data.data;
        // a.push(b);

        faqsToCategory.value = res.data.data;
    })
    .catch(error => {
        if (!error.response) {
            console.log("Please check your internet connection. (faqsImportants)");
        }
      });
}


const faqsImportants = () =>{
    const url = 'api/faqs?filters[isImportant][$eq]=true';
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header)
    .get(url)
    .then(res => {
        importants.value = {...res.data.data.slice(0, 5)}
    })
    .catch(error => {
        if (!error.response) {
            console.log("Please check your internet connection. (faqsImportants)");
        }
      });
}


const openFaqCanvans = (dataFaq) => {
    faq.value = dataFaq;
}


/*TODO DA INSERIRE IN UN HELPER*/

const  openFaq = () =>{
    return showFaqClass.value = "show";
}
const  closeFaq = () =>{
    return  showFaqClass.value = "";
}


/*
const countQuestion = (question) => {
    try {
        const countQuestion = question.length;
        return {
            countQuestion,
        }
    } catch (error) {
        console.log("Please check your internet connection. (limitQuestion)")
    }
}
*/
    return{
        faqs,
        filterFaqs,
        faqsFiltered,
        allFaqsCategories,
        faqsImportants,
        faqsToCategory,
        faqsToCategoryCount,
        faqsCategory,
        importants,
        categories,
        faqsCategories,
        faq,
        openFaq,
        closeFaq,
        showFaqClass,
        openFaqCanvans
    }
}