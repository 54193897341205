<template>


    <div class=" d-flex align-items-center" style="
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
        background-image: url(http://localhost:8080/images/backgrounds/login_background_5.jpg);
">
        <div class="container">

            <div class="row align-items-center vh-100">
                <div class="col-5 mx-auto">
                    <div class=" mb-6 shadow-lg-4 text-center text-white">
                        <h1 class="text-white">Pagina non trovata</h1>
                        <p class="zoom-area">C'è stato qualche problema</p>
                            <section class="error-container">
                                <span class="four"><span class="screen-reader-text">4</span></span>
                                <span class="zero"><span class="screen-reader-text">0</span></span>
                                <span class="four"><span class="screen-reader-text">4</span></span>
                            </section>
                        <div class="link-container">
                        <button type="button" class="btn btn-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 mr-5" data-toggle="modal" data-target="#modal-messenger"> Vai al sito </button>
                        <button type="button" class="btn btn-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 ml-5" data-toggle="modal" data-target="#modal-messenger"> Vai alla login </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</template>
