<script setup>
import TitlePage from "../components/TitlePage";
import NoRecords from "../components/NoRecords";
import LoadingPage from "../components/LoadingPage";
import WashDetails from "../layouts/partials/side/WashDetail.vue";
import { useWashes } from "../use/useWashes.js";
import { helpers } from "../helpers/helpers.js";
const { disabledScroll } = helpers();

const {
  wash,
  loading,
  washes,
  showWashClass,
  loadWashes,
  openWashCanvans,
  openWash,
  closeWash,
} = useWashes();
loadWashes();
</script>

<template>
  <TitlePage :title="$t('pages.wash.title')" />

  <div class="container">
    <div class="row" v-if="washes">
      <LoadingPage :loading="loading" />
      <div class="col-12" v-for="wash in washes" :key="wash.id">
        <div class="card p-4 border shadow-hover-1 mb-6 no-gutters">
          <div class="card-body row pb-0">
            <p class="p-0 m-0 mb-4">
              <strong>{{ wash.title }}</strong>
            </p>

            <div class="col-lg-6 col-md-6 col-12">
              <ul class="p-0 m-0">
                <li
                  class="d-flex align-items-center p-0"
                  v-for="comp in wash.composition"
                  :key="comp.id"
                >
                  <span class="bullet mr-4"></span>{{ comp }}
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="text-center">
              <div class="text-center mt-2">
                <a
                  class="fs-14 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                  href="#"
                  @click="
                    disabledScroll();
                    openWash();
                    openWashCanvans(wash);
                  "
                  >Istruzioni per il lavaggio</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoRecords messagge="Non ci sono articoli disponibili" :checkData="washes" />
  </div>
  <WashDetails :class="showWashClass" :wash="wash" @closeWash="closeWash" />
</template>
