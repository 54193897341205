import { ref } from 'vue'
import useCameraStream from './useCameraStream'

export default function (payload) {
  const { startCameraStream, stopCameraStream, cameraStream } = useCameraStream(payload)

  const video = ref(null)
  const showCamera = ref(false)
  const loading = ref(false)

  const initVideo = () => {
    if (!video.value)
      video.value = document.querySelector(`#${payload.videoId}`)
  }

  const startCamera = async () => {
    loading.value = true
    initVideo()

    if (!video.value)
      throw new Error(`Cannot find video element ${payload.videoId}`)

    await startCameraStream()

    if (!cameraStream.value)
      throw new Error('No media stream available to reproduce.')

    showCamera.value = true

    video.value.srcObject = cameraStream.value

    await video.value.play()

    loading.value = false
  }

  const stopCamera = async () => {
    if (!video.value)
      throw new Error(`Cannot find video element ${payload.videoId}`)

    loading.value = true
    video.value.pause()

    showCamera.value = false

    await stopCameraStream()

    setTimeout(() => {
      if (video.value)
        video.value.srcObject = null
      loading.value = false
    }, 200)
  }

  return {
    loading,
    video,
    showCamera,
    startCamera,
    stopCamera,
  }
}
