export default {
  "seo": {
    "index": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support area - poltronesofà"])}
    }
  },
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "commons": {
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
  },
  "pages": {
    "login": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "confirm-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User correctly activated"])},
      "recover-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the confirmation email?"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not registered?"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered?"])},
      "back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
      "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "report-a-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem"])}
    },
    "register": {
      
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome ", _interpolate(_named("nameOfUser"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help you?"])},
      "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a topic"])},
      "button": {
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
        "assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tickets"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
        "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing instructions"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "download-warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download warranty"])},
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
        "home-area-riservata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main menu"])}
      }
    },
    "orders": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
      "total-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total cost"])},
      "to-pay-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pay"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a ticket"])},
      "ticket-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket opened"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
      "no-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no orders"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "go-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View messages"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
      "add-order-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add order to your list"])}
    },
    "order": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order n°."])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "show-ticket-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the open tickets"])},
      "message-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here below the list of products purchased with the current order"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item code"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "code-article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item code"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing"])},
      "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a ticket"])},
      "show-all-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all the orders"])}
    },
    "order-payment-response": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])}
    },
    "assistances": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tickets"])},
      "ticket-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket number"])},
      "data-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening date"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
      "no-assistances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open assistance"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
      "inserita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insered"])},
      "respinta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "chiusa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
      "inlavorazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
      "inattesadielementi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for elements"])},
      "invisione (attesa rientro)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On view"])}
    },
    "open-assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a ticket"])},
      "assistance-hour-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance is not available between 01:00 and 04:00"])},
      "global-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to make a request for support? Try consulting our <a href='/home' style='text-decoration: underline;'>FAQ</a> first.<br />It is also recommended to enter a new request for assistance from a mobile phone."])},
      "no-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no selectable orders. Please check that a service has not already been opened."])},
      "step-one": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the order"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your order from the menu"])},
        "find-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find your order in the list above? Please click here and follow the instructions to retrieve it and associate it with your user."])},
        "has-assistances-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, there is an open support on this order. It is not possible to continue. <u style='color: #d41317'>Go to your assistances page</u>"])},
        "is-not-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order is not eligible for service as it is undelivered. Please contact the relevant shop for delivery information."])}
      },
      "step-two": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the product"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please select the product for which you want to make a request for support"])}
      },
      "step-three": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please describe the issue and attach images and videos"])},
        "label-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Describe the issue and attach a minimum of ", _interpolate(_named("maxFiles")), " files (maximum ", _interpolate(_named("maxSize")), "), including photos and videos.\nThe following extensions are allowed: ", _interpolate(_named("ext")), "."])},
        "label-relax-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a relaxation mechanism malfunctions, please send us a video showing the problem."])},
        "label-description-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
        "label-warning-file-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, at least 3 files must be uploaded."])},
        "label-warning-file-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, file size exceeds the limit of 30MB total"])},
        "label-warning-file-extension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please note that the following file extensions are not supported: ", _interpolate(_named("ext"))])}
      },
      "step-four": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your data and submit"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify the entered data and submit the request"])}
      }
    },
    "wash": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing instructions"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main menu"])}
    },
    "faq-category": {
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have found these possible solutions"])},
      "button-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You return to the main menu"])},
      "call-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are unable to resolve this, we recommend that you contact the shop where you made your purchase."])}
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
      "second-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure your profile"])},
      "label-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change default language"])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])}
      },
      "text-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this option to change the default language of your customer area"])}
      }
    }
  },
  "header": {
    "menu": {
      "poltronesofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main menu"])},
      "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
      "my-tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tickets"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a ticket"])},
      "maintenance-booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance booklet"])},
      "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "text-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà S.p.A., C.F. e P.IVA. 03613140403, Valsamoggia (BO) - Loc. Crespellano, Via Lunga n. 16, Registro delle Imprese di Bologna REA BO – 462239, Capitale sociale i.v. Euro 250.000,00 "])},
    "text-link-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
    "text-link-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "text-link-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores"])},
    "text-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © poltronesofa S.p.A 2023 - All rights reserved"])}
  },
  "error": {
    "form-validation": {
      "name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
      "lastname-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name is required"])},
      "telephone-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone is required"])},
      "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
      "email-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is invalid"])},
      "email-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails must match"])},
      "email-confirm-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email confirmation is required"])},
      "password-lenght": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long"])},
      "password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
      "old-password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The old password is required"])},
      "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
      "privacy-requiered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy acceptance is required"])},
      "passwords-match-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation is required"])}
    }
  },
  "components": {
    "faq": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the information useful?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "open-assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request assistance"])}
    },
    "open-assistance": {
      "open": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please attach min ", _interpolate(_named("maxFiles")), " image or video files"])}
    },
    "store-detail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store detail: "])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City:"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address:"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone:"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
      "indications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indications:"])},
      "timetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetable:"])},
      "go-to-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to map"])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])}
    },
    "assistance-chat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket number: "])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update: "])},
      "text-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive a response within 4 working days."])}
    },
    "order-row-detail": {
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "other-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other data"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article code"])}
    },
    "order-row-wash": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing instructions"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other useful information"])},
      "composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition"])}
    },
    "multiple-image-preview": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimension"])}
    }
  },
  "form": {
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your email in the order to import it directly"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email and check your inbox. You will receive an email with all the instructions."])},
      "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have just registered and have not received the email, check your spam or enter here the email you used for registration and check your inbox"])},
      "resend-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend confirmation"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with registration to stay up to date on your orders or assistance requests"])},
      "link-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the link to report a problem"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to register?"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
      "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email confirmation"])},
      "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward"])},
      "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backward"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "warranty-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty code"])},
      "warrantyCodeTooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can find the warranty code inside your ", _interpolate(_named("order")), ", in the ", _interpolate(_named("warranty")), " or by contacting your reference shop."])},
      "warrantyCodeTooltipOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order"])},
      "warrantyCodeTooltipWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warranty"])},
      "add-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to "])},
      "read-understood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and understood"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field is mandatory"])},
      "privacy": {
        "part-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare that I have read and understood"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the privacy policy"])},
        "part-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in accordance with the articles 13 and 14 of the EU Regulation 2016/679."])}
      }
    },
    "placeholder": {
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert your email"])}
    },
    "field": {
      "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])}
    }
  },
  "user": {
    "change-password": {
      "error-no-token-username": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please request the password change e-mail again."])}
      }
    }
  },
  "payment_status_be": {
    "authorized": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation successfully completed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment was successful. The invoice is fully paid.\nTo continue navigating, please return to the main menu."])}
    },
    "canceled": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data entered were not correct. Please try again."])}
    },
    "3ds_authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3DS authentication failed. Please try again."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D-secure authentication failed: transaction was aborted."])}
    },
    "token_expired": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token expired"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The token has expired"])}
    },
    "transaction_aborted": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction aborted"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction was aborted by the payer."])}
    },
    "validation_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation failed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "amount_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid amount"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount does not respect the restrictions for this action. For example, it may exceed the permitted catch amount."])}
    },
    "authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wrong password was entered."])}
    },
    "card_check_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid card number or CVC"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please enter the correct card or CVC number."])}
    },
    "card_cvc_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CVC"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please enter the correct CVC code."])}
    },
    "card_cvc_required": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC not inserted but required"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please enter the CVC code."])}
    },
    "communication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["communication failed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication with the processor failed."])}
    },
    "currency_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect currency code"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currency does not correspond to the currency of the reference transaction."])}
    },
    "permission_denied": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission denied"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "payment_status_axepta_fr": {
    "0000": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation successfully completed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment was successful. The invoice has been paid.\nTo continue navigating, please return to the main menu."])}
    },
    "0200": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data entered were not correct. Please try again."])}
    },
    "0351": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorised payment"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper limit exceeded"])}
    },
    "0305": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation denied"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "0392": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione negata"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di sistema nel centro dati bancario. Riprova più tardi."])}
    }
  },
  "modal": {
    "assistance": {
      "add-order-number": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order correctly imported"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order is now visible in your customer area."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order entry error"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order cannot be retrieved"])}
        }
      },
      "post-assistance": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance inserted"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We confirm that your assistance has been successfully inserted."])}
        },
        "error-response": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket entry error"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to receive a response from the server, unidentifiable error"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Input Error"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "post-chat-message": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Input Error"])}
        }
      }
    },
    "auth": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "expire-session": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication expired"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must log in"])}
    },
    "orders": {
      "load-orders": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error message: "])}
        }
      },
      "download-warranty": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error message: "])}
        }
      },
      "send-order": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request cannot be sent "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are problems with the data to be sent"])}
        }
      }
    },
    "user": {
      "post-registration-user": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user has been successfully registered"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your mailbox to complete your registration"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to login"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User creation error."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "send-activation": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request was sent"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox or spam, and click on the link you will find in the message"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password-email": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request was sent"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox or spam, and click on the link you will find in the message"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in sending data"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error message:"])}
        }
      },
      "update-language": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when changing language"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error message:"])}
        }
      },
      "change-password": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was changed successfully"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when changing password"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error-no-token-username": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request the password change e-mail again."])}
        }
      }
    }
  }
}