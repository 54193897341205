<template>
	<div class="container mb-2">		
			<div v-for="(file, key) in files" :key= key >
				<div class="w-100 p-1">
					<div class="card w-100">
						<div class="row g-0">
							<div class="col-3 col-sm-5 col-md-3 col-lg-2">
								<img class="preview mx-auto" :id="'image-' + parseInt(key)"  :src =imagePreview(key) style="max-height: 100px;"/>
							</div>
							<div class="col-9 col-sm-7 col-md-9 col-lg-10 d-flex align-items-center justify-content-center">
								<div class="card-body m-1 p-1">
									<p class="p-0 m-1" style="word-break: break-all;"><small><u>{{ $t('components.multiple-image-preview.name') }}</u>: {{ file.resource.name }}</small></p>
									<p class="p-0 m-1"><small><u>{{ $t('components.multiple-image-preview.dimension') }}</u>: {{ ((file.resource.size/1024)/1024).toFixed(1) }} MB</small></p>									
								</div>
							</div>
						</div>
						<div class="preview-controller">
							<i class="fas fa-times-circle text-primary fs-20" @click="removeFile(key); "></i>
						</div>
						<!-- 
						<span class="overlay-box">{{file.name}}</span> 
						-->
					</div>
				</div>
			</div>
		<input type="file" :id="inputId" name="files" accept="video/*, image/*, application/pdf" multiple @change="loadFileFromDisk" />
	</div><span class="badge badge-primary d-none">{{ files.length }}</span>
</template>


<script setup>
import { defineProps } from 'vue'

const props =  defineProps({
	inputId: String,
	files: Array,
	removeFile: Function,
	imagePreview: Function,
	addFiles: Function
})

const loadFileFromDisk = (e) => {
  const { files: inputFileList } = e.target

  if (!inputFileList)
    return

  props.addFiles(Array.from(inputFileList))
}

// import { defineEmits,computed, defineProps  } from 'vue';
// import config from '../../config';
// import {getFileExtention} from '../../utilities/file.js';
// const emits = defineEmits(['add']);
// const props = defineProps({
// 	files:Array,
// });

// const countFiles = computed (()=>{
// 	return props.files.length || 0
// });

// const handleSubmit = () => {
// 	emits('add', event.target.files)
// }

// const removeFile = (key) => {
// 	emits('remove',key);
// }

// const getImagePreviews = (file,i) => {

// 	let reader = new FileReader();
// 	reader.addEventListener("load", function () {
// 		const previewThumb  =  config.assistance.extensions.image.includes(getFileExtention(file.name)) ? reader.result : config.assistance.extensions.video.includes(getFileExtention(file.name)) ? "/images/icons/video.png" :  "/images/not-found.png";
// 		document.getElementById('image-' + parseInt(i)).src = previewThumb;
// 	}.bind(file), false);
// 	reader.readAsDataURL(file);

// }
</script>

