import { defineStore } from 'pinia';


export const useRegistrationStore = defineStore({
    id: 'registration',
    state: () => ({
        user : {}
    }),
    getters:{
        getUserData(){
            return this.user
        }
    },
    actions: {
        setUserData(user) {
            this.user = user;
        },
    }
});
