<script setup>
import { defineProps, defineEmits, ref } from "vue";
import moment from "moment";

import LoadingPage from "@/components/LoadingPage";
import { helpers } from "../../../helpers/helpers.js";
import { useAssistances } from "../../../use/useAssistances.js";
import MultipleImagesPreview from "../../../components/upload/MultipleImagesPreview.vue";
import OpenResource from "../../../components/upload/OpenResource.vue";
import { getFileExtention } from "../../../utilities/file.js";

import config from "../../../config";
import useCamera from "@/composables/useCamera";
import useVideoSnapshot from "@/composables/useVideoSnapshot";
import useVideoRecorder from "@/composables/useVideoRecorder";
import useFiles from "@/composables/useFiles";
import { useMobileDetection } from "vue3-mobile-detection";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores";
const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);
/** Inizio LightBox
 * ToDo: da riscrivere
 */
//const containerRef = ref(null);
const imageExists = ref(null);

const onImageLoadFailure = (e) => {
  e.target.src = "/images/not-found.png";
};

const getExt = (file) => {
  return config.assistance.extensions.image.includes(getFileExtention(file));
};

function checkImage(imageSrc, good, bad) {
  var img = new Image();
  img.onload = good;
  img.onerror = bad;
  img.src = imageSrc;
}
const linkOpen = (link) => {
  const src = link;
  checkImage(
    link,
    function () {
      openLightbox(src);
      imageExists.value = true;
    },
    function () {
      imageExists.value = false;
    }
  );

  if (getExt(link) === false) {
    openFile(src);
  }
};

const openFile = (src) => {
  window.open(src);
};

const openLightbox = (src) => {
  //const src = e.target.getAttribute("src");

  const existingLightbox = document.querySelector(".lightbox");
  if (existingLightbox) {
    existingLightbox.remove();
  }

  const lightbox = document.createElement("div");
  lightbox.className = "lightbox";
  lightbox.innerHTML = `
  <div class="lightbox-container">
    <div class="lightbox-content">
      <img src="${src}" alt="${src}" />
    </div>
    <div class="lightbox-close">&times;</div>
    <div class="lightbox-navigation">
    </div>
    </div>
  `;
  const boxWrapper = document.getElementById("content");
  boxWrapper.appendChild(lightbox);

  /*
  lightbox.addEventListener("click", (event) => {
    if (event.target.classList.contains("lightbox-content")) {
      return;
    }
    boxWrapper.removeChild(lightbox);
  });
*/
  const closeButton = lightbox.querySelector(".lightbox-close,.lightbox-container");
  closeButton.addEventListener("click", () => {
    boxWrapper.removeChild(lightbox);
  });
  /*
  const container = containerRef.value;
  const images = container.querySelectorAll(".lightbox-image");
  const currentIndex = Array.from(images).indexOf();

  const handleArrowKeys = (event, images, currentIndex) => {
    if (event.key === "ArrowLeft") {
      openLightbox(images[(currentIndex - 1 + images.length) % images.length]);
    } else if (event.key === "ArrowRight") {
      openLightbox(images[(currentIndex + 1) % images.length]);
    }
  };

  const handleKeyDown = (event) => {
    handleArrowKeys(event, images, currentIndex);
  };

  document.addEventListener("keydown", handleKeyDown);

  const prevButton = lightbox.querySelector(".lightbox-prev");
  prevButton.addEventListener("click", () => {
    openLightbox(images[(currentIndex - 1 + images.length) % images.length]);
  });

  const nextButton = lightbox.querySelector(".lightbox-next");
  nextButton.addEventListener("click", () => {
    openLightbox(images[(currentIndex + 1) % images.length]);
  });

  const nextButtonImage = lightbox.querySelector(".lightbox-next");
  nextButtonImage.addEventListener("click", () => {
    handleNextPreview;
  });
  */
};
/*
const handleNextPreview = () => {
  containerRef.value = document.getElementById("frame");
  const images = containerRef.value.querySelectorAll(".lightbox-image");
  console.log(images);
  for (let i = 0; i < images.length; i++) {
    const img = images[i];
    console.log(img.src);
    img.addEventListener("click", () => {
      openLightbox(img.src);
    });
  }
};
*/
/** Fine LightBox */

const { isMobile } = useMobileDetection();
const { startCamera, stopCamera, showCamera } = useCamera({
  cameraLabel: "3000",
  videoId: "my-video",
});

const { takeScreenshot } = useVideoSnapshot({
  videoId: "my-video",
  canvasId: "my-canvas",
});
const { startRecorder, stopRecorder, recording } = useVideoRecorder({
  videoId: "my-video",
  canvasId: "my-canvas",
});

const {
  files,
  addToFiles,
  clearAllFiles,
  preview,
  removeFromFiles,
  onlyFiles,
} = useFiles();

const takePicture = async () => {
  const image = await takeScreenshot();
  return addToFiles(image);
};

const startRecordCamera = async () => {
  await startRecorder();

  setTimeout(() => stopRecordCamera(), 60000);
};

const stopRecordCamera = async () => {
  if (!recording.value) return;

  const file = await stopRecorder();
  return addToFiles({ resource: file.video, thumbnail: file.thumbnail });
};

/*
 * Chiamo tutte le funcions che mi servono
 */
const { postChatMessage } = useAssistances();
const { scrollToBottom, enabledscroll } = helpers();

/*
 * Passo le emit la componente padre
 */
const emit = defineEmits(["closeAssistance", "addToChat"]);
/*
 * Recupero i dati dal componente padre
 */
const scrollableDiv = ref(null);

defineProps({
  chat: {
    type: Array,
    default: () => [],
  },
  assistance: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

/*
 * Definisco tutte le ref da utilizzare
 */

const message = ref("");

/*
const onChange = (e) =>{
    message = e.target.value;
}
*/

/*
 * Dopo che ho inviato i dati faccio il reset
 */

const resetForm = () => {
  message.value = "";
  // files.value = [];
  clearAllFiles();
  document.getElementById("multiple-image-preview-files").value = "";
  document.getElementById("chat-message").text = "";
};

/*
 * Abilito il bottone submit dopo aver scritto il messaggio, requisito minimo per poter inviare un messaggio
 */

const isDisable = () => {
  return message.value.length === 0;
};

/*
 * Preparo i dati del payload
 */

const submitMessagetoChat = (assistance) => {
  const messageToChat = {
    username: authUser.value.mail,
    message: message.value,
    assistance: assistance,
    // files: files.value,
    files: onlyFiles.value,
  };

  /*
   * Chiamo postChatMessage da use, passo i dati arrivati dall' emit e resetForm
   */
  postChatMessage(messageToChat, resetForm).then(() => {
    //console.log("post da chat", messageToChat);
    emit("addToChat", {
      messaggio: messageToChat.message,
      discuss_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      links: messageToChat.files,
    });
  });
};

const getImagePreviewsChat = (file, key, index) => {
  if (typeof file === "object" && !Array.isArray(file) && file !== null) {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const res = config.assistance.extensions.image.includes(
          getFileExtention(file.name)
        )
          ? reader.result
          : config.assistance.extensions.video.includes(getFileExtention(file.name))
          ? "/images/icons/video.png"
          : config.assistance.extensions.file.includes(getFileExtention(file.name))
          ? "/images/icons/pdf.png"
          : "/images/not-found.png";
        document.getElementById("imageChat-" + key + index).src = res;
      },
      false
    );

    reader.readAsDataURL(file);
  } else {
    return config.assistance.extensions.image.includes(getFileExtention(file))
      ? file
      : config.assistance.extensions.video.includes(getFileExtention(file))
      ? "/images/icons/video.png"
      : config.assistance.extensions.file.includes(getFileExtention(file))
      ? "/images/icons/pdf.png"
      : "/images/not-found.png";
  }
};
</script>

<template>
  <div class="canvas-sidebar chat-canvas" v-if="chat">
    <LoadingPage :loading="loading" />

    <div
      class="canvas-overlay"
      @click="
        emit('closeAssistance');
        enabledscroll();
      "
    ></div>

    <div class="card border-0 rounded-0 h-100">
      <div class="card-header d-flex border-bottom bg-transparent align-items-center">
        <div>
          <span class="font-weight-600">{{
            $t("components.assistance-chat.number")
          }}</span
          ><span>{{ assistance.supportCode }}</span
          ><br />
          <span class="font-weight-600">{{
            $t("components.assistance-chat.last-update")
          }}</span
          ><span>{{ assistance.statusDate }}</span>
          <br />
          <span class="font-weight-600" style="color: #d41317;"><i class="bi bi-exclamation-triangle-fill"></i> {{ $t("components.assistance-chat.response-time") }}</span>                    
        </div>
        <span
          class="ml-auto canvas-close d-inline-block fs-24 mb-1 ml-auto lh-1 text-primary"
          @click="
            emit('closeAssistance');
            enabledscroll();
          "
          ><i class="fal fa-times"></i
        ></span>
      </div>
      <div class="card-body overflow-y-auto divToScrollToBottom" ref="scrollableDiv">
        <div id="frame">
          <div
            class="content"
            v-for="(row, index) in chat"
            :key="row.id"
            :style="{ order: chat.length - index }"
          >
            <div class="messages">
              <ul class="p-0 m-0">
                <li class="sent" v-if="row.utente == 'SEDE'">
                  <img src="images/chat-operator.png" alt="" class="avatar" />
                  <div class="container message">
                    <div class="row message-text">
                      <div class="thumb-box" v-for="(link, key) in row.links" :key="key">
                        <img
                          class="image-wrapper lightbox-image cursor-pointer"
                          :id="'imageChat-' + key + index"
                          :src="`${getImagePreviewsChat(link, key, index)}`"
                          @click="linkOpen(link)"
                          @error="onImageLoadFailure"
                        />
                      </div>
                      <p>{{ row.messaggio }}</p>
                    </div>
                    <div class="message-data mt-1" :id="`chat-message-${index}`">
                      <span class="d-block fs-14">{{
                        moment(row.discuss_date).format(
                          config.format.date + " - " + config.format.hour
                        )
                      }}</span>
                    </div>
                  </div>
                </li>
                <li class="replies" v-else>
                  <img src="images/chat-user.png" alt="" class="avatar" />
                  <div class="container message">
                    <div class="row message-text" v-if="row.links">
                      <div class="thumb-box" v-for="(link, key) in row.links" :key="key">
                        <img
                          class="image-wrapper lightbox-image cursor-pointer"
                          :id="'imageChat-' + key + index"
                          :src="`${getImagePreviewsChat(link, key, index)}`"
                          @click="linkOpen(link)"
                          @error="onImageLoadFailure"
                        />
                      </div>
                      <p>{{ row.messaggio }}</p>
                    </div>

                    <div
                      class="message-data text-right mt-1"
                      :id="`chat-message-${index}`"
                    >
                      <span class="d-block fs-14">{{
                        moment(row.discuss_date).format(
                          config.format.date + " - " + config.format.hour
                        )
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-footer border-0 bg-transparent px-3 mt-3"
        v-if="assistance.statusCode"
      >
        <form
          class="h-100"
          ref="sendMessageToChat"
          id="sendMessageToChat"
          @submit.prevent="submitMessagetoChat(assistance)"
        >
          <MultipleImagesPreview
            input-id="multiple-image-preview-files"
            :files="files"
            :image-preview="preview"
            :add-files="addToFiles"
            :remove-file="removeFromFiles"
          />

          <video
            v-show="showCamera"
            id="my-video"
            style="
               {
                width: 100%;
                max-width: 24rem;
                background: #000;
              }
            "
          />
          <canvas id="my-canvas" style="display: none" />

          <div v-if="isMobile()" class="d-none">
            <template v-if="!showCamera">
              <button type="button" @click="startCamera">Open Camera</button>
            </template>
            <template v-else>
              <button type="button" @click="stopCamera">Stop Camera</button>

              <template v-if="!recording">
                <button type="button" @click="takePicture">Take Snapshot</button>
                <button type="button" @click="startRecordCamera">Start Recording</button>
              </template>
              <template v-else>
                <button type="button" @click="stopRecordCamera">Stop recording</button>
              </template>
            </template>
          </div>
          <OpenResource :onlyButton="true" input-id="multiple-image-preview-files" />
          <div class="message-input">
            <div class="wrap">
              <textarea
                v-bind:placeholder="$t('components.assistance-chat.text-message')"
                @change="onChange"
                id="chat-message"
                v-model="message"
                row="1"
              ></textarea>
              <button
                class="submit"
                :disabled="isDisable(message.value)"
                :class="{ 'button-disabled': isDisable(message.value) }"
                @click="
                  scrollToBottom()
                  /*assistanceData(assistance);
                  addMessageToChat(chat);*/
                "
              >
                <i
                  class="fad fa-paper-plane fs-20"
                  aria-hidden="true"
                  :disabled="isDisable(message.value)"
                ></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
.lightbox {
  z-index: 1050;
}

.lightbox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 88%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-content {
  overflow: auto;
  text-align: center;
}

.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.lightbox-navigation {
  display: block;
  height: 50px;
}

.lightbox-next {
  right: 10px;
}
.lightbox-prev {
  left: 10px;
}
.lightbox-prev,
.lightbox-next {
  position: absolute;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 40px;
  transition: 0.6s ease;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

#frame {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  height: 100%;
}
</style>
