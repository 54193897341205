<script setup>
import { ref } from "vue";
import { helpers } from "../../../helpers/helpers.js";

import MenuMobileDetails from "../../../layouts/partials/side/MenuMobileDetails.vue";
import { useI18n } from "vue-i18n";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores";
import { useAssistanceStore } from "@/stores/assistanceStore";

const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);
const assistanceStore = useAssistanceStore();

const { t } = useI18n();

const { openCanvans, closeCanvans, showCanvansClass, disabledScroll } = helpers();

const navSite = {
  title: "Area assistenza",

  menu: [
    {
      id: 0,
      label: t("header.menu.home"),
      link: "/home",
      icon: "/images/home.png",
    },
    {
      id: 2,
      label: t("header.menu.my-orders"),
      link: "/orders",
      icon: "/images/order.png",
    },
    {
      id: 3,
      label: t("header.menu.my-tickets"),
      link: "/assistances",
      icon: "/images/garanty.png",
    },
    {
      id: 1,
      label: t("header.menu.open-ticket"),
      link: "/open-assistance",
      icon: "/images/open-assistance.png",
    },
    {
      id: 6,
      label: t("header.menu.maintenance-booklet"),
      link: "/open-assistance",
      icon: "/images/libretto manutenzione_1.png",
    },
    {
      id: 4,
      label: t("header.menu.my-profile"),
      link: "/profile",
      icon: "/images/user.png",
    },
    {
      id: 5,
      label: "Logout",
      link: "#",
      icon: "/images/logout.png",
    },
  ],
};

const text = ref([]);
const  manteinanceBookletUrl = ref('');

const changeData = (data) => {
  console.log(data);
  text.value = data;
};

function manteinanceBooklet (){
  let langUrl = "it";
  if (authUser.value.lang != null){
    switch (authUser.value.lang.trim()) {    
    case "FR":
    case "EN":
    langUrl = authUser.value.lang.toLowerCase();
      break
    case "DE":
    case "NL":
    langUrl = "en";
      break;
   }
  } else {
    if (navigator.language != null && navigator.language.split('-') != null && navigator.language.split('-')[0] != null){
      let langTemp = navigator.language.split('-')[0];
      switch (langTemp){
        case "en":
        case "fr":
        case "it":
          langUrl = langTemp;
      }
    }
  } 
  manteinanceBookletUrl.value = 'https://www.poltronesofa.com/public/pdf/LibrettoManutenzione_'+langUrl+'.pdf';
  window.open(manteinanceBookletUrl.value, '_blank');
}
/*
const currentRoute = computed(() => {
  return useRouter().currentRoute.value.name;
});
*/
</script>

<template>
  <MenuMobileDetails
    :class="showCanvansClass"
    :text="text"
    @closeCanvans="closeCanvans"
  />

  <header
    class="main-header m-0 navbar-dark header-sticky header-sticky-smart header-mobile-xl"
  >
    <div class="sticky-area">
      <div
        class="container container-xxl"
        style="border-bottom: 1px solid rgb(204 204 204 / 66%)"
      >
        <div class="container">
          <div class="row d-flex justify-content-between" style="height: 75px">
            <div class="col-3 justify-content-center align-self-center">
              <div class="d-xl-none p-0">
                <a
                  href="#"
                  class="px-0 d-xl-none mr-6 mr-xl-0"
                  @click="
                    changeData(navSite);
                    openCanvans(text);
                    disabledScroll();
                  "
                >
                  <span class="fs-24"><i class="fal fa-bars"></i></span>
                </a>
              </div>
            </div>
            <div class="col-6 text-center justify-content-center align-self-center">
              <a
                href="https://www.poltronesofa.com"
                class="navbar-brand m-0"
                target="_blank"
              >
                <img src="/images/logo.svg" class="normal-logo" />
              </a>
            </div>
            <div class="col-3 text-right justify-content-center align-self-center">
              <div class="d-none d-xl-block">
                <ul
                  class="navbar-nav flex-row ml-auto align-items-center justify-content-lg-end flex-wrap py-2"
                >
                  <li
                    class="nav-item"
                    v-if="authUser?.assistances && authUser.assistances.length > 0"
                  >
                    <router-link
                      to="/assistances"
                      class="nav-link px-2 position-relative"
                    >
                      <i class="far fa-comment fs-large-4 text-dark"></i>
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link dropdown-toggle pl-0 pl-lg-2 text-dark"
                      id="bd-user"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      href="#"
                      >{{ authUser?.name }} {{ authUser?.lastname }}</a
                    >
                    <div
                      class="dropdown-menu dropdown-sm dropdown-menu-right"
                      aria-labelledby="bd-user"
                    >
                      <router-link
                        :to="{ name: 'home' }"
                        active-class="active"
                        class="dropdown-item"
                      >
                        {{ $t("header.menu.home") }}
                      </router-link>
                      <router-link
                        to="/orders"
                        active-class="active"
                        class="dropdown-item"
                      >
                        {{ $t("header.menu.my-orders") }}</router-link
                      >
                      <router-link
                        to="/assistances"
                        active-class="active"
                        class="dropdown-item"
                      >
                        {{ $t("header.menu.my-tickets") }}</router-link
                      >
                      <router-link
                        :to="{ name: 'open-assistance' }"
                        active-class="active"
                        class="dropdown-item"
                        @click="assistanceStore.initializeOrder"
                      >
                        {{ $t("header.menu.open-ticket") }}
                      </router-link>

                      <button class="dropdown-item" style="cursor: pointer;" @click="manteinanceBooklet()" target="_blank">
                        {{ $t("header.menu.maintenance-booklet") }}
                      </button>

                      <hr class="my-3" />
                      <router-link to="/profile" class="dropdown-item">{{
                        $t("pages.home.button.your-data")
                      }}</router-link>

                      <a @click="authStore.logout()" href="#" class="dropdown-item">
                        {{ $t("header.menu.logout") }}</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <router-link
                to="/assistances"
                v-if="authUser?.assistances && authUser.assistances.length > 0"
                class="nav-link px-2 position-relative d-xl-none"
              >
                <span class="far fa-comment fs-20"></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
