import{ref} from 'vue';
import {http,headers} from '../services/http.js';
export  function usePreferences(){


const languages = ref([]);
const loadLanguages = () =>{
  
    const url = "/languages";
    const header = {...headers.json, ...headers.language };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header)
    .get(url)
    .then(res => {
        languages.value = [...res.data]
    })
    .catch(error => {
        if (!error.response) {
            console.log("Please check your internet connection.");
        }
      });
}
    return{
        languages,
        loadLanguages
    }
}