<script setup>
import { ref, computed } from "vue";
import i18n from "../i18n";

import { useUser } from "../use/useUser.js";

import TitlePage from "../components/TitlePage";
import { usePreferences } from "../use/usePreferences.js";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores";

import { Form, Field } from "vee-validate";
import * as Yup from "yup";

import { useI18n } from "vue-i18n";

const { changePassword } = useUser();
const { t } = useI18n();

const schema = Yup.object().shape({
  oldPassword: Yup.string().required(t("error.form-validation.password-required")),
  newPassword: Yup.string().required(t("error.form-validation.password-required")),
  repeatNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], t("error.form-validation.passwords-match"))
    .required(t("error.form-validation.passwords-match-required")),
});

function onSubmit(values) {
  console.log(values);
  changePassword(values);
}

const { loadLanguages } = usePreferences();
loadLanguages();

const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);

const { updateLanguage } = useUser();
const changeLang = (lang) => {
  const dataLanguage = {
    username: authUser.value.mail,
    codLingua: lang,
  };
  updateLanguage(dataLanguage);
  i18n.global.locale.value = lang;
  authStore.changeLanguage(lang);
};

const showPassword = ref(false);
const showNewPassword = ref(false);
const showRepeatNewPassword = ref(false);

const inputType = computed(() => (showPassword.value ? "text" : "password"));
const inputTypeNewPassword = computed(() => (showNewPassword.value ? "text" : "password"));
const inputTypeRepeatNewPassword = computed(() => (showRepeatNewPassword.value ? "text" : "password"));

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}
function toggleNewPasswordVisibility() {
  showNewPassword.value = !showNewPassword.value;
}
function toggleRepeatNewPasswordVisibility() {
  showRepeatNewPassword.value = !showRepeatNewPassword.value;
}
</script>

<template>
  <TitlePage :title="t('pages.profile.title')" />
  <div class="container">
    <div class="row mt-3 mb-6 m-0 justify-content-md-center">
      <div class="col-md-8 col-12 mb-6 mb-md-0 col-md-auto">
        <div class="card shadow-2">
          <div class="card-body py-4 py-md-0 px-0">
            <div class="container">
              <div class="row mt-3 mb-6 m-0">
                <div class="col-12 mb-6 mb-md-0">
                  <div class="media">
                    <span class="fs-32 text-primary text-left mwp-60"
                      ><i class="fal fa-user text-red-gradient"></i
                    ></span>
                    <div class="media-body mt-3">
                      <h4 class="fs-16 lh-2 mb-1 text-dark font-weight-bold">
                        {{ $t("form.field.registry") }}
                      </h4>
                      <div class="row mb-1 pb-1 border-bottom">
                        <div class="col-12 col-md-4">
                          <span> {{ $t("form.label.name") }}</span>
                        </div>
                        <div class="col-12 col-md-8">
                          <span class="text-heading font-weight-500">{{
                            authUser?.name
                          }}</span>
                        </div>
                      </div>
                      <div class="row mb-1 pb-1 border-bottom">
                        <div class="col-12 col-md-4">
                          <span>{{ $t("form.label.lastname") }}</span>
                        </div>
                        <div class="col-12 col-md-8">
                          <span class="text-heading font-weight-500">{{
                            authUser?.lastname
                          }}</span>
                        </div>
                      </div>
                      <div class="row mb-1 pb-1 border-bottom">
                        <div class="col-12 col-md-4">
                          <span>{{ $t("form.label.mobile") }} </span>
                        </div>
                        <div class="col-12 col-md-8">
                          <span class="text-heading font-weight-500">{{
                            authUser?.cell
                          }}</span>
                        </div>
                      </div>
                      <div class="row mb-1 pb-1">
                        <div class="col-12 col-md-4">
                          <span>{{ $t("form.label.email") }} </span>
                        </div>
                        <div class="col-12 col-md-8">
                          <span class="text-heading font-weight-500">{{
                            authUser?.sub
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TitlePage :title="t('pages.profile.second-title')" />
  <div class="container">
    <div id="accordion-style-02" class="accordion accordion-02">
      <div
        class="border-top-0 border-right-0 border-left-0 border-bottom pb-5 mb-4 position-relative"
      >
        <div class="p-0" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-user btn-link fs-22 font-weight-500 p-0 border-0"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              {{ $t("pages.profile.label-accordion.change-language") }}
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion-style-02"
        >
          <div class="card-body px-0 pt-2 pb-1 lh-214">
            <div class="row mb-1 pb-1 align-items-center">
              <div class="col-12 col-md-8 align-middle">
                {{ $t("pages.profile.text-accordion.change-language") }}
              </div>
              <div class="col-12 col-md-4">
                <ul
                  class="navbar-nav flex-row ml-auto align-items-center justify-content-lg-end flex-wrap py-2"
                >
                  <li class="nav-item dropdown">
                    <a
                      class="border nav-link dropdown-toggle px-3 text-dark font-weight-600 btn-rounded waves-effect d-inline-flex align-items-center btn-shadow fs-16 font-weight-400 bg-white"
                      href="#"
                      id="bd-versions"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{ $t("language") }}
                    </a>
                    <div
                      class="dropdown-menu dropdown-sm dropdown-menu-right"
                      aria-labelledby="bd-versions"
                    >
                      <a
                        v-for="(value, key, index) in config.languages"
                        :key="index"
                        class="dropdown-item"
                        @click="changeLang(value)"
                        >{{ key }}</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-top-0 border-right-0 border-left-0 border-bottom pb-5 mb-4 position-relative"
      >
        <div class="border-0 p-0" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-user btn-link fs-22 font-weight-500 p-0 collapsed border-0"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              {{ $t("pages.profile.label-accordion.change-password") }}
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion-style-02"
        >
          <div class="card-body px-0 pt-2 pb-1 lh-214">
            <Form
              :validation-schema="schema"
              @submit="onSubmit"
              v-slot="{ errors, isSubmitting }"
            >
              <div class="form-row mx-n2 mb-5">
                <div class="col-12 col-lg-4 col-xxl-4 px-2">
                  <div class="form-group">
                    <label for="oldPassword" class="text-heading"
                      >{{ $t("form.label.old-password") }}
                    </label>
                    <div class="input-group mb-3">
                      <Field
                        name="oldPassword"
                        :type="inputType"
                        id="oldPassword"
                        autocomplete="oldPassword"
                        class="form-control form-control-lg bg-white"
                        :class="{ 'is-invalid': errors.oldPassword }"
                      />
                      <div
                        class="input-group-append cursor-pointer"
                        @click="togglePasswordVisibility"
                      >
                        <span class="input-group-text"
                          ><i
                            class="far"
                            :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                          ></i
                        ></span>
                      </div>
                      <div class="invalid-tooltip">{{ errors.oldPassword }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4 col-xxl-4 px-2">
                  <div class="form-group">
                    <label for="newPassword" class="text-heading">{{
                      $t("form.label.new-password")
                    }}</label>
                    <div class="input-group mb-3">
                      <Field
                        name="newPassword"
                        :type=inputTypeNewPassword
                        id="newPassword"
                        autocomplete="newPassword"
                        class="form-control form-control-lg bg-white"
                        :class="{ 'is-invalid': errors.newPassword }"
                      />
                      <div class="input-group-append cursor-pointer" @click="toggleNewPasswordVisibility">
                        <span class="input-group-text"><i class="far" :class="showNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i></span>
                      </div>
                      <div class="invalid-tooltip">{{ errors.newPassword }}</div>
                    </div>                    
                  </div>
                </div>
                <div class="col-12 col-lg-4 col-xxl-4 px-2">
                  <div class="form-group">
                    <label for="repeatNewPassword" class="text-heading">{{
                      $t("form.label.repeat-password")
                    }}</label>
                    <div class="input-group mb-3">
                      <Field
                        name="repeatNewPassword"
                        :type="inputTypeRepeatNewPassword"
                        id="repeatNewPassword"
                        autocomplete="repeatNewPassword"
                        class="form-control form-control-lg bg-white"
                        :class="{ 'is-invalid': errors.repeatNewPassword }"
                      />
                      <div class="input-group-append cursor-pointer" @click="toggleRepeatNewPasswordVisibility">
                        <span class="input-group-text"><i class="far" :class="showRepeatNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i></span>
                      </div>
                      <div class="invalid-tooltip">{{ errors.repeatNewPassword }}</div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button
                  :disabled="isSubmitting"
                  class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                >
                  <span
                    v-show="isSubmitting"
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                  {{ $t("form.label.send") }}
                  <span
                    v-show="isSubmitting"
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                  <span class="d-inline-block ml-2 fs-16"
                    ><i class="fal fa-long-arrow-right"></i
                  ></span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid-tooltip {
  left: 0px;
  z-index: 5;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.2rem;
  line-height: 1.47;
  color: rgb(220 53 69);
  background-color: transparent;
  border-radius: 3px;
  font-size: 12px;
  text-align: right;
}
.input-group-append {
  border: 2px solid rgba(108, 117, 125, 0.4784313725);
}
.invalid-tooltip-bottom {
  top: inherit;
  left: 20px;
}
</style>
