export default {
  "seo": {
    "index": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förderbereich - poltronesofà"])}
    }
  },
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "commons": {
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
  },
  "pages": {
    "login": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauchen Sie Hilfe?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie sich anmelden?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "confirm-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Registrierung"])},
      "recover-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie das Passwort vergessen?"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie keine Bestätigungs-E-Mail erhalten?"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie nicht registriert?"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie bereits registriert?"])},
      "back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Login"])},
      "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
      "report-a-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signalisieren Sie ein Problem"])}
    },
    "register": {
      
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Willkommen ", _interpolate(_named("nameOfUser"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie können wir Ihnen helfen?"])},
      "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Argument suchen"])},
      "button": {
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bestellungen"])},
        "assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Tickets"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
        "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waschanweisungen"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "download-warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie herunterladen"])},
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlassen Sie Ihren privaten Bereich"])},
        "home-area-riservata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptmenü"])}
      }
    },
    "orders": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bestellungen"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellnummer"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
      "total-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
      "to-pay-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibende Kosten"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Ticket öffnen"])},
      "ticket-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket offen"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen"])},
      "no-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit liegen keine Bestellungen vor"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "go-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen Sie zu den Serviceanfragen"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Update"])},
      "add-order-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen eines Auftrags zur Liste"])}
    },
    "order": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bestellungen"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung nr."])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
      "show-ticket-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siehe offenes Ticket"])},
      "message-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle mit der aktuellen Bestellung erworbenen Produkte sind unten aufgeführt"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelcode"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "code-article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelcode"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinigung"])},
      "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Ticket öffnen"])},
      "show-all-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bestellungen ansehen"])}
    },
    "assistances": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Tickets"])},
      "ticket-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketnummer"])},
      "data-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellnummer"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Update"])},
      "no-assistances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine offene Tickets"])},
      "inserita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingefügt"])},
      "respinta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
      "chiusa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen"])},
      "inlavorazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
      "inattesadielementi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartende Elemente"])},
      "invisione (attesa rientro)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Ansicht"])}
    },
    "open-assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie ein Ticket"])},
      "assistance-hour-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischen 01:00 und 04:00 Uhr ist die Hilfe nicht verfügbar."])},
      "global-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie eine Kundendienstanfrage stellen? Versuchen Sie zuerst unsere <a href='/home' style='text-decoration: underline;'>FAQ</a>.<br />Es wird auch empfohlen, ein neues Hilfeersuchen über ein Mobiltelefon einzugeben."])},
      "step-one": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbestellung wählen"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihre Bestellung aus dem Menü"])},
        "find-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihre Bestellung nicht in der Liste oben finden? Klicken Sie hier und folgen Sie den Anweisungen, um sie abzurufen und mit Ihrem Benutzer zu verknüpfen"])},
        "has-assistances-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, es gibt eine offene Unterstützung für diesen Auftrag. Es ist nicht möglich, fortzufahren. <u style='color: #d41317'>Gehen Sie zu Ihrer Unterstützungsseite</u>"])},
        "is-not-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung ist nicht zustellbar, da sie nicht zugestellt wurde. Bitte wenden Sie sich an den entsprechenden Shop, um Informationen zur Lieferung zu erhalten."])}
      },
      "step-two": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt auswählen"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wählen Sie das Produkt, für das Sie den Kundendienst öffnen möchten"])}
      },
      "step-three": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreiben Sie das Problem und fügen Sie Bilder und Videos bei"])},
        "label-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte beschreiben Sie das Problem und fügen Sie mindestens ", _interpolate(_named("maxFiles")), " (maximal ", _interpolate(_named("maxSize")), "), einschließlich Fotos und Videos, bei.\nDie folgenden Anhänge sind zulässig, ", _interpolate(_named("ext")), "."])},
        "label-relax-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Entspannungsmechanismus nicht funktioniert, senden Sie uns bitte ein Video, das das Problem zeigt."])},
        "label-description-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatorisches Feld"])},
        "label-warning-file-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, es müssen mindestens 3 Dateien hochgeladen werden."])},
        "label-warning-file-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, die Dateigröße überschreitet die Grenze von 30MB insgesamt"])},
        "label-warning-file-extension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte beachten Sie, dass die folgenden Dateierweiterungen nicht unterstützt werden: ", _interpolate(_named("ext"))])}
      },
      "step-four": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre Daten und senden Sie"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie die eingegebenen Daten und senden Sie den Antrag"])}
      }
    },
    "wash": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waschanleitungen"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptmenü"])}
    },
    "faq-category": {
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben diese möglichen Lösungen gefunden"])},
      "button-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie kehren zum Hauptmenü zurück"])},
      "call-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
      "second-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihr Profil"])},
      "label-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie die Standardsprache"])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie das Passwort"])}
      },
      "text-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie diese Option, um die Standardsprache Ihres reservierten Bereichs zu ändern"])}
      }
    }
  },
  "header": {
    "menu": {
      "poltronesofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poltronesofà"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptmenü"])},
      "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bestellungen"])},
      "my-tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Tickets"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie ein Ticket"])},
      "maintenance-booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartungsheft"])},
      "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenden"])}
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meistgesehene Fragen"])},
    "text-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà S.p.A., C.F. e P.IVA. 03613140403, Valsamoggia (BO) - Loc. Crespellano, Via Lunga n. 16, Unternehmensregister von Bologna REA BO – 462239, Grundkapital i.v. Euro 250.000,00 "])},
    "text-link-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
    "text-link-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
    "text-link-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäfte"])},
    "text-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © poltronesofa S.p.A 2023 - Alle Rechte sind vorbehalten"])}
  },
  "error": {
    "form-validation": {
      "name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name ist erforderlich"])},
      "lastname-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nachname ist erforderlich"])},
      "telephone-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Telefon ist erforderlich"])},
      "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-mail ist erforderlich"])},
      "email-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail ist ungültig"])},
      "email-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mails müssen übereinstimmen"])},
      "email-confirm-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestätigung ist nicht gültig"])},
      "password-lenght": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss aus mindestens 8 Zeichen bestehen"])},
      "password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort ist erforderlich"])},
      "old-password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das alte Passwort ist erforderlich"])},
      "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter müssen übereinstimmen"])},
      "privacy-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zustimmung zum Datenschutz ist erforderlich"])},
      "passwords-match-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwortbestätigung ist erforderlich"])}
    }
  },
  "components": {
    "faq": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waren diese Informationen nützlich?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "open-assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Unterstützung anfragen"])}
    },
    "open-assistance": {
      "open": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mindestens ", _interpolate(_named("maxFiles")), " Bilder zwischen Fotos und Videos anhängen"])}
    },
    "order-row-detail": {
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "other-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Daten"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelcode"])}
    },
    "order-row-wash": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waschanleitung"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere nützliche Informationen"])},
      "composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammensetzung"])}
    },
    "assistance-chat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketnummer: "])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Update: "])},
      "text-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht einfügen"])},
      "response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten innerhalb von 4 Arbeitstagen eine Antwort."])}
    }
  },
  "form": {
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie Ihre E-Mail in der Bestellung, um sie direkt zu importieren"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
      "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen Sie das neue Passwort"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail-Adresse ein und prüfen Sie Ihren Posteingang. Sie werden eine E-Mail mit allen Anweisungen erhalten."])},
      "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie sich gerade registriert haben und keine E-Mail erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder schreiben Sie hier die E-Mail, die Sie für die Registrierung verwendet haben und überprüfen Sie Ihren Posteingang"])},
      "resend-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung erneut senden"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahren Sie mit der Registrierung fort, um sich über Ihre Bestellungen oder den Kundendienst zu informieren"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie sich anmelden?"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon"])},
      "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Bestätigung"])},
      "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort-Bestätigung"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schliessen"])},
      "warranty-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantiecode"])},
      "warrantyCodeTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "add-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assoziieren mit "])},
      "read-understood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe gelesen und verstanden"])},
      "link-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf den Link, um ein Problem zu melden"])},
      "contant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
      "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern im Gange..."])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld ist obligatorisch"])},
      "privacy": {
        "part-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erkläre, dass ich Folgendes gelesen und verstanden habe"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinien"])},
        "part-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemäß den Artikeln 13 und 14 der Verordnung (EU) 2016/679"])}
      }
    },
    "placeholder": {
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail ein"])}
    },
    "field": {
      "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])}
    }
  },
  "modal": {
    "expire-session": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierung abgelaufen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen sich anmelden"])}
    },
    "assistance": {
      "add-order-number": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung korrekt importiert"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bestellung ist nun in Ihrem reservierten Bereich sichtbar."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Auftragserfassung"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Auftrag kann nicht abgerufen werden"])}
        }
      },
      "post-assistance": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützung inklusive"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bestätigen, dass Ihre Unterstützung erfolgreich eingegeben wurde."])}
        },
        "error-response": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Ticket-Eingabe"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Antwort erhalten."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Ticket-Eingabe"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "post-chat-message": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Ticket-Eingabe"])}
        }
      }
    },
    "auth": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Dateneingabe"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "orders": {
      "load-orders": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlermeldung: "])}
        }
      },
      "download-warranty": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlermeldung: "])}
        }
      },
      "send-order": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage kann nicht gesendet werden"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt Probleme mit den zu übertragenden Daten"])}
        }
      }
    },
    "user": {
      "post-registration-user": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzer wurde erfolgreich registriert"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie Ihre Mailbox, um Ihre Anmeldung abzuschließen"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Anmeldung gehen"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Benutzererstellung."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "send-activation": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde gesendet"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihren Posteingang oder Spam und klicken Sie auf den Link, den Sie in der Nachricht finden"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password-email": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde gesendet"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihren Posteingang oder Spam und klicken Sie auf den Link, den Sie in der Nachricht finden"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Senden von Daten"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlermeldung:"])}
        }
      },
      "update-language": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Wechsel der Sprache"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlermeldung:"])}
        }
      },
      "change-password": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wurde erfolgreich geändert"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Ändern des Passworts"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error-no-token-username": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordern Sie die E-Mail zur Passwortänderung erneut an."])}
        }
      }
    }
  }
}