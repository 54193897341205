import { defineStore } from 'pinia';


export const useAssistanceStore = defineStore({
    id: 'assistance',
    state: () => {
        return { faq: 1 }
    },
    getters:{
        getFaq(){
            return this.faq
        }

    },
    actions: {
        setFaq (faqId) {
            this.faq = faqId !== undefined ? faqId : 1;
        }
    }
});
