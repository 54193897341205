import axios from 'axios'
const apiKey = localStorage.getItem("token");



//console.log(apiKey);
// 'Autorization': "Bearer " + apiKey,

export const headers = {
    json : {
        "content-type": "application/json",
    },
    multipart : {
        'content-type': 'multipart/form-data',
    },
    xwwwform : {
        'content-type': 'application/x-www-form-urlencoded',
    },    
    apiKey : {
        'Authorization': "Bearer " + apiKey,
    },      
    cors : {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",     
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
    },
    auth:{
        'Authorization': "Bearer " + apiKey
    },
    responseBlob:{
        'responseType': 'blob'
    },
    accessToken:{
        'access_token': "1234567asdfghj"
    },
    language:{
        'language': header_language()
    }
}

export function header_language(){
    if (localStorage.getItem('lang')!= null){
        return localStorage.getItem('lang')
    }
    return navigator.language.substring(0,2)
}

export function http(base, header) {
//console.log(header);
    //const baseUrl = base ? base : process.env.VUE_APP_BASE_URL;
    const instance = axios.create({
        baseURL : base,
        headers: header
    });

    const get = (url) => {
        return instance({
            method: 'GET',
            url
        });
    }

    const post = (url, data) => {
        return instance({
            method: 'POST',
            data, 
            url
        });
    }

    return {
        get,
        post
    }
} 