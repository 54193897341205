
<!-- TEXT IN THE MIDDLE BASIC -->
<template>
  <section v-if="url" class="d-flex flex-column container p-0 ">
    <div class="image-container ">
      <img class="mb-5 w-100 shadow-lg" :src="url" alt="">
      <div class="centered-text rounded-pill w-100 h-100" style=" background-image:
        /* top, transparent black, faked with gradient */ 
        radial-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0.03),
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.01),
          rgba(0, 0, 0, 0.005)
        )">
        <div class="centered-text">
        <h1 class="display-2 with-box-shadow text-white">{{ title }}</h1> 
        <p class="fs-18 fs-md-20 with-box-shadow text-white d-none d-md-block" v-if="subtitle">{{ subtitle }}</p>
        </div>


      </div>
    </div>
    
    <div class="d-flex align-items-center mb-md-4">
      <div class="container text-center pt-2 d-md-none">
        <p class="fs-16 fs-md-18" v-if="subtitle">
          {{ subtitle }}
        </p>        
        <span class="heading-divider mx-auto mb-md-7"></span>  
      </div>    

    </div>

  </section>
</template>

<style scoped>
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>

<script>
export default {
  name: "TitleFaqCategories",
  props: {
    title: String,
    subtitle: String,
    url: String,
  },
}
</script>

