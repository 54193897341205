import {
    // eslint-disable-next-line no-unused-vars
    Ref,
    ref
} from 'vue';
import {
    http,
    headers
} from '../services/http'
import Swal from 'sweetalert2';
import { useI18n } from "vue-i18n";
import { useUser } from './useUser';
/**
 * @return {{
 * orders: Ref<{orderNumber: string, article: { code: string, description: string }[]}[]>,
 * loadOrders: (filter: string) => void
 * order: Ref<{ head: { orderNumber: string } }>
 * }}
 */
export function useOrders() {
    const { t } = useI18n();
    const orders = ref([]);
    const order = ref({});
    const store = ref([]);
    const row = ref([]);
    const loading = ref([]);
    const showStoreClass = ref("");
    const showAssistanceClass = ref("");
    const showRowClass = ref("");
    const { expireTokenAuth } = useUser();

    const loadOrders = (filter) => {

        loading.value = true;
        const url = "/orders" + filter;
        const header = {
            ...headers.json,
            ...headers.cors,
            ...headers.apiKey,
            ...headers.language
        };
        http(process.env.VUE_APP_BASE_URL, header)
            .get(url)
            .then(res => {
                const ordersData = res.data.map(el => {
                    return {
                        ...el,
                        ...getOrderNumber(el.orderNumber)
                    }
                });
                orders.value = Array.isArray(ordersData) ? [...ordersData] : []
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 401){
                        expireTokenAuth();
                        return 
                    }
                    Swal.fire({
                        title: t("modal.orders.load-orders.error.title") + error.response.status,
                        text: t("modal.orders.load-orders.error.description") +error.response.data.detail,
                        icon: 'error'
                    });
                    //console.log("Please check your internet connection. Lista ordini");
                }
            })
            .finally(() => {
                loading.value = false
            });
    }


    const downloadWarranty = (row,username) => {
        //:href="`/print/warranty_k?numero=${row.orderYear}-${row.orderType}-${row.orderNro}&riga=${row.row}&username=${authStore.user.mail}&lingua=${authStore.lang}`"

        loading.value = true;
        const url = "/print/warranty_k?numero=" + row.orderYear +'-' + row.orderType +'-' + row.orderNro +'&riga=' + row.row  +'&username=' + username;
        const header = {
            ...headers.cors,
            ...headers.responseBlob,
            ...headers.accessToken, 
            ...headers.language
        };
        http(process.env.VUE_APP_BASE_URL, header)
            .get(url)
            .then(response=>{
                console.log(response.request.responseURL);
                window.open(response.request.responseURL);
                if(response.status == 200){
/*
                    const url = window.URL.createObjectURL(new Blob([response.data], { type:"application/pdf" }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'warranty.pdf');
                    document.body.appendChild(link);
                    link.click();
*/
                    /*
                    const url = window.URL.createObjectURL(new Blob([response.data], { type:"application/pdf" }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'warranty.pdf');
                    document.body.appendChild(link);
                    link.click();
                  
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(new Blob([response.data], { type:"application/pdf" }));
                    a.download ="warranty.pdf";
                    document.body.appendChild(a);
                    a.click();
     

                      // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    var newBlob = new Blob([response.data], {type: 'application/pdf'})

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob)
                        return
                    }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob)
                    var link = document.createElement('a')
                    link.href = data
                    link.download = 'warranty.pdf'
                    link.click()
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data)
                    }, 100)
                      */
                }
            })
            .catch(error => {
                console.log(error.response.data.detail);
                if (error.response) {
                    Swal.fire({
                        title: t("modal.orders.download-warranty.error.title") + error.response.status,
                        text: t("modal.orders.download-warranty.error.description") + error.response.data.detail,
                        icon: 'error'
                    });
                    //console.log("Please check your internet connection. Lista ordini");
                }
            })
            .finally(() => {
                loading.value = false
            });
    }


    const sendOrder = (paymentData) => {

        const form = new FormData();
        form.append("MerchantID", paymentData.merchant);
        form.append("MAC", paymentData.mac);
        form.append("CustomField1", paymentData.toPay);
        form.append("CustomField3", paymentData.logo);
        form.append("Len", paymentData.len);
        form.append("Data", paymentData.data);
        form.append("Background", paymentData.background);


        const url = paymentData.actionUrl;
        const header = {
            ...headers.json,
            ...headers.cors,
            ...headers.language
        };
        //if (paymentData.toPay == '.00' || paymentData.toPay <= '0'  ) {
        if (paymentData.toPay != '.00' ) {
            Swal.fire({
                title: t("modal.orders.send-order.error.title"),
                text: t("modal.orders.send-order.error.description"),
                icon: 'error'
            });
        } else {

            http("", header)
                .post(url, form)
                .then(() => {

                })
        }

    }


    const showOrder = (year, type, nro) => {
        loading.value = true;
        const url = "/orders/detail/" + year + "/" + type + "/" + nro;
        const header = {
            ...headers.json,
            ...headers.cors,
            ...headers.apiKey, 
            ...headers.language
        };
        http(process.env.VUE_APP_BASE_URL, header)
            .get(url)
            /*   
              .then(res => {
                 const orderData = res.data.map(el => {
                      return {
                          ...el,
                          ...getOrderNumber(el.head.orderNumber)
                      }
                  });
                  order.value = {
                      ...orderData
                  }

                  console.log(order.value);
              })
                       */
            .then(res => {
                order.value = {
                    ...res.data
                }
                console.log(order.value);
            })

            .catch(error => {
                if (error.response.status == 401){
                    expireTokenAuth();
                    return 
                }
                if (!error.response) {
                    console.log("Please check your internet connection.");
                }
            })
            .finally(() => {
                loading.value = false
            });
    }



    const getOrderNumber = (numero) => {
        try {
            const getOrderNumberData = numero.split("/");
            const year = getOrderNumberData[0],
                type = getOrderNumberData[1],
                nro = getOrderNumberData[2]
            return {
                year,
                type,
                nro
            }
        } catch (error) {
            console.log("Please check your internet connection.")
        }
    }

    const openStoreCanvans = (code) => {

        /**
         * Preparo il js che serve per poter manipolare il dom
         */
        //document.body.style.overflowY = "hidden";

        const url = "/stores/" + code;
        const header = {
            ...headers.json,
            ...headers.cors,
            ...headers.accessToken, 
            ...headers.language
        };
        http(process.env.VUE_APP_BASE_URL, header)
            .get(url)
            .then(res => {
                console.log(code);
                store.value = {
                    ...res.data
                }
            })
            .catch(error => {
                if (error.response.status == 401){
                    expireTokenAuth();
                    return 
                }
                if (error.response) {
                    console.log("Please check your internet connection.");
                }
            });
    }

    const openStore = () => {
        return showStoreClass.value = "show";
    }
    const closeStore = () => {
        return showStoreClass.value = "";
    }



    const openAssistanceCanvans = (code = "F1") => {

        const url = "/assistance";
        const header = {
            ...headers.json,
            ...headers.cors,
            ...headers.apiKey, 
            ...headers.language

        };
        http(process.env.VUE_APP_BASE_URL, header)
            .get(url)
            .then(res => {
                console.log(code);
                store.value = {
                    ...res.data
                }
            })
            .catch(error => {
                if (error.response.status == 401){
                    expireTokenAuth();
                    return 
                }
                if (error.response) {
                    console.log("Please check your internet connection.");
                }
            });


    }

    const openAssistance = () => {
        return showAssistanceClass.value = "show";
    }
    const closeAssistance = () => {
        return showAssistanceClass.value = "";
    }




    const openRowCanvans = (dataRow) => {
        row.value = dataRow;
    }

    const openRow = () => {
        //      console.log("open");
        return showRowClass.value = "show";
    }
    const closeRow = () => {
        return showRowClass.value = "";
    }



    const payOrder = () => {
        alert("Qui posso pagare");
    }

    return {
        sendOrder,
        order,
        store,
        orders,
        row,
        loading,
        showOrder,
        loadOrders,
        getOrderNumber,

        openStoreCanvans,
        openStore,
        closeStore,
        showStoreClass,

        openAssistanceCanvans,
        openAssistance,
        closeAssistance,
        showAssistanceClass,

        openRow,
        closeRow,
        openRowCanvans,
        showRowClass,
        downloadWarranty,
        payOrder
    }
}