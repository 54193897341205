
import { createRouter, createWebHistory} from 'vue-router'

import PublicLayout from "../layouts/Public.vue";
import ReservedLayout from "../layouts/Reserved.vue";
import { ResponseView, LoginView, AssistancesView, FaqsView, OpenAssistancesView, OrdersView, PageNotFound, ProfileView, WashView, DetailsView, ChangePasswordView,RegisterUserView,FaqsCategoryView, OrderPaymentResponseView, OrderPaymentResponseBeView, HomeHelpDeskView,TestView,HomeTestTwoView, ErrorView} from '@/views';

import { useAuthStore } from '@/stores';

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes : [
    {
      path: "/",
      component: PublicLayout,
      children: [
        {
          path: "", component: LoginView,
          meta:{  title: 'Area di supporto - poltronesofà'}
        },
        {
          path: "/change-password", component: ChangePasswordView,
          meta:{  title: 'Cambia password - poltronesofà'}
        },
        {
          path: "/register-user", component: RegisterUserView,
          meta:{  title: 'Registrazione utente - poltronesofà'}
        },
        {
          path: "/response", component: ResponseView,
          meta:{  title: 'poltronesofà'}
        },                   
        {
          path: '/:pathMatch(.*)*',
          name: 'not-fount', component: PageNotFound,
          meta:{ title: 'Errore pagina non trovata '}
        },                   
        {
          path: '/:pathMatch(.*)*',
          name: 'not-fount', component: PageNotFound,
          meta:{ title: 'Errore pagina non trovata '}
        }
      ]
    },
    {
      path: "/in",
      component: ReservedLayout,
      children: [
        {
          path: '/home',
          name: 'home',
          component: HomeTestTwoView,
          meta:{ title: 'Homepage - poltronesofà' }
        },
        {
          path: '/profile',
          name: 'profile',  component: ProfileView,
          meta:{ title: 'Profilo personale - poltronesofà'}
        },
        {
          path: '/orders',
          name: 'orders', component: OrdersView,
          meta:{ title: 'Ordini - poltronesofà' },
        },  
        {
          path: '/order/:year/:type/:nro',
          name: 'order',  component: DetailsView,
          meta:{ title: 'Dettaglio Ordine - poltronesofà' }
        },
        {
          path: '/assistances',
          name: 'assistances',  component: AssistancesView,
          meta:{   title: 'Assistenze - poltronesofà' }
        },
        {
          path: '/open-assistance/:year?/:type?/:nro?',
          name: 'open-assistance',
          component: OpenAssistancesView,
          meta:{ title: 'Dettaglio Assistenza - poltronesofà'  },
        },
        {
          path: '/wash',
          name: 'wash',
          component: WashView,
          meta:{  title: 'Istruzioni Lavaggio - poltronesofà' }
        },
        {
          path: '/faq',
          name: 'faq',
          component: FaqsView,
          meta:{ title: 'Faq - poltronesofà' }
        },
        {
          path: '/faqs-category/:idCategory',
          name: 'faqs-category',
          component: FaqsCategoryView,
          meta:{ title: 'Categoria Faq - poltronesofà' }
        },
        {
          path: '/order-response',
          name: 'order-response',
          component: OrderPaymentResponseView,
          meta:{ title: 'Risposta ordine - poltronesofà' }
        },
        {
          path: '/order-pay-be',
          name: 'order-pay-be',
          component: OrderPaymentResponseBeView,
          meta:{ title: 'Risposta ordine - poltronesofà' }
        },
        {
          path: '/home-help',
          name: 'home-help',
          component: HomeHelpDeskView,
          meta:{ title: 'Aiuto - poltronesofà' }
        },
        {
          path: '/test',
          name: 'test',
          component: TestView,
          meta:{ title: 'Risposta ordine' }
        },
        {
          path: '/error',
          name: 'error',
          component: ErrorView,
          meta:{ title: 'Risposta ordine' }
        }
      ]
    },
  
  ]
});


router.beforeEach( (to,from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/','/change-password','/register-user'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  document.title = to.meta.title || 'Default Title';
  if (authRequired && !auth.user) {
    let nextRoute = to.path;
    let encodeUrl = encodeURI(nextRoute)
    next('/?next='+encodeUrl);
  }
  if(to.params.next != null){
    next(to.params.next)
  }
  next();
});

export default router;

