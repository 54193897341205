export default {
  assistance: {
    maxSize: '30MB',
    maxFiles: 3,
    extensions: {
      image:['jpeg', 'jpg', 'png','webp'],
      video:['mpeg','m4a', 'mp4','m4v', 'avi', 'webm'],
      file:['pdf']
      }
  },
  resources:{
    logo:''
  },
  languages:{
    'Italiano':'it',
    'English':'en',
    'Français':'fr',
    'Deutsch':'de',
    'Vlaams':'nl'     
  },
  format:{
    'date':'DD/MM/YYYY',
    'hour':'HH:mm:ss'
  },

  socials: [
    {
      "id": 1,
      "label": "Facebook",
      "icon": "fa-facebook-f",
      "link": "https://www.facebook.com/poltronesofaspa/"
    },
    {
      "id": 2,
      "label": "Instagram",
      "icon": "fa-instagram",
      "link": "https://www.instagram.com/poltronesofa_official/"
    },
    {
      "id": 2,
      "label": "YouTube",
      "icon": "fa-youtube",
      "link": "https://www.youtube.com/channel/UChZNNDoc8levypRXoTIyUjg"
    },
    {
      "id": 2,
      "label": "Linkedin",
      "icon": "fa-linkedin",
      "link": "https://www.linkedin.com/company/poltronesofa/"
    }
  ],
  
}