import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */



 function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key).default
      }
    })
    return messages
  }

  const numberFormats = {
    'it': {
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      percent: {
        style: 'percent', useGrouping: false
      },     
    },
  }

  const datetimeFormats = {
    'it': {
      short: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },  
    'en-GB': {
      short: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      short_mon: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      },
      short_2_digit:{
          year: 'numeric', month: '2-digit', day: '2-digit'     
      },
      long_2_digit:{
        year: 'numeric', month: '2-digit', day: '2-digit',hour: 'numeric', minute: 'numeric' 
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },  
  }
  
  export default createI18n({
    locale: navigator.language || navigator.userLanguage || 'it',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it',
    messages: loadLocaleMessages(),
    numberFormats,
    datetimeFormats
  })