import{ref} from 'vue';
import moment from 'moment';
import {http, headers} from '../services/http';
import Swal from 'sweetalert2';
import config from '../config';
import { useI18n } from "vue-i18n";
import { useUser } from './useUser';

export  function useAssistances(){

const assistances = ref([]);
const loading = ref([]);
const successCreateAssistance = ref(true); 
const showAssistanceClass = ref("");
const chat = ref([]);
const assistance = ref([]);
const order = ref("");
const { expireTokenAuth } = useUser();


const { t } = useI18n();

const loadAssistances = () =>{
    loading.value = true;
    const url = "/assistances";
    const header = {...headers.json, ...headers.cors, ...headers.apiKey, ...headers.language };
    http(process.env.VUE_APP_BASE_URL, header)
    .get(url)
    .then(res => {
        const assistancesData = res.data.map(el => {
            return {
                ...el,
                ...getOrderNumber(el.orderNumber),
                ...getFormattedDate(el),
                ...toUpperCase(el)
            }
        });
        assistances.value = {
            ...assistancesData
        }
    })
    /*
    .then(res => {
        assistances.value = [...res.data]
    })
    */

    .catch(error => {
        if (error.response.status == 401){
            expireTokenAuth();
            return 
        }
        if (!error.response) {
            console.log("Please check your internet connection.");
        }
      }) 
    .finally(() => {
        loading.value =  false
        });
    }
  /**
    * getOrderNumber
    * Divide la stringa in 3 porzioni da poter passare alla route Order
    */

    const getOrderNumber = (numero) => {
        try {
            const getOrderNumberData = numero.split("/");
            const year = getOrderNumberData[0],
                  type = getOrderNumberData[1],
                  nro  = getOrderNumberData[2]
            return {
                year,
                type,
                nro
            }
        } catch (error) {
            console.log("Please check your internet connection.")
        }
    }

  /**
    * getFormattedDate
    * trasforma la data che arriva da el in una data leggibile italiana
    * TODO: sarà necessario trasformare la data in rapporto alla lingua
    */

    const getFormattedDate = (el) => {

        try {
            const dataOpened = moment(el.dataOpened).format(config.format.date),
                 statusDate  = moment(el.statusDate).format(config.format.date + ' - ' + config.format.hour)
            return {
                dataOpened,
                statusDate
            }
        } catch (error) {
            console.log("Please check your internet connection.")
        }
    }

    const toUpperCase = (el) => {
        try {
            const status = el.status.toLowerCase();
            return {
                status
            }
        } catch (error) {
            console.log("Please check your internet connection.")
        }
    }


    const openAssistanceCanvans = (supportCode) => {
        loading.value = true;
        assistance.value = supportCode;
        const header = {...headers.json, ...headers.cors, ...headers.apiKey, ...headers.language }
        const url = "/message_board/" + supportCode.supportCode + "/10";
        http(process.env.VUE_APP_BASE_URL,header)
        .get(url)
        .then(res => {
            chat.value = [
                ...res.data
            ]
        })
        .catch(error => {
            if (error.response.status == 401){
                expireTokenAuth();
                return 
            }
            if (error.response) {
                console.log("Please check your internet connection.");
            }
        })    
        .finally(() => {
 
            loading.value =  false
        });
    }



    const addOrderNumber = (data) => {
        loading.value = true;
        const url = "/orders/add_order_by_warranty_code";
        const header = {...headers.json, ...headers.cors, ...headers.apiKey, ...headers.language }
        return http(process.env.VUE_APP_BASE_URL, header)
        .post(url, data)

        .then(res => {
            order.value = {
                ...res.data
            }
            console.log(res.data);

            if(res.data.success === true){
            Swal.fire({
                title: t("modal.assistance.add-order-number.success.title"),
                text:  t("modal.assistance.add-order-number.success.description"),
                icon: 'success'
            }).then(function() {
                window.location = "/open-assistance";
            });
            }else{
                Swal.fire({
                    title: t("modal.assistance.add-order-number.error.title"),
                    html:  t("modal.assistance.add-order-number.error.description") + "<br/>" + res.data.message,
                    icon: 'error'
                });

            }
            //console.log(order.value.order);
          })
        .catch(error => {            
            if (error) {
                if (error.response.status == 401){
                    expireTokenAuth();
                    return 
                }
                Swal.fire({
                    title: t("modal.assistance.add-order-number.error.title"),
                    text:  t("modal.assistance.add-order-number.error.description"),
                    icon: 'error'
                });
            }
        })    
        .finally(() => {
        });
   
    }



    const postAssistance = (data) => {
        loading.value = true;
        successCreateAssistance.value = true
        const form = new FormData();
        const files = Array.from(data.files);
            for(const file of files){
                form.append('files', file);
            }
        const faqId =  data.faqId !== undefined ? data.faqId : 1
        form.append("codeCountry", data.codeCountry);
        form.append("orderYear", data.orderYear);
        form.append("orderType", data.orderType);
        form.append("orderNro",  data.orderNro);
        form.append("orderRows", data.rows);
        form.append("username", data.username);
        form.append("description", data.description);
        form.append("faqId", faqId);    
        const url = "/assistances/create";
        const header = {...headers.multipart, ...headers.cors, ...headers.apiKey, ...headers.language }
        return http(process.env.VUE_APP_BASE_URL, header)
        .post(url, form)
        .then(() => {
            Swal.fire({
                title: t("modal.assistance.post-assistance.success.title"),
                text:  t("modal.assistance.post-assistance.success.description"),
                icon: 'success'
            });
          })
        .catch(error => {  
            successCreateAssistance.value = false
            if (error) {
                if(error.response.status == 0 ||  error.response.data === 'undefined' || error.response.headers === 'undefined'){
                    Swal.fire({
                        title: t("modal.assistance.post-assistance.error-response.title"),
                        text:  t("modal.assistance.post-assistance.error-response.description"),
                            icon: 'error'
                    });
                }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
           console.log(error.request);
          } else {
            console.log(error);
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
             // console.log(error.config);
            if (error) {
                if (error.response.status == 401){
                    expireTokenAuth();
                    return 
                }
                Swal.fire({
                    title:t("modal.assistance.post-assistance.error.title"),
                    text: error.response.data.messages[0].toString(),
                    icon: 'error'
                });
            }
        })    
        .finally(() => {
            loading.value =  false;
        });
    }


    const postChatMessage = (data,resetForm) => {
        loading.value = true;
        /*
        if(data.message.trim() ==='')
        return  Swal.fire({
            title:'Invio messaggio',
            text: "Non hai inserito il testo nel messaggio",
            icon: 'error'
        });
        */
        const form = new FormData();
            
        const files = Array.from(data.files);
            for(const file of files){
                form.append('files', file);
            }
        form.append("neg_terz", "C");
        form.append("anno_ass", data.assistance.annoOrdAss);
        form.append("tipo_ass", data.assistance.tipoOrdAss);
        form.append("nro_ass",  data.assistance.nroOrdAss);
        form.append("anno_ord", data.assistance.year);
        form.append("tipo_ord", data.assistance.type);
        form.append("nro_ord",  data.assistance.nro);
        form.append("riga_ass", 10);
        form.append("riga_ord", 10);                                                                
        form.append("username", data.username);     
        form.append("message",  data.message);     

        loading.value = true;
        const url ="/message_board/insert_message";
        const header = {...headers.multipart, ...headers.cors, ...headers.apiKey, ...headers.language }
        return http(process.env.VUE_APP_BASE_URL, header)
        .post(url, form)
        .then(() => {
            resetForm()
        })
        .catch((error) => {
            if (error) {
                if (error.response.status == 401){
                    expireTokenAuth();
                    return 
                }
                Swal.fire({
                    title:t("modal.assistance.post-chat-message.error.title"),                
                    text: error,
                    icon: 'error'
                });
            }
        })    
        .finally(() => {
        });
     }
 


    const  openAssistance = () =>{
        return showAssistanceClass.value = "show";
    }
    const  closeAssistance = () =>{
        return  showAssistanceClass.value = "";
    }


    return{
        addOrderNumber,
        postChatMessage,
        postAssistance,
        assistance,
        assistances,
        loading,
        loadAssistances,
        openAssistanceCanvans,
        openAssistance,
        closeAssistance,
        showAssistanceClass,
        chat,
        order,
        successCreateAssistance
    }
}