<template>
  <section class="d-flex flex-column">
    <div class="d-flex align-items-center mt-4 mb-md-4">
      <div class="container text-center pt-2">
        <h1 class="fs-30 fs-lg-42">{{ title }}</h1>
        <p class="fs-16 fs-md-18" v-if="subtitle">
          {{ subtitle }}
        </p>
        <span class="heading-divider mx-auto mb-md-7"></span>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TitlePage",
  props: {
    title: String,
    subtitle: String,
  },
};
</script>
