<script setup>
import { ref } from "vue";
import { useUser } from "../use/useUser.js";
import { useRegistrationStore } from "@/stores";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const registerUserStore = useRegistrationStore();

const schema = Yup.object().shape({
  name: Yup.string().required(t("error.form-validation.name-required")),
  lastName: Yup.string().required(t("error.form-validation.lastname-required")),
  phoneNumber: Yup.string().required(t("error.form-validation.telephone-required")),
  email: Yup.string()
    .required(t("error.form-validation.email-required"))
    .email(t("error.form-validation.email-invalid")),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], t("error.form-validation.email-must-match"))
    .required(t("error.form-validation.email-confirm-required")),
  password: Yup.string()
    .min(8, t("error.form-validation.password-lenght"))
    .required(t("error.form-validation.password-required")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], t("error.form-validation.passwords-match"))
    .required(t("error.form-validation.passwords-match-required")),
  privacy: Yup.string().required(t("error.form-validation.privacy-required")),
});

const { currentStep, postRegistrationUser } = useUser();

function onSubmit(values) {
  registerUserStore.setUserData(values);
  postRegistrationUser(values);
}

const showPassword= ref(false);
const showPasswordConfirm = ref(false);

</script>

<template>
  <div
    class="d-flex align-items-center overflow-auto"
    style="
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('/images/backgrounds/bg-poltronesofa.jpg');
    "
  >
    <div class="container">
      <div class="row align-items-center vh-100">
        <div class="col-md-10 col-12 mx-auto">
          <div class="card mb-6 mt-10 shadow-lg-4-white">
            <div class="text-center mt-5">
              <a
                href="https://www.poltronesofa.com/"
                target="_blank"
                class="fs-16 fw-bold"
                ><img src="/images/logo.svg" class="normal-logo"
              /></a>
            </div>
            <div class="card-body p-6 login">
              <Form
                :validation-schema="schema"
                @submit="onSubmit"
                v-slot="{ errors, isSubmitting }"
                v-if="currentStep === 0"
              >
                <div class="form-row mx-n2">
                  <div class="col-md-6 col-lg-4 col-xxl-4 px-2">
                    <div class="form-group">
                      <label for="name" class="text-heading">{{
                        $t("form.label.name")
                      }}</label>
                      <Field
                        name="name"
                        type="text"
                        autocomplete="given-name"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': errors.name }"
                      />
                      <div class="invalid-tooltip">{{ errors.name }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 col-xxl-4 px-2">
                    <div class="form-group">
                      <label for="lastName" class="text-heading">{{
                        $t("form.label.lastname")
                      }}</label>
                      <Field
                        name="lastName"
                        type="text"
                        autocomplete="family-name"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': errors.lastName }"
                      />
                      <div class="invalid-tooltip">{{ errors.lastName }}</div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4 col-xxl-4 px-2">
                    <div class="form-group">
                      <label for="phoneNumber" class="text-heading">{{
                        $t("form.label.telephone")
                      }}</label>
                      <Field
                        name="phoneNumber"
                        type="text"
                        autocomplete="phone phonenumber"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': errors.phoneNumber }"
                      />
                      <div class="invalid-tooltip">{{ errors.phoneNumber }}</div>
                    </div>
                  </div>
                </div>
                <div class="form-row mx-n2">
                  <div class="col-md-6 px-2">
                    <div class="form-group">
                      <label for="email" class="text-heading">{{
                        $t("form.label.email")
                      }}</label>

                      <Field
                        name="email"
                        type="text"
                        id="email"
                        autocomplete="username email"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': errors.email }"
                      />
                      <div class="form-text helper-tooltip">{{$t("form.label.emailTooltip")}}</div>
                      <div class="invalid-tooltip">{{ errors.email }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xxl-6 px-2">
                    <div class="form-group">
                      <label for="confirmEmail" class="text-heading">{{
                        $t("form.label.email-confirmation")
                      }}</label>

                      <Field
                        name="confirmEmail"
                        type="text"
                        id="confirmEmail"
                        autocomplete="confirmemail confirm-email"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': errors.confirmEmail }"
                      />
                      <div class="invalid-tooltip">{{ errors.confirmEmail }}</div>
                    </div>
                  </div>
                </div>

                <div class="form-row mx-n2">
                  <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                    <div class="form-group">
                      <label for="password" class="text-heading">{{
                        $t("form.label.password")
                      }}</label>
                      <div style="position: relative;">                      
                          <Field
                            name="password"
                            :type="showPassword ? 'text' : 'password'"
                            id="password"
                            autocomplete="password"
                            class="form-control form-control-lg"
                            :class="{ 'is-invalid': errors.password }"
                          />                        
                        <span class="toggle-password" @click="showPassword = !showPassword">
                          <i v-if="!showPassword" class="fas fa-eye"></i>
                          <i v-if="showPassword" class="fas fa-eye-slash"></i>
                        </span>
                        <div class="invalid-tooltip-relative">{{ errors.password }}</div>
                      </div>                                          
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                    <div class="form-group">
                      <label for="confirmPassword" class="text-heading">
                        {{ $t("form.label.password-confirmation") }}</label
                      >
                      <div style="position: relative;">                        
                        <Field
                          name="confirmPassword"
                          :type="showPasswordConfirm ? 'text' : 'password'"
                          id="confirmPassword"
                          autocomplete="confirm-password"
                          class="form-control form-control-lg"
                          :class="{ 'is-invalid': errors.confirmPassword }"
                        />                        
                        <span class="toggle-password" @click="showPasswordConfirm = !showPasswordConfirm">
                          <i v-if="!showPasswordConfirm" class="fas fa-eye"></i>
                          <i v-if="showPasswordConfirm" class="fas fa-eye-slash"></i>
                        </span>
                        <div class="invalid-tooltip-relative">{{ errors.confirmPassword }}</div>
                      </div>                                            
                    </div>
                  </div>
                </div>

                <div class="form-row mx-n2">
                  <div class="col-md-12 px-2">
                    <div class="custom-control custom-checkbox">
                      <Field
                        name="privacy"
                        type="checkbox"
                        id="privacy"
                        value="true"
                        class="custom-control-input"
                        :class="{ 'is-invalid': errors.privacy }"
                      />
                      <label class="custom-control-label" for="privacy">
                        {{ $t("form.label.privacy.part-one") }}
                        <a
                          href="https://www.poltronesofa.com/Privacy"
                          target="_blank"
                          class="text-decoration-undeline font-weight-700"
                          >{{ $t("form.label.privacy.link") }}</a
                        >
                        {{ $t("form.label.privacy.part-two") }}</label
                      >
                      <div class="invalid-tooltip invalid-tooltip-bottom">
                        {{ errors.privacy }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-6">
                  <button
                    :disabled="isSubmitting"
                    class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2"
                  >
                    <span
                      v-show="isSubmitting"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                    {{ $t("form.label.register") }}
                    <span class="d-inline-block ml-2 fs-16"
                      ><i class="fal fa-long-arrow-right"></i
                    ></span>
                  </button>
                </div>

                <div class="mt-5 text-center">
                  <router-link to="/" class="fs-16 fw-bold">
                    {{ $t("pages.login.registered") }}</router-link
                  >
                </div>
              </Form>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid-tooltip {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 5;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.47;
  color: rgb(220 53 69);
  background-color: transparent;
  border-radius: 3px;
  font-size: 12px;
  left: auto;
}

.invalid-tooltip-relative {
  position: absolute;
  top: -30px;
  right: 10px;
  z-index: 5;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.47;
  color: rgb(220 53 69);
  background-color: transparent;
  border-radius: 3px;
  font-size: 12px;
  left: auto;
}

.invalid-tooltip-bottom {
  top: inherit;
  left: 20px;
}

.helper-tooltip{
  color:#7F7F7F;
  font-size: 12px;
}

.toggle-password {
    position: absolute;
    top: 30%;
    right: 1.8rem;
    cursor: pointer;
    user-select: none;
}

</style>
