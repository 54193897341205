<script setup>
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import TitlePage from "../components/TitlePage";
import NoRecords from "../components/NoRecords";
import LoadingPage from "../components/LoadingPage";
import { useOrders } from "../use/useOrders.js";
import { useAssistanceStore } from "@/stores/assistanceStore";
import StoreDetails from "../layouts/partials/side/StoreDetail.vue";
import AddOrderToList from "@/components/form/AddOrderToList.vue";
import { Tooltip } from "bootstrap";
import { useAuthStore } from "@/stores/auth.store";


onMounted(() => {
  new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  });
});

const router = useRouter();
const authStore = useAuthStore();
const assistanceStore = useAssistanceStore();
const {
  orders,
  store,
  loading,
  loadOrders,
  openStoreCanvans,
  openStore,
  closeStore,
  showStoreClass,
} = useOrders();
loadOrders("");

/**
 *
 * @param {string} orderNumber
 */
const openAssistance = (orderNumber) => {
  assistanceStore.initializeOrder();

  assistanceStore.setOrderNumber(orderNumber, false, true);
  assistanceStore.setStep(2);

  return router.push("/open-assistance");
};

const hasOrders = computed(() => {
  return Object.keys(orders).length !== 0;
});


const isOpen = ref(false);
</script>

<template>
  <TitlePage :title="$t('pages.orders.title')" />

  <AddOrderToList v-model="isOpen" to="#orders-view" />
  <section class="pb-9">
    <div class="container">
      <div class="row position-relative justify-content-center" v-if="hasOrders">
        <LoadingPage :loading="loading" />
        <div class="col-12 text-center mb-7">
          <a
            class="btn btn-outline-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50"
            @click="isOpen = true"
          >
            <div class="media">
              <div class="media-body">
                {{ $t("pages.orders.add-order-to-list") }}
              </div>
            </div>
          </a>
        </div>
        <div class="col-12 col-xl-6" id="orders-view"></div>
        <div class="col-sm-12" v-for="order in orders" :key="order.id">
          <div
            class="card p-4 border shadow-hover-1 mb-6 flex-column flex-lg-row no-gutters"
          >
            <div class="media-body mt-5 mt-lg-0">
              <div class="container px-0">
                <div class="row">
                  <div class="col-6 col-md-3 mb-3">
                    <p class="fs-18 font-weight-700 m-0 p-0 lh-1">
                      {{ $t("pages.orders.order-number") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400">{{
                      order.orderNumber
                    }}</span>
                  </div>
                  <div class="col-6 mb-3 col-md-2 text-right text-md-left">
                    <p class="fs-18 font-weight-700 m-0 p-0 lh-1">
                      {{ $t("pages.orders.data") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400"
                      >{{ $d(order.date, 'short')}}
                    </span>
                  </div>
                  <div class="col-6 col-md-2 mb-3">
                    <p class="fs-18 font-weight-700 m-0 p-0 lh-1">
                      {{ $t("pages.orders.store") }}
                    </p>
                    <a
                      href="#"
                      @click="
                        openStore();
                        openStoreCanvans(order.store.code);
                      "
                      class="fs-16 fs-6 text-success-400"
                      >{{ order.store.desc }}<span class="heading-divider-xs"></span
                    ></a>
                  </div>
                  <div class="col-6 col-md-2 mb-3 text-right text-md-left">
                    <p class="fs-18 font-weight-700 m-0 p-0 lh-1">
                      {{ $t("pages.orders.total-cost") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400">
                     <span v-if="order.currency=='EURO'">€</span> 
                     <span v-else>CHF</span>{{ $n(parseInt(order.totalCost), 'decimal') }}
                    </span>                    
                  </div>                  
                  <div v-if="(order.country == 'F' || order.country=='BE') && order.paymentEnabled == 'OK' && order.totalToPay != null && order.totalToPay != '.00' " class="col-6 col-md-2 mb-3 text-left">
                    <p class="fs-18 font-weight-700 m-0 p-0 lh-1">
                      {{ $t("pages.orders.to-pay-cost") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400">
                      <span v-if="order.currency=='EURO'">€</span> 
                      <span v-else>CHF</span> {{ $n(isNaN(parseFloat(order.totalToPay)) ? 0 : parseFloat(order.totalToPay), 'decimal') }}
                    </span>
                  </div>
                </div>
              </div>              
              <div class="position-relative pt-4 mb-4">
                <div
                  class="d-flex align-items-center"
                  style="overflow: auto; white-space: nowrap"
                >
                  <div class="media align-items-center">
                    <div class="media-body">
                      <ul class="nav row-product d-inline-block pb-1">
                        <li class="nav-item mr-5 d-inline-block">
                          <router-link
                            :to="{
                              name: 'order',
                              params: {
                                year: order.year,
                                type: order.type,
                                nro: order.nro,
                              },
                            }"
                            class="fs-16 fs-6 text-success-400"
                            >{{ $t("commons.detail")
                            }}<span class="heading-divider-xs"></span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-2">
                <div class="d-inline m-0" v-if="order.isDelivered">
                  <span  v-if="!order.hasOpenAssistances">
                  <button
                    @click="openAssistance(order.orderNumber)"
                    class="btn btn-outline-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 mr-2"
                  >
                    {{ $t("pages.orders.open-ticket") }}
                  </button></span
                >
                <span v-else>
                  <router-link
                    to="/assistances"
                    class="btn btn-outline-success fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 mr-2"
                  >
                    {{ $t("pages.orders.ticket-opened") }}
                  </router-link>
                </span>
                </div>
                

                <a
                  v-if="order.country == 'F' && order.paymentEnabled == 'OK' && order.paymentUrl != null"
                  class="btn btn-outline-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 ml-2"
                  :href="`${order.paymentUrl}`"
                >
                  {{ $t("pages.orders.pay") }}
                </a>
                <div v-if="order.country == 'F' && order.paymentEnabled == 'KO'" class="btn btn-outline-success fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 ml-2 btn-payed"
                  data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('pages.orders.payed-tooltip')">{{ $t('pages.orders.payed') }}</div>

                <div class="d-inline" v-if="order.country == 'F' && order.billing== 'Y'">
                  <form method="POST" action="https://support-api.poltronesofa.com/invoice" target="_blank" >
                    <input type="hidden" name="iUsername" :value="authStore.user.mail"/>
                    <input type="hidden" name="iAnno" :value="order.orderYear" />
                    <input type="hidden" name="iTipo" :value="order.orderType"/>
                    <input type="hidden" name="iNumero" :value="order.orderNro" />
                    <button type="submit" class="btn btn-outline-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 ml-2">{{ $t("pages.orders.invoice") }}</button>
                  </form>
                </div>
                <a
                  v-if="order.paymentUrlBe != null"
                  class="btn btn-outline-primary fs-18 p-2 lh-1 mb-1 shadow-none rounded-50 ml-2"
                  :href="`${order.paymentUrlBe}`"
                >
                  {{ $t("pages.orders.pay") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoRecords :message="$t('pages.orders.no-order')" :checkData="orders" />
    </div>
  </section>

  <StoreDetails :class="showStoreClass" :store="store" @closeStore="closeStore" />
</template>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: white;
  color: brown;
  box-shadow: 0 11px 30px 0 rgb(51 51 51 / 15%) !important;
}
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.btn-payed:hover{
  color: #009121 !important;
  background-color: white !important;
  cursor: default !important;
  border-color: #009121 !important;
}
</style>
