<template>
  <div class="canvas-sidebar wash-canvas">
    <div
      class="canvas-overlay"
      @click="
        emitCloseWash('closeWash');
        enabledscroll();
      "
    ></div>
    <div class="h-100">
      <div class="card border-0 h-100">
        <div class="px-6 text-right pt-4">
          <span
            class="canvas-close d-inline-block fs-24 mb-1 ml-auto lh-1 text-primary"
            @click="
              emitCloseWash('closeWash');
              enabledscroll();
            "
            ><i class="fal fa-times"></i
          ></span>
        </div>
        <div class="card-body px-3 pt-0 overflow-y-auto">
          <h5 class="mb-0 border-bottom pb-1 mb-2 font-weight-bold">
            {{ $t('components.order-row-wash.instructions') }}
          </h5>
          <div class="media mb-6">
            <span class="fs-32 text-primary text-left mwp-40"
              ><i class="fal fa-dryer-alt text-red-gradient"></i
            ></span>
            <div class="media-body mt-2">
              <div class="border-bottom mb-4">
                <span class="fs-16 mb-1 text-dark font-weight-bold">{{
                  wash.title
                }}</span>
              </div>

              <div class="row mb-1 pb-1">
                <div class="col-12">
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex align-items-center py-1"
                      :class="{
                        'border-bottom': index + 1 != wash.washingInstructions.length,
                      }"
                      v-for="(instruction, index) in wash.washingInstructions"
                      :key="instruction.id"
                    >
                      <span class="bullet mr-2 mt-1"></span>
                      {{ $filters.capitalizeFirstLetter(instruction.toLowerCase()) }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <h5 class="mb-0 border-bottom pb-1 mb-2 font-weight-bold">
            {{ $t('components.order-row-wash.information') }}
          </h5>
          <div class="media">
            <span class="fs-32 text-primary text-left mwp-40"
              ><i class="far fa-atom-alt text-red-gradient"></i
            ></span>
            <div class="media-body mt-2">
              <h4 class="fs-16 lh-2 mb-1 text-dark font-weight-bold">{{ $t('components.order-row-wash.composition') }}</h4>
              <div class="row mb-1 pb-1">
                <div class="col-12">
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex align-items-center p-0"
                      v-for="comp in wash.composition"
                      :key="comp.id"
                    >
                      <span class="bullet mr-4"></span>{{ comp }}
                    </li>
                  </ul>

                  <ul class="p-0 m-0">
                    <li
                      class="d-flex align-items-center p-0"
                      v-for="comp_back in wash.composition_back"
                      :key="comp_back.id"
                    >
                      <span class="bullet mr-4"></span>{{ comp_back }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
const emitCloseWash = defineEmits(["closeWash"]);
defineProps(["wash"]);

import { helpers } from "../../../helpers/helpers.js";
const { enabledscroll } = helpers();
</script>
