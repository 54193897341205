<script setup>
import TitlePage from "../components/TitlePage";
//import NoRecords from "../components/NoRecords";
import LoadingPage from "../components/LoadingPage";
import { helpers } from "../helpers/helpers.js";
import { useAssistances } from "../use/useAssistances.js";
import AssistanceChat from "../layouts/partials/side/AssistanceChat.vue";
import NoRecords from "../components/NoRecords";
const { disabledScroll } = helpers();

const {
  assistance,
  assistances,
  loading,
  loadAssistances,
  openAssistanceCanvans,
  openAssistance,
  closeAssistance,
  showAssistanceClass,
  chat,
} = useAssistances();

const addToChat = (message) => {
  chat.value.push(message);
  console.log(chat);
};

loadAssistances();
</script>

<template>
  <TitlePage :title="$t('pages.assistances.title')" />
  <section class="pb-9">
    <div class="container">
      <div class="row position-relative" v-if="Object.keys(assistances).length !== 0">
        <LoadingPage :loading="loading" />
        <div class="col-sm-12">
          <div
            class="card p-4 border shadow-hover-1 mb-6 flex-column flex-lg-row no-gutters"
            :style="
              assistance.statusCode === 1
                ? 'backgrpund-color:mintcream'
                : 'backgrpund-color:mintcream'
            "
            v-for="assistance in assistances"
            :assistance="assistance"
            :key="assistance.id"
          >
            <div class="media-body mt-5 mt-lg-0">
              <ul class="list-inline d-flex mb-0 flex-wrap border-bottom">
                <li class="list-inline-item mr-6 mb-3 mr-5 h-100">
                  <p class="fs-20 font-weight-500 m-0 p-0 lh-1 font-weight-600">
                    {{ $t("pages.assistances.ticket-number") }}
                  </p>
                  <span class="fs-16 fw-bold fs-6 text-gray-400">{{
                    assistance.supportCode
                  }}</span>
                </li>

                <li class="list-inline-item mb-3 h-100 ml-auto">
                  <i
                    class="fal fa-comment pl-2 fs-22 font-weight-400 text-success"
                    v-if="assistance.statusCode"
                  ></i>
                </li>
              </ul>

              <div class="container px-0 mt-5">
                <div class="row">
                  <div class="col-6 col-md-6 col-lg-6 mb-3">
                    <p class="fs-16 font-weight-600 m-0 p-0 lh-1">
                      {{ $t("pages.assistances.data-opened") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400">{{
                      assistance.dataOpened
                    }}</span>
                  </div>
                  <div
                    class="col-6 mb-3 col-md-6 col-lg-6 text-right text-md-right text-lg-left"
                  >
                    <p class="fs-16 font-weight-600 m-0 p-0 lh-1">
                      {{ $t("pages.assistances.order-number") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400">
                      <router-link
                        :to="{
                          name: 'order',
                          params: {
                            year: assistance.year,
                            type: assistance.type,
                            nro: assistance.nro,
                          },
                        }"
                        class="fs-16 fs-6 text-grey-400"
                        >{{ assistance.orderNumber }}</router-link
                      ></span
                    >
                  </div>
                  <div class="col-8 col-md-8 col-lg-6 mb-3">
                    <p class="fs-16 font-weight-600 m-0 p-0 lh-1">
                      {{ $t("pages.orders.last-update") }}
                    </p>
                    <span class="fs-16 fw-bold fs-6 text-gray-400">{{
                      assistance.statusDate
                    }}</span>
                  </div>
                  <div
                    class="col-4 col-md-4 col-lg-6 mb-3 text-right text-md-right text-lg-left"
                  >
                    <p class="fs-16 font-weight-600 m-0 p-0 lh-1">
                      {{ $t("pages.orders.status") }}
                    </p>
                    <span
                      class="fs-16 fs-6 font-weight-600"
                      :class="
                        assistance.statusCode === 1 ? 'text-success' : 'text-danger'
                      "
                    >
                      {{ $t("pages.assistances."+assistance.status.toLowerCase().replace(" ","")) }}</span
                    >
                  </div>
                  <div class="col-12 mt-4 mt-md-0 text-center">
                    <div>
                      <button
                        :class="
                          assistance.statusCode === 1
                            ? 'btn-outline-success'
                            : 'btn-outline-danger'
                        "
                        class="btn btn-rounded waves-effect d-inline-flex align-items-center btn-shadow fs-16 font-weight-400"
                        @click="
                          openAssistanceCanvans(assistance);
                          disabledScroll();
                          openAssistance();
                        "
                      >
                        {{
                          assistance.statusCode === 1
                            ? $t("pages.orders.go-message")
                            : $t("pages.orders.go-message")
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NoRecords
        :message="$t('pages.assistances.no-assistances')"
        :checkData="assistances"
      />
    </div>
  </section>

  <AssistanceChat
    v-if="chat"
    :class="showAssistanceClass"
    :chat="chat"
    @addToChat="addToChat"
    :assistance="assistance"
    :loading="loading"
    @closeAssistance="closeAssistance"
  />
</template>
