import { defineStore } from 'pinia';

export const useOpenAssistanceStore = defineStore({
    id: 'open-assistance',
    state: () => {
        return { assistance: {} }
    },
    getters:{
        getAssistanceOrderNumber(){
            return (this.assistance?.order?.head?.orderNumber) ? this.assistance?.order?.head?.orderNumber : this.assistance?.order?.orderNumber;
        },
        getAssistanceOrderArticles(){
            return (this.assistance?.order?.rows) ? this.assistance?.order?.rows : this.assistance?.order?.article;
        },
        getAssistanceProduct(){
            return this.assistance?.row;
        }
    },
    actions: {
        setAssistance ( assistance ) {
            this.assistance = assistance;
        }
    }
});
