<script setup>
import { defineProps, defineEmits } from "vue";
const emitCloseStore = defineEmits(["closeStore"]);
defineProps(["store"]);

import { helpers } from "../../../helpers/helpers.js";
const { enabledscroll } = helpers();
import { useMobileDetection } from "vue3-mobile-detection";

const { isMobile } = useMobileDetection();
</script>

<template>
  <div class="canvas-sidebar store-canvas" id="store-canvas" v-if="store">
    <div
      class="canvas-overlay"
      @click="
        emitCloseStore('closeStore');
        enabledscroll();
      "
    ></div>
    <div class="h-100">
      <div class="card border-0 h-100">
        <div class="px-6 text-right pt-4">
          <span
            class="canvas-close d-inline-block fs-24 mb-1 ml-auto lh-1 text-primary"
            @click="
              emitCloseStore('closeStore');
              enabledscroll();
            "
            ><i class="fal fa-times"></i
          ></span>
        </div>
        <div class="card-body px-3 pt-0 overflow-y-auto">
          <h5 class="mb-0 border-bottom pb-1 mb-2">
            <span class="font-weight-bold">{{ $t("components.store-detail.title") }} {{ store.name }}</span>
          </h5>
          <dl class="m-0 mb-1 p-0 d-flex">
            <dt class="w-180px font-weight-bold">{{ $t("components.store-detail.city") }}</dt>
            <dd class="m-0 ml-auto text-right">{{ store.name }}</dd>
          </dl>
          <dl class="mb-5 p-0 d-flex">
            <dt class="w-180px font-weight-bold">{{ $t("components.store-detail.address") }}</dt>
            <dd class="m-0 ml-auto text-right">{{ store.address }}</dd>
          </dl>

          <dl class="mb-0 mb-1 p-0 d-flex">
            <dt class="w-180px font-weight-bold">{{ $t("components.store-detail.telephone") }}</dt>
            <dd class="m-0 ml-auto text-right">
              <a :href="`tel:${store.telephone}`">{{ store.telephone }}</a>
            </dd>
          </dl>
          <dl class="mb-0 mb-1 p-0 d-flex">
            <dt class="w-180px font-weight-bold">{{ $t("components.store-detail.mail") }}</dt>
            <dd class="m-0 ml-auto text-right">
              <a :href="`mail:${store.mail}`">{{ store.mail }}</a>
            </dd>
          </dl>
          <dl class="mb-5 p-0 d-flex">
            <dt class="w-180px font-weight-bold">{{ $t("components.store-detail.indications") }}</dt>
            <dd class="m-0 ml-auto text-right">
              <a
                :href="`https://maps.google.com/maps?q=${store.lat},${store.lng}`"
                target="_blank"
                >{{ $t("components.store-detail.go-to-map") }}</a
              >
            </dd>
          </dl>
          <h5 class="mb-0 border-bottom pb-1 mb-2">
            <span class="font-weight-bold">{{ $t("components.store-detail.timetables") }}</span>
          </h5>

          <dl
            class="m-0 p-0 d-flex border-bottom pb-1 mb-2"
            v-for="timetable in store.timetables"
            :key="timetable.id"
          >
            <span class="">{{ timetable }} </span>
          </dl>
        </div>
        <div
          class="card-footer mt-auto border-0 bg-transparent px-6 pb-0 pt-5 text-center"
          v-if="isMobile()"
        >
          <p class="p-0 m-0 font-weight-bold">{{ $t("components.store-detail.call") }}</p>
          <a
            :href="`tel:${store.telephone}`"
            class="btn btn-outline-success fs-18 p-2 lh-1 mb-1 shadow-none rounded-50"
            ><i class="fal fa-phone-alt"></i> {{ store.telephone }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
