<script setup>
import { ref, reactive, defineProps, defineEmits, onMounted } from "vue";
import { useAssistances } from "@/use/useAssistances";
import { useAuthStore } from "@/stores/auth.store";
// import { onClickOutside } from "@vueuse/core";

defineProps({
  modelValue: Boolean,
  to: String,
});

const authStore = useAuthStore();

const state = reactive({
  lastName: "",
  name: "",
  warrantyCode: "",
});

const emit = defineEmits(["update:model-value"]);
const { addOrderNumber } = useAssistances();

function close() {
  emit("update:model-value", false);
}

const targetAddOrder = ref();

// onClickOutside(targetAddOrder, close);

function submitOrderTolist() {
  if (!authStore.user) return;

  try {
    addOrderNumber({
      ...state,
      username: authStore.user.mail,
      codLingua: authStore.getLang,
    });
    emit("update:model-value", false);
  } catch (error) {
    console.error(error);
  }
}

const isOpenWarrantyExample = ref(false);
const isOpenOrderExample = ref(false);
const lang = ref('');
function openWarrantyExample(open){
  isOpenWarrantyExample.value = open;
}
function openOrderExample(open){
  isOpenOrderExample.value = open;
}
onMounted(() => {
   lang.value = authStore.getLang;
})

</script>

<template>
  <teleport v-if="modelValue" :to="to">
    <div class="addOrderToList animate__animated animate__fadeInUpBig animate__faster" ref="targetAddOrder">
      <div class="text-right">
        <span
          @click="close"
          class="cursor-pointer d-inline-block fs-14 mb-1 ml-auto lh-1 text-primary"
        >
          {{ $t("form.label.close") }} <i class="fal fa-times"></i
        ></span>
      </div>
      <form
        class="h-100"
        ref="sendMessageToChat"
        id="sendMessageToChat"
        @submit.prevent="submitOrderTolist"
      >
        <div class="form-row mx-n2 mt-5">
          <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
            <div class="form-group">
              <label for="name" class="text-heading">{{ $t("form.label.name") }} </label>
              <input
                type="text"
                v-model.trim="state.name"
                class="form-control form-control-lg"
                id="name"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
            <div class="form-group">
              <label for="lastName" class="text-heading"
                >{{ $t("form.label.lastname") }}
              </label>
              <input
                type="text"
                v-model.trim="state.lastName"
                class="form-control form-control-lg"
                id="lastname"
              />
            </div>
          </div>
        </div>
        <div class="form-group mt-4">
          <label for="title" class="text-heading"
            >{{ $t("form.label.warranty-code") }}
          </label>
          <input
            type="text"
            v-model.trim="state.warrantyCode"
            class="form-control form-control-lg"
            id="warrantyCode"
          />
          <div class="form-text helper-tooltip">
            <i18n-t keypath="form.label.warrantyCodeTooltip" tag="label" for="form.label.warrantyCodeTooltipOrdine">
              <template v-slot:order>
                    <span @click="openOrderExample" style="cursor: pointer; text-decoration: underline;">{{ $t('form.label.warrantyCodeTooltipOrder') }}</span>
              </template>
              <template v-slot:warranty>
                <span @click="openWarrantyExample" style="cursor: pointer; text-decoration: underline;">{{ $t('form.label.warrantyCodeTooltipWarranty') }}</span>
              </template>
            </i18n-t>
          </div>
        </div>
        <div class="actions d-flex justify-content-center">
          <ul role="menu" class="list-unstyled">
            <li aria-hidden="false" aria-disabled="false" class="text-center">
              <button
                role="menuitem"
                :disabled="!state.name || !state.lastName || !state.warrantyCode"
                :class="{
                  'button-disabled':
                    !state.name || !state.lastName || !state.warrantyCode,
                }"
              >
                {{ $t("form.label.add-to") }}
              </button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <div v-if="isOpenWarrantyExample" class="modal animate__animated animate__fadeIn animate__faster">
      <div class="modal-content" @click.stop>
        <span class="close" @click="openWarrantyExample(false)">&times;</span>
        <img class="" :src="'/images/warranty_example/'+lang+'.webp'" alt="lightbox" style="height: 100%;">
      </div>
    </div>
    <div v-if="isOpenOrderExample" class="modal animate__animated animate__fadeIn animate__faster">
      <div class="modal-content" @click.stop>
        <span class="close" @click="openOrderExample(false)">&times;</span>
        <img class="" :src="'/images/order_example/'+lang+'.webp'" alt="lightbox" style="height: 100%;">
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.helper-tooltip{
  color:#7F7F7F;
  font-size: 12px;
}
.modal {
  display: flex;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.85);
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  margin: 15% auto;
  padding: 20px;
  width: auto;
  height: 90%;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
  border: 2px solid black;
  height: 32px;
  width: 32px;
  text-align: center;
  z-index: 9999;
}
</style>
