import { ref } from 'vue'
import useVideoSnapshot from './useVideoSnapshot'

export default function (payload) {
  const { takeScreenshot } = useVideoSnapshot(payload)

  const mediaRecorder = ref(null)
  const blobsRecorderd = ref([])
  const localVideo = ref(null)
  const thumbnail = ref(null)
  const recording = ref(false)

  const init = () => {
    mediaRecorder.value = null
    blobsRecorderd.value = []
    thumbnail.value = null
    localVideo.value = null
  }

  const startRecorder = async () => {
    init()

    const video = document.querySelector(`#${payload.videoId}`)

    if (!video)
      throw new Error(`Cannot find video element with id ${payload.videoId}`)

    if (!video.srcObject)
      throw new Error(`No stream associated with the video element id ${payload.videoId}`)

    mediaRecorder.value = new MediaRecorder(video.srcObject, {
      mimeType: 'video/webm',
    })

    mediaRecorder.value.addEventListener('dataavailable', (e) => {
      blobsRecorderd.value.push(e.data)
    })

    recording.value = true

    mediaRecorder.value.start(1000)

    thumbnail.value = await takeScreenshot()
  }

  /**
   * @return {Promise<{ video: File, thumbnail?: File | undefined }>}
   */
  const stopRecorder = () => new Promise((resolve, reject) => {
    if (!recording.value)
      return reject(new Error('Recording not started.'))

    if (!mediaRecorder.value)
      return reject(new Error('No recorder initialized.'))

    if (!blobsRecorderd.value.length)
      return reject(new Error('Video must last at least 1 second'))

    mediaRecorder.value.stop()

    recording.value = false

    const fullVideo = new Blob(blobsRecorderd.value, { type: 'video/webm' })

    localVideo.value = new File(
      [fullVideo],
      `video-${new Date().getTime()}.webm`,
      { type: 'video/webm' },
    )

    return resolve({
      video: localVideo.value,
      thumbnail: thumbnail.value ?? undefined,
    })
  })

  return {
    recording,
    startRecorder,
    stopRecorder,
  }
}
