<script setup>
import { defineProps } from "vue";
import { helpers } from "../helpers/helpers.js";
import { useAssistanceStore } from "@/stores";
//import Swal from "sweetalert2";
// import { useMobileDetection } from "vue3-mobile-detection";

const assistanceStore = useAssistanceStore();
const props = defineProps({
  suf: {
    type: String,
    default: "faq",
  },
  faqs: {
    type: Object,
  },
  openFaq: {
    type: Function,
  },
  openFaqCanvans: {
    type: Function,
  },
});
const {  scrollToTop } = helpers();
/*
const callAlert = (faq_id) => {
  console.log(faq_id);
  Swal.fire({
    title: "Grazie!",
    icon: "success",
  });
};
*/
const openAssistance = (faq) => {
  console.log(faq);
  assistanceStore.setFaq(faq);
  scrollToTop();
};
</script>

<template>
  <div class="accordion accordion-02" id="faq-list" v-for="faq in faqs" :key="faq.id">
    <div
      class="border-top-0 border-right-0 border-left-0 border-bottom rounded-0 pb-5 mb-4 pl-7 pr-5 position-relative"
    >
      <div class="border-0 p-0" :id="'heading' + props.suf + faq.id">
        <h5 class="mb-0">
          <a
            class="btn btn-faq btn-link fs-18 fs-md-22 font-weight-300 p-0 border-0 text-left font-weight-400 collapsed"
            style="line-height: 1.45"
            data-toggle="collapse"
            :data-target="'#collapse' + props.suf + faq.id"
            aria-expanded="false"
            :aria-controls="'collapse' + props.suf + faq.id"
          >
            {{ faq.attributes.question }}
          </a>
        </h5>
      </div>
      <div
        :id="'collapse' + props.suf + faq.id"
        class="collapse"
        :aria-labelledby="'heading' + props.suf + faq.id"
        data-parent="#faq-list"
        style=""
      >
        <div class="card-body px-0 pt-2 pb-1">
         

          <p class="break-white-space">{{ faq.attributes.answer }}</p>
          <p class="break-white-space">{{ $t("pages.faq-category.call-store") }}</p>
          <div class="border-1 border-top mt-2 pt-2">
            <!--
            <p class="lh-0 m-0 mb-1 p-0 font-weight-800">
              {{ $t("components.faq.label") }}
            </p>
            -->
            <ul class="navbar-nav flex-row align-items-center flex-wrap m-0 p-0">
              <!--
              <li class="nav-item">
                <button
                  class="btn btn-outline-success btn-sm mr-2 disable-before font-weight-400 rounded-lg"
                  @click="callAlert(faq.id)"
                >
                  {{ $t("components.faq.yes") }}
                </button>
              </li>
-->
              <li class="nav-item">
                <router-link
                  @click="openAssistance(faq.id)"
                  to="/open-assistance"
                  class="btn btn-outline-primary btn-sm mr-2 disable-before font-weight-400 rounded-lg"
                  >{{ $t("components.faq.open-assistance") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
