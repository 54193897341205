<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const response = ref('');
response.value = route.query.response
</script>

<template>
    <div class="container">    
        <h3 class="fs-4 text-center m-5">{{ response }}</h3>
    </div>
</template>

