 const filters = {

     limitToWords  (value, size){
        if (!value || !size) return ''
        return value.split(" ").splice(0,size).join(" ") + " ...";
    },   
    countWords (value) {
        if (!value) return ''
        return value.trim().split(/\s+/).length;
    },    
    counArray (value) {
        if (!value) return ''
        return value.length;
    },
    
    capitalizeFirstLetter(value) {
        if (!value) return ''
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    br2nl(str) {
        return str.replace(/<br\s*\/?>/mg, '\n');
    },
    /*
    capitalizeFirstLetters(value) {
        const capitalize = value.charAt(0).toUpperCase() + value.slice(1);
        return value.split(' ').map(capitalize).join(' ');
    },
*/
    strippedContent(value) {
        let regex = /(<([^>]+)>)/ig;
        return value.replace(regex, "");
      },

      findEmptyValueInObject(obj){
        //const key = "code";
        //let index  = obj.find(e => e[key] != '');
        //return index;
        //console.log(index);
        for (let key in obj) {
            if (obj[key] !== null && obj[key] != "")
                return false;
        }
        return true;

          /*
        obj.forEach(o => {
            if (o.key === '') {
                console.log(o);
            }
        });
         */
        //const newArray = obj.filter(element => element.code == "");
        //const key = "code";
        //console.log(obj[key]);
        //console.log(obj);
      },




}


export default filters;