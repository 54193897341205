import{ ref } from 'vue';

const showCanvansClass = ref("");
const canvansContent = ref([]);

export  function helpers(){

    const scrollToTop = ()=> {
         window.scrollTo(0,0)
    }
    
    const disabledScroll = () =>  {
        document.body.style.overflow = "hidden";
        document.body.style.userSelect = "none";
    }

    const enabledscroll = () =>  {
        document.body.style.overflow = "auto";
        document.body.style.userSelect = "auto";
    } 

    const scrollToBottom = () => {
      //  const container = document.getElementById('divToScrollToBottom');
      //  container.scrollTop = container.scrollHeight;
        //let element = document.querySelector(`#chatBottom`);
        //element.scrollIntoView({ behavior: "smooth" });
        const  element = document.getElementById('divToScrollToBottom');
        element.scrollIntoView();
    }

    const limitToWords =  (value, size) =>  {
        if (!value || !size) return ''
        return value.split(" ").splice(0,size).join(" ") + " ...";
    }   

    const openGenericCanvans = (data) => {
        canvansContent.value = data;
    }

    
    
    const  openCanvans = () =>{
        return showCanvansClass.value = "show";
    }
    const  closeCanvans = () =>{
        return  showCanvansClass.value = "";
    }

    const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };

      const checkLangBrowser = () => {
        let userLang = navigator.language || navigator.userLanguage; 
        userLang = userLang.split("-");
        return userLang[0];
      }

    return{
        checkLangBrowser,
        openGenericCanvans,
        canvansContent,
        openCanvans,
        closeCanvans,
        showCanvansClass,
        limitToWords,
        scrollToBottom,
        disabledScroll,
        enabledscroll,
        scrollToTop,
        parseJwt
    }
}