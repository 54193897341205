<script setup>
import { ref, computed, onMounted } from "vue";
import TitlePage from "../components/TitlePage.vue";
import { useAssistances } from "../use/useAssistances.js";
import { useOrders } from "../use/useOrders.js";
import OpenResource from "../components/upload/OpenResource.vue";
import MultipleImagesPreview from "../components/upload/MultipleImagesPreview.vue";
import NoRecords from "../components/NoRecords";
import LoadingPage from "../components/LoadingPage";
import useCamera from "@/composables/useCamera";
import useVideoSnapshot from "@/composables/useVideoSnapshot";
import useVideoRecorder from "@/composables/useVideoRecorder";
import useFiles from "@/composables/useFiles";
import useAssistance from "@/composables/useAssistance";
import { useAssistanceStore } from "@/stores/assistanceStore";
import { useRouter } from "vue-router";
//import { useMobileDetection } from "vue3-mobile-detection";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores";
const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);

//const { isMobile } = useMobileDetection();
const router = useRouter();

const { startCamera, stopCamera, showCamera } = useCamera({
  cameraLabel: "3000",
  videoId: "camera-video",
});

const { takeScreenshot } = useVideoSnapshot({
  videoId: "camera-video",
  canvasId: "camera-canvas",
});
const { startRecorder, stopRecorder, recording } = useVideoRecorder({
  videoId: "camera-video",
  canvasId: "camera-canvas",
});

const {
  files,
  addToFiles,
  preview,
  removeFromFiles,
  onlyFiles,
  clearAllFiles,
} = useFiles();

const takePicture = async () => {
  const image = await takeScreenshot();
  return addToFiles(image);
};

const startRecordCamera = async () => {
  await startRecorder();

  setTimeout(() => stopRecordCamera(), 60000);
};

const stopRecordCamera = async () => {
  if (!recording.value) return;

  const file = await stopRecorder();
  return addToFiles({ resource: file.video, thumbnail: file.thumbnail });
};

const { addOrderNumber } = useAssistances();
const { orders, loadOrders, loading } = useOrders();

// const openCamera = ref(false);

const max = 2000;
const descriptionCounter = computed(() => {
  return assistanceStore.order.description.length
})
/*
 * Ricerca ordine
 */

const name = ref();
const lastName = ref();
const warrantyCode = ref();
const hourEnabled = ref(true);
const lang = ref('');


let isOpen = ref(false);
const open = () => {
  isOpen.value = true;
  //isOpen.value = !isOpen.value;
  //console.log(isOpen.value);
};
const close = () => {
  //console.log("invoco chiudo");
  isOpen.value = false;
};

const submitOrderTolist = () => {
  const sendDataAddOrdersAssistance = {
    username: authUser.value.mail,
    codLingua: authStore.getLang,
    name: name.value,
    lastName: lastName.value,
    warrantyCode: warrantyCode.value,
  };
  addOrderNumber(sendDataAddOrdersAssistance);
};

const imageExists = ref(null);
const checkImageExists = (imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  if (img.complete) {
    imageExists.value = true;
  } else {
    img.onload = () => {
      imageExists.value = true;
    };
    img.onerror = () => {
      imageExists.value = false;
    };
  }
};
checkImageExists();

const assistanceStore = useAssistanceStore();
const { checkCode, moveBack, moveAhead } = useAssistance({
  files: onlyFiles,
  clearAllFiles,
  onSuccess: () => {
    return router.push("/assistances");
  },
});

const articles = computed(
  () =>
    orders.value?.find((o) => o.orderNumber === assistanceStore.order.orderNumber)
      ?.article
);

loadOrders("/open_assistance");

onMounted(() => {
  const now = new Date();
  const currentHour = now.getHours();
  lang.value = authStore.getLang;
  if (currentHour>=1 && currentHour<=3){
    hourEnabled.value = false
  }
  assistanceStore.order.step = 1;
  if (assistanceStore.order.step === 4) moveBack();
});
  

const isOpenWarrantyExample = ref(false);
const isOpenOrderExample = ref(false);

function openWarrantyExample(open){
  isOpenWarrantyExample.value = open;
}
function openOrderExample(open){
  isOpenOrderExample.value = open;
}

</script>

<template>
  <TitlePage :title="$t('pages.open-assistance.title')" />
  <LoadingPage :loading="loading" />
  <div class="container pb-9">
    <div class="row">
      <div class="col-12">
        <p class="mb-5 pb-0">
          <span v-html='$t("pages.open-assistance.global-text")'></span>
        </p>

        <div class="card">
          <div class="card-body">
            <div id="wizard" role="application" class="wizard clearfix vertical">
              <div class="steps clearfix">
                <ul role="tablist">
                  <li
                    role="tab"
                    aria-disabled="false"
                    class="first"
                    :class="{ current: assistanceStore.order.step === 1 }"
                    aria-selected="true"
                  >
                    <a
                      id="wizard-t-0"
                      href="#"
                      aria-controls="wizard-p-0"
                      @click="assistanceStore.setStep(1)"
                    >
                      <span class="current-info audible">current step: </span>
                      <div class="media">
                        <div class="bd-wizard-step-icon">
                          <i class="fal fa-shopping-bag"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="bd-wizard-step-title">
                            1. {{ $t("pages.open-assistance.step-one.title") }}
                          </h6>
                          <p class="bd-wizard-step-subtitle">
                            {{ $t("pages.open-assistance.step-one.sub-title") }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    role="tab"
                    aria-disabled="false"
                    :class="{ current: assistanceStore.order.step === 2 }"
                  >
                    <a
                      id="wizard-t-1"
                      aria-controls="wizard-p-1"
                      @click="assistanceStore.setStep(2)"
                    >
                      <div class="media media-effect">
                        <div class="bd-wizard-step-icon">
                          <i class="fal fa-couch"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="bd-wizard-step-title">
                            2. {{ $t("pages.open-assistance.step-two.title") }}
                          </h6>
                          <p class="bd-wizard-step-subtitle">
                            {{ $t("pages.open-assistance.step-two.sub-title") }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    role="tab"
                    aria-disabled="false"
                    :class="{ current: assistanceStore.order.step === 3 }"
                  >
                    <a
                      id="wizard-t-2"
                      aria-controls="wizard-p-2"
                      @click="assistanceStore.setStep(3)"
                    >
                      <div class="media media-effect">
                        <div class="bd-wizard-step-icon">
                          <i class="fal fa-comment-alt-dots"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="bd-wizard-step-title">
                            3. {{ $t("pages.open-assistance.step-three.title") }}
                          </h6>
                          <p class="bd-wizard-step-subtitle">
                            {{ $t("pages.open-assistance.step-three.sub-title") }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    role="tab"
                    aria-disabled="false"
                    class="last"
                    :class="{ current: assistanceStore.order.step === 4 }"
                  >
                    <a
                      id="wizard-t-3"
                      aria-controls="wizard-p-3"
                      @click="assistanceStore.setStep(4)"
                    >
                      <div class="media media-effect">
                        <div class="bd-wizard-step-icon">
                          <i class="fal fa-badge-check"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="bd-wizard-step-title">
                            4. {{ $t("pages.open-assistance.step-four.title") }}
                          </h6>
                          <p class="bd-wizard-step-subtitle">
                            {{ $t("pages.open-assistance.step-four.sub-title") }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="content clearfix position-relative" id="card-wizard">
                <div v-if="assistanceStore.order.step === 1">
                  <h3 id="wizard-h-0" tabindex="-1" class="title">
                    <div class="media media-effect">
                      <div class="bd-wizard-step-icon">
                        <i class="mdi mdi-account-multiple-outline"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="bd-wizard-step-title">
                          1. {{ $t("pages.open-assistance.step-one.title") }}
                        </h6>
                        <p class="bd-wizard-step-subtitle">
                          {{ $t("pages.open-assistance.step-one.sub-title") }}
                        </p>
                      </div>
                    </div>
                  </h3>
                  <section
                    id="wizard-p-0"
                    role="tabpanel"
                    aria-labelledby="wizard-h-0"
                    class="body current"
                    aria-hidden="false"
                  >
                    <h4>1. {{ $t("pages.open-assistance.step-one.title") }}</h4>

                    <h5
                      v-if="orders.length"
                      class="section-heading fs-15 font-weight-300 mb-3"
                    >
                      {{ $t("pages.open-assistance.step-one.sub-title-tab") }}
                    </h5>
                    <div class="form-group mb-0" v-if="orders.length">
                      <div
                        class="dropdown bootstrap-select form-control shadow-none form-control-lg"
                      >
                        <select
                          class="form-control shadow-none form-control-lg selectpicker"
                          title="Seleziona il tuo ordine"
                          data-style="btn-lg py-2 h-52"
                          id="status"
                          name="status"
                        ></select>
                        <button
                          type="button"
                          tabindex="-1"
                          class="btn dropdown-toggle btn-lg py-2 h-52 bs-placeholder"
                          data-toggle="dropdown"
                          role="combobox"
                          aria-owns="bs-select-1"
                          aria-haspopup="listbox"
                          aria-expanded="false"
                          data-id="status"
                          title="Select"
                        >
                          <div class="filter-option">
                            <div class="filter-option-inner">
                              <div class="filter-option-inner-inner">
                                {{ assistanceStore.order.orderNumber }}
                              </div>
                            </div>
                          </div>
                        </button>
                        <div class="dropdown-menu">
                          <div
                            class="inner show"
                            role="listbox"
                            id="bs-select-1"
                            tabindex="-1"
                          >
                            <ul class="dropdown-menu inner show" role="presentation">
                              <li
                                v-for="order in orders"
                                :key="order?.orderNumber"
                                style="cursor: pointer"
                              >
                                <a
                                  v-if="order?.orderNumber"
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-1-0"
                                  tabindex="0"
                                  aria-setsize="2"
                                  aria-posinset="1"
                                  @click="
                                    assistanceStore.setOrderNumber(order.orderNumber, order.hasOpenAssistances, order.isDelivered)
                                  "
                                >
                                  <span class="text">{{ order.orderNumber }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>                    
                    <div v-if="assistanceStore.order.hasAssistancesOpened" class="section-heading fs-15 font-weight-300 mb-3">
                      <router-link to='/assistances' style='cursor: pointer;color:#000'>
                        <span v-html="$t('pages.open-assistance.step-one.has-assistances-opened')"></span>
                      </router-link>                      
                    </div>
                    <div v-if="assistanceStore.order.isDelivered != null && (!assistanceStore.order.isDelivered)" class="section-heading fs-15 font-weight-300 mb-3">                      
                        <span v-html="$t('pages.open-assistance.step-one.is-not-delivered')"></span>                      
                    </div>
                    <NoRecords
                      :message="$t('pages.open-assistance.no-orders')"
                      :checkData="orders"
                    />
                    <teleport v-if="isOpen" to="#card-wizard">
                      <div class="addOrderToList animate__animated animate__fadeInUpBig animate__faster">
                        <div class="text-right">
                          <span
                            @click="close"
                            class="cursor-pointer d-inline-block fs-14 mb-1 ml-auto lh-1 text-primary"
                          >
                            {{ $t("form.label.close") }} <i class="fal fa-times"></i
                          ></span>
                        </div>
                        <!--
                        <a
                          class="mt-2 fs-14 font-weight-700 m-0 p-0 lh-1 text-primary cursor-pointer d-block lh-15"
                        >
                          <div class="media">
                            <i class="far fa-question-circle fs-20 mr-2"></i>
                            <div class="media-body">
                              Clicca qui per sapere come recuperare i dati di ordine e
                              associarlo al tuo utente
                            </div>
                          </div>
                        </a>
                        -->
                        <form
                          class="h-100"
                          ref="sendMessageToChat"
                          id="sendMessageToChat"
                          @submit.prevent="submitOrderTolist"
                        >
                          <div class="form-row mx-n2 mt-5">
                            <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                              <div class="form-group">
                                <label for="name" class="text-heading"
                                  >{{ $t("form.label.name") }}
                                </label>
                                <input
                                  type="text"
                                  v-model.trim="name"
                                  class="form-control form-control-lg"
                                  id="name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6 col-lg-12 col-xxl-6 px-2">
                              <div class="form-group">
                                <label for="lastName" class="text-heading"
                                  >{{ $t("form.label.lastname") }}
                                </label>
                                <input
                                  type="text"
                                  v-model.trim="lastName"
                                  class="form-control form-control-lg"
                                  id="lastname"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group mt-4">
                            <label for="title" class="text-heading"
                              >{{ $t("form.label.warranty-code") }}
                            </label>
                            <input
                              type="text"
                              v-model.trim="warrantyCode"
                              class="form-control form-control-lg"
                              id="warrantyCode"
                            />
                            <div class="form-text helper-tooltip">
                              <i18n-t keypath="form.label.warrantyCodeTooltip" tag="label" for="form.label.warrantyCodeTooltipOrdine">
                                <template v-slot:order>
                                      <span @click="openOrderExample(true)" style="cursor: pointer; text-decoration: underline;">{{ $t('form.label.warrantyCodeTooltipOrder') }}</span>
                                </template>
                                <template v-slot:warranty>
                                  <span @click="openWarrantyExample(true)" style="cursor: pointer; text-decoration: underline;">{{ $t('form.label.warrantyCodeTooltipWarranty') }}</span>
                                </template>
                              </i18n-t>
                            </div>
                            
                          </div>
                          <div class="actions d-flex justify-content-center">
                            <ul role="menu" class="list-unstyled">
                              <!--v-if-->
                              <li
                                aria-hidden="false"
                                aria-disabled="false"
                                class="text-center"
                              >
                                <button
                                  role="menuitem"
                                  :disabled="!!!name || !!!lastName || !!!warrantyCode"
                                  :class="{
                                    'button-disabled':
                                      !!!name || !!!lastName || !!!warrantyCode,
                                  }"
                                >
                                  {{ $t("form.label.add-to") }}
                                </button>                                
                              </li>
                            </ul>
                          </div>                          
                        </form>                        
                      </div>
                      <div v-if="isOpenWarrantyExample" class="modal animate__animated animate__fadeIn animate__faster">
                          <div class="modal-content" @click.stop>
                            <span class="close" @click="openWarrantyExample(false)">&times;</span>
                            <img class="" :src="'/images/warranty_example/'+lang.toLowerCase()+'.webp'" alt="lightbox" style="height: 100%;">
                          </div>
                        </div>
                        <div v-if="isOpenOrderExample" class="modal animate__animated animate__fadeIn animate__faster">
                          <div class="modal-content" @click.stop>
                            <span class="close" @click="openOrderExample(false)">&times;</span>
                            <img class="" :src="'/images/order_example/'+lang.toLowerCase()+'.webp'" alt="lightbox" style="height: 100%;">
                          </div>
                        </div>
                    </teleport>
                  </section>
                </div>
                <div v-else-if="assistanceStore.order.step === 2">
                  <h3 id="wizard-h-1" tabindex="-1" class="title">
                    <div class="media">
                      <div class="bd-wizard-step-icon">
                        <i class="mdi mdi-cart-outline"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="bd-wizard-step-title">
                          2. {{ $t("pages.open-assistance.step-two.title") }}
                        </h6>
                        <p class="bd-wizard-step-subtitle">
                          {{ $t("pages.open-assistance.step-two.sub-title") }}
                        </p>
                      </div>
                    </div>
                  </h3>
                  <h4>2. {{ $t("pages.open-assistance.step-two.title") }}</h4>
                  <section>
                    <h5 class="section-heading fs-15 font-weight-300 mb-3">
                      {{ $t("pages.open-assistance.step-two.sub-title-tab") }}
                    </h5>
                    <!--  v-if="((currentAssistance.order.article.length >= 1) && $filters.findEmptyValueInObject(currentAssistance.order.article) != false)" -->

                    <!-- APPUNTO: nel caso di selezione a bottoni verticali aggiungere questo d-lg-flex justify-content-lg-center e controllare _custom.scss -->
                    <div>
                      <div
                        class="inputGroup"
                        v-for="(article, index) in articles"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          name="purpose"
                          :id="`radio${index}`"
                          class="purpose-radio-input"
                          :checked="assistanceStore.hasRowCode(article.row)"
                          :label="article.row"
                          @change="checkCode"
                        />
                        <label :for="`radio${index}`" class="purpose-radio-label">
                          <span>{{ article.description }}</span>
                        </label>
                      </div>
                    </div>
                  </section>
                </div>
                <div v-else-if="assistanceStore.order.step === 3">
                  <h3 id="wizard-h-2" tabindex="-1" class="title">
                    <div class="media">
                      <div class="bd-wizard-step-icon">
                        <i class="mdi mdi-credit-card-outline"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="bd-wizard-step-title">
                          3. {{ $t("pages.open-assistance.step-three.title") }}
                        </h6>
                        <p class="bd-wizard-step-subtitle">
                          {{ $t("pages.open-assistance.step-three.sub-title") }}
                        </p>
                      </div>
                    </div>
                  </h3>
                  <section
                    id="wizard-p-2"
                    role="tabpanel"
                    aria-labelledby="wizard-h-2"
                    class="body"
                    aria-hidden="true"
                  >
                    <h4>3. {{ $t("pages.open-assistance.step-three.title") }}</h4>
                    <p class="section-heading fs-15 mb-3 text-primary font-weight-600" style="white-space: break-spaces;">
                      {{
                        $t("pages.open-assistance.step-three.label-description", {
                          maxFiles: config.assistance.maxFiles,
                          maxSize: config.assistance.maxSize,
                          ext: config.assistance.extensions.image.concat(config.assistance.extensions.video).concat(config.assistance.extensions.file).join(", "),
                        })
                      }}
                    </p>
                    <div class="form-group mb-3 position-relative">
                      <textarea
                        id="description"
                        v-model="assistanceStore.order.description"
                        class="form-control"
                        rows="5"
                        :maxlength="max"
                      ></textarea>
                      <div class="form-text helper-tooltip">
                        <span class="text-danger">{{ $t('pages.open-assistance.step-three.label-description-helper') }}</span>
                        <span class="text-danger float-right">{{descriptionCounter}}/2000</span>
                      </div>
                    </div>

                    <template v-if="!showCamera">
                      <button type="button" @click="startCamera" class="d-none">
                        Open Camera
                      </button>
                    </template>
                    <template v-else>
                      <button type="button" @click="stopCamera">Stop Camera</button>

                      <template v-if="!recording">
                        <button type="button" @click="takePicture">Take Snapshot</button>
                        <button type="button" @click="startRecordCamera">
                          Start Recording
                        </button>
                      </template>
                      <template v-else>
                        <button type="button" @click="stopRecordCamera">
                          Stop recording
                        </button>
                      </template>
                    </template>

                    <div
                      class="position-absolute w-100 h-100"
                      style="z-index: 99999"
                      v-show="showCamera"
                    >
                      <video
                        id="camera-video"
                        class="position-relative w-100 h-100"
                        style="background-color: #000"
                      ></video>
                      <canvas id="camera-canvas" style="display: none" />
                    </div>

                    <OpenResource
                      class="mr-3 pt-0 mt-0"
                      input-id="multiple-image-preview-files"
                    />

                    <MultipleImagesPreview
                      input-id="multiple-image-preview-files"
                      :files="files"
                      :image-preview="preview"
                      :add-files="addToFiles"
                      :remove-file="removeFromFiles"
                    />
                    <p class="section-heading fs-15 mb-3 text-primary font-weight-600" style="white-space: break-spaces;">{{ $t("pages.open-assistance.step-three.label-relax-helper") }}</p>
                    <div class="alert alert-danger" v-if="assistanceStore.order.numberOfFiles > 0 && assistanceStore.order.numberOfFiles < 3" >
                      <span class="section-heading fs-15 mb-3 text-primary font-weight-600" style="white-space: break-spaces;">{{ $t("pages.open-assistance.step-three.label-warning-file-number") }}</span>
                    </div>
                    <div class="alert alert-danger" v-if="assistanceStore.order.dimensionOfFiles>30*1024*1024" >
                      <span class="section-heading fs-15 mb-3 text-primary font-weight-600" style="white-space: break-spaces;">{{ $t("pages.open-assistance.step-three.label-warning-file-dimension") }}</span>
                    </div>
                    <div class="alert alert-danger" v-if="assistanceStore.order.extensionNotSupported.length > 0" >
                      <span class="section-heading fs-15 mb-3 text-primary font-weight-600" style="white-space: break-spaces;">{{ $t("pages.open-assistance.step-three.label-warning-file-extension",{ext: assistanceStore.order.extensionNotSupported.join(', ')}) }}</span>
                    </div>
                  </section>
                </div>
                <div v-else>
                  <h3 id="wizard-h-3" tabindex="-1" class="title">
                    <div class="media">
                      <div class="bd-wizard-step-icon">
                        <i class="mdi mdi-send-outline"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="bd-wizard-step-title">
                          {{ $t("pages.open-assistance.step-four.title") }}
                        </h6>
                        <p class="bd-wizard-step-subtitle">
                          {{ $t("pages.open-assistance.step-four.sub-title") }}
                        </p>
                      </div>
                    </div>
                  </h3>
                  <section
                    id="wizard-p-3"
                    role="tabpanel"
                    aria-labelledby="wizard-h-3"
                    class="body"
                    aria-hidden="true"
                  >
                    <h4>4. {{ $t("pages.open-assistance.step-four.title") }}</h4>
                    <h5 class="section-heading fs-15 font-weight-400 mb-3">
                      {{ $t("pages.open-assistance.step-four.sub-title-tab") }}
                    </h5>
                    <div class="media">
                      <div class="media-body mt-3">
                        <div class="row mb-1 pb-1 border-bottom">
                          <div class="col-12 col-md-4">
                            <span class="fs-16 lh-2 mb-1 text-dark font-weight-bold">
                              {{ $t("form.label.name") }}</span
                            >
                          </div>
                          <div class="col-12 col-md-8">
                            <span class="text-heading font-weight-500">{{
                              assistanceStore.order.orderNumber
                            }}</span>
                          </div>
                        </div>
                        <div class="row mb-1 pb-1 border-bottom">
                          <div class="col-12 col-md-4">
                            <span class="fs-16 lh-2 mb-1 text-dark font-weight-bold"
                              >{{ $t("form.label.product") }}
                            </span>
                          </div>
                          <div class="col-12 col-md-8">
                            <span class="text-heading font-weight-500">{{
                              assistanceStore.order.orderRowCodes[0]
                            }}</span>
                          </div>
                        </div>
                        <div class="row mb-1 pb-1">
                          <div class="col-12 col-md-4">
                            <span class="fs-16 lh-2 mb-1 text-dark font-weight-bold">{{
                              $t("form.label.message")
                            }}</span>
                          </div>
                          <div class="col-12 col-md-8">
                            <span class="text-heading font-weight-500">{{
                              assistanceStore.order.description
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div v-if="hourEnabled">
                <div class="actions d-flex justify-content-center">
                  <ul role="menu" aria-label="Pagination">
                    <li aria-disabled="true" v-if="assistanceStore.order.step > 1">
                      <a
                        href="#"
                        class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2 mr-2"
                        @click="moveBack"
                        >{{ $t("form.label.backward") }}</a
                      >
                    </li>
                    <li aria-hidden="false" aria-disabled="false">
                      <button
                        class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2"
                        @click="moveAhead"
                        :disabled="!assistanceStore.currentStepComplete"
                        :class="{ disabled: !assistanceStore.currentStepComplete }"
                        v-if="assistanceStore.order.step < 4"
                      >
                        {{ $t("form.label.forward") }}
                      </button>
                      <button
                        class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2"
                        @click="moveAhead"
                        :disabled="assistanceStore.saveAssistance"
                        :class="{ disabled: assistanceStore.saveAssistance }"
                        v-else
                      >
                        <span v-if="!assistanceStore.saveAssistance">
                          {{ $t("form.label.send") }}
                        </span>
                        <span v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {{ $t("form.label.saving") }}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>              
              </div>
              <div v-else>
                {{ $t("pages.open-assistance.assistance-hour-not-enabled") }}
              </div>            
              <div
                class="mt-5"
                v-if="assistanceStore.order.step === 1 && isOpen === false"
              >
                <a
                  class="mt-2 fs-16 font-weight-700 m-0 p-0 lh-1 text-primary cursor-pointer d-block lh-15"
                  @click="open"
                >
                  <div class="media">
                    <i class="far fa-shopping-bag fs-25 mr-5"></i>
                    <div class="media-body">
                      {{ $t("pages.open-assistance.step-one.find-order") }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.helper-tooltip{
  color:#7F7F7F;
  font-size: 12px;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.85);
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  margin: 15% auto;
  padding: 20px;
  width: auto;
  height: 90%;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
  border: 2px solid black;
  height: 32px;
  width: 32px;
  text-align: center;
  z-index: 999;
}
</style>