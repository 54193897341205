<template>
  <div class="canvas-sidebar row-canvas" v-if="row">
    <div
      class="canvas-overlay"
      @click="
        emitCloseRow('closeRow');
        enabledscroll();
      "
    ></div>
    <div class="h-100">
      <div class="card border-0 h-100">
        <div class="px-6 text-right pt-4">
          <span
            class="canvas-close d-inline-block fs-24 mb-1 ml-auto lh-1 text-primary"
            @click="
              emitCloseRow('closeRow');
              enabledscroll();
            "
            ><i class="fal fa-times"></i
          ></span>
        </div>
        <div class="card-body px-3 pt-0 overflow-y-auto">
          <h5 class="mb-0 border-bottom pb-1 mb-2">
            <span class="font-weight-bold">{{ $t('components.order-row-detail.detail') }}</span>
          </h5>

          <div class="media">
            <span class="fs-32 text-primary text-left mwp-40"
              ><i class="far fa-info-circle text-red-gradient"></i
            ></span>
            <div class="media-body mt-2">
              <p class="mb-3">{{ row.title }}</p>
            </div>
          </div>

          <div v-if="row.description">
            <h5 class="mb-0 border-bottom pb-1 mb-2">
              <span class="font-weight-bold">{{ $t('components.order-row-detail.description') }}</span>
            </h5>
            <div class="media">
              <span class="fs-32 text-primary text-left mwp-40"
                ><i class="far fa-file-alt text-red-gradient"></i
              ></span>
              <div class="media-body mt-2">
                <p class="mb-3">{{ row.description }}</p>
              </div>
            </div>
          </div>
          <h5 class="mb-0 border-bottom pb-1 mb-2">
            <span class="font-weight-bold">{{ $t('components.order-row-detail.other-data') }}</span>
          </h5>
          <div class="media">
            <span class="fs-32 text-primary text-left mwp-40"
              ><i class="far fa-table text-red-gradient"></i
            ></span>
            <div class="media-body mt-2">
              <ul class="list-group list-group-no-border">
                <li
                  class="list-group-item d-flex row m-0 px-0 pt-1 pb-0"
                  v-if="row.article"
                >
                  <span class="col-6 p-0 font-weight-600 mb-1 mb-sm-0"
                    >{{ $t('components.order-row-detail.article-code')}}</span
                  >
                  <span class="col-6 p-0 text-heading text-right">{{ row.article }}</span>
                </li>
                <li
                  class="list-group-item d-flex row m-0 px-0 pt-1 pb-0"
                  v-if="row.article_quantity"
                >
                  <span class="col-6 p-0 font-weight-600 mb-1 mb-sm-0">Quantità</span>
                  <span class="col-6 p-0 text-heading text-right">{{
                    row.article_quantity
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex row m-0 px-0 pt-1 pb-0"
                  v-if="row.article_price"
                >
                  <span class="col-6 p-0 font-weight-600 mb-1 mb-sm-0">Prezzo</span>
                  <span class="col-6 p-0 text-heading text-right"
                    >{{ row.article_price }} €</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
const emitCloseRow = defineEmits(["closeRow"]);
defineProps(["row"]);

import { helpers } from "../../../helpers/helpers.js";
const { enabledscroll } = helpers();
</script>
