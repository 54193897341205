<script setup>
import { useRoute } from "vue-router";
import { ref } from 'vue';
import TitlePage from "../components/TitlePage";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const route = useRoute();
const paymentStatus = ref({code:'', status :'', description:'', msg:'', errorValue:false});
paymentStatus.value.code = route.query.Code;
paymentStatus.value.status = paymentStatus.value.code.substring(paymentStatus.value.code.length -4);
paymentStatus.value.msg = route.query.msg

if (paymentStatus.value.msg != null && paymentStatus.value.code=='00000000' && paymentStatus.value.msg.substring(0,2)=='KO'){
  paymentStatus.value.errorValue = true
}

// paymentStatus.value = { status: paymentStatus.value.substring(paymentStatus.value.length -4, paymentStatus.value.length)};
paymentStatus.value.description = t("payment_status_axepta_fr." + paymentStatus.value.status + ".code");
paymentStatus.value.descriptionDetail = t("payment_status_axepta_fr." + paymentStatus.value.status + ".desc");

// switch (paymentStatus.value.status){
//   case '0200':
    
//     break
//   default:
//     paymentStatus.value.description = 'Si è verificato un errore';
//     break;
// }

// original ref is now disconnected from state.count
</script>

<template>
  <TitlePage :title="$t('pages.order-payment-response.title')" />
  <div class="container">    
    <h3 class="fs-4 text-center">{{ paymentStatus.description }}</h3>
    <br>
    <h5 class="fs-4 text-center payment-detail">{{ paymentStatus.descriptionDetail }}</h5>
    <div class="alert alert-danger text-center" v-if="paymentStatus.errorValue">
      {{$t('pages.order-payment-response.error-contact-store')}}
      
    </div>
    
    <section class="text-center mt-7">
      <router-link
        to="/orders"
        class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
        @click="scrollToTop"
        >{{ $t("pages.order.show-all-order") }}</router-link
      >
    </section>
  </div>  
</template>

<style scoped>
.payment-detail {
  white-space: break-spaces;
  line-height: 1.5;
}
</style>