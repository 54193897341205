<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useUser } from "../use/useUser.js";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import Swal from "sweetalert2";

const route = useRoute();
const router = useRouter();

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, t("error.form-validation.password-lenght"))
    .required(t("error.form-validation.password-required")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], t("error.form-validation.passwords-match"))
    .required(t("error.form-validation.passwords-match-required")),
});

const formValues = {
  username: route.query.username,
  token: route.query.token,
};

const { changePassword } = useUser();
function onSubmit(values) {
  if (values.token == null || values.username == null) {
    Swal.fire({
      title: t("modal.user.change-password.error-no-token-username.title"),
      text: t("modal.user.change-password.error-no-token-username.description"),
      icon: "error",
    });
    return;
  }
  changePassword(values).then(function (results) {
    console.log(results);
    if (results.success) {
      router.push("/");
    }
  });
}
</script>

<template>
  <div
    class="d-flex align-items-center"
    style="
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('/images/backgrounds/bg-poltronesofa.jpg');
    "
  >
    <div class="container">
      <div class="row align-items-center vh-100">
        <div class="col-md-5 col-12 mx-auto">
          <div class="card mb-6 shadow-lg-4-white">
            <div class="text-center mt-5">
              <a href="#" class="fs-16 fw-bold"
                ><img src="/images/logo.png" class="normal-logo"
              /></a>
            </div>
            <div class="card-body p-6 login">
              <Form
                @submit="onSubmit"
                :initial-values="formValues"
                :validation-schema="schema"
                v-slot="{ errors, isSubmitting }"
                autocomplete="off"
              >
                <Field name="username" type="hidden" />
                <Field name="token" type="hidden" />
                <div class="form-group">
                  <label for="title" class="text-heading">{{
                    $t("pages.login.new-password")
                  }}</label>
                  <div class="input-group has-validation">
                    <Field
                      name="password"
                      type="password"
                      autocomplete="off"
                      class="form-control form-control-lg"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <ErrorMessage class="invalid-feedback" name="password" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="title" class="text-heading">{{
                    $t("pages.login.confirm-password")
                  }}</label>
                  <div class="input-group has-validation">
                    <Field
                      name="confirmPassword"
                      type="password"
                      id="password"
                      autocomplete="off"
                      class="form-control form-control-lg"
                      :class="{ 'is-invalid': errors.confirmPassword }"
                    />
                    <ErrorMessage name="confirmPassword" class="invalid-feedback" />
                  </div>
                </div>
                <div class="text-center">
                  <button
                    :disabled="isSubmitting"
                    class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary py-2"
                  >
                    {{ $t("pages.login.login") }}
                    <span
                      v-show="isSubmitting"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                    <span class="d-inline-block ml-2 fs-16"
                      ><i class="fal fa-long-arrow-right"></i
                    ></span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid-tooltip {
  position: absolute;
  top: -30px;
  top: 0px;
  right: 10px;
  z-index: 5;

  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.47;
  color: rgb(220 53 69);
  background-color: transparent;
  border-radius: 3px;
  font-size: 12px;
  left: auto;
}

.invalid-tooltip-bottom {
  top: inherit;
  left: 20px;
}
</style>
