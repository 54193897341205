<script setup>
import { defineProps } from "vue";
import { useFaqs } from "../../../use/useFaqs.js";
import FaqAccordion from "../../../components/FaqAccordion.vue";
import FaqDetails from "../../../layouts/partials/side/FaqDetail.vue";

const props = defineProps({
  route: String,
});

import { useCommons } from "../../../use/useCommon.js";
const { socials, loadSocials } = useCommons();
loadSocials();
//const { scrollToTop } = helpers();
const {
  faqsImportants,
  importants,

  faq,
  openFaq,
  closeFaq,
  showFaqClass,
  openFaqCanvans,
} = useFaqs();
faqsImportants();
</script>

<template>
  <section v-if="props.route == ''">
    <div class="container text-center py-8">
      <h1 class="fs-30 fs-lg-42" v-if="props.route == 'home'">
        {{ $t("footer.title") }} <br class="d-sm-block d-md-none" />
      </h1>
      <span class="heading-divider mx-auto mb-7"></span>
    </div>
  </section>

  <section class="pb-9" v-if="props.route == ''">
    <div class="container" id="faq-page">
      <FaqAccordion
        :faqs="importants"
        :openFaq="openFaq"
        :openFaqCanvans="openFaqCanvans"
        suf="footer"
      />
    </div>
  </section>

  <footer class="pt-10 pb-8 footer">
    <div class="container container-xxl">
      <div class="row">
        <div class="col-md-12 col-lg-12 text-center pt-6">
          <ul class="list-inline mb-0">
            <li class="list-inline-item mr-2" v-for="social in socials" :key="social.id">
              <a
                :href="social.link"
                class="w-40px h-40 rounded-circle bg-grey text-white bg-hover-secondary hover-white d-flex align-items-center justify-content-center"
                ><i class="fab" :class="social.icon"></i
              ></a>
            </li>
          </ul>

          <div class="nav-link fs-13 pt-6">
            <a href="https://www.poltronesofa.com/Trovanegozi">{{
              $t("footer.text-link-store")
            }}</a>
            -

            <a href="https://www.poltronesofa.com/CookiePolicy">{{
              $t("footer.text-link-cookies")
            }}</a>
            -

            <a href="https://www.poltronesofa.com/Privacy" target="_blank">
              {{ $t("footer.text-link-privacy") }}
            </a>
            -

            <a href="https://www.poltronesofa.com/Contatti">
              {{ $t("footer.text-link-problem") }}
            </a>
          </div>
          <p class="nav-link fs-13 p-0 m-0">
            {{ $t("footer.text-copy") }}
          </p>
          <p class="nav-link fs-13 m-0">
            {{ $t("footer.text-address") }}
          </p>
        </div>
      </div>
    </div>
  </footer>
  <FaqDetails :class="showFaqClass" :faq="faq" @closeFaq="closeFaq" />
</template>
