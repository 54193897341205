import{ref} from 'vue';
import {http,headers} from '../services/http.js';
import config from '../config';
export  function useCommons(){


const menus = ref([]);
const socials = ref([]);

const loadMenu = () =>{
    const url = "/menu";
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_STRAPI,header, ...headers.language)
    .get(url)
    .then(res => {
        menus.value = [...res.data]
    })
    .catch(error => {
        if (error.response) {
            console.log("Menu top  has not been loaded");
        }
    })
    .finally(() => {
      
    });
}

const loadSocials = () =>{
    socials.value = config.socials
    /*
    const url = "/socials";
    const header = {...headers.json };
    http(process.env.VUE_APP_BASE_URL_LOCAL,header)
    .get(url)
    .then(res => {
        socials.value = [...res.data]
    })
    .catch(error => {
        if (error.response) {
            console.log("Social icon list has not been loaded");
        }
    })
    .finally(() => {
      
    });
    */
}

    return{
        loadMenu,
        menus,
        loadSocials,
        socials
    }
}