<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useAuthStore } from "@/stores";
import { useFaqs } from "../use/useFaqs.js";
import { helpers } from "../helpers/helpers.js";
import FaqAccordion from "../components/FaqAccordion.vue";
import FaqDetails from "../layouts/partials/side/FaqDetail.vue";
import LoadingPage from "../components/LoadingPage";

const isLoaded = ref(false);
/*
onMounted(() => {
  document.addEventListener(
    "DOMContentLoaded",
    () => {
      isLoaded.value = true;
    },
    { once: true }
  );
});
*/
onMounted(() => {
  // Simula il caricamento del div
  setTimeout(() => {
    isLoaded.value = true;
  }, 200);
});
const authStore = useAuthStore();
const { disabledScroll } = helpers();
const {
  faqs,
  faq,
  allFaqsCategories,
  filterFaqs,
  faqsFiltered,
  openFaq,
  closeFaq,
  showFaqClass,
  openFaqCanvans,
} = useFaqs();
allFaqsCategories();

const searchTerm = ref();
const search = () => {
  if (searchTerm.value.length < 3) return [];
  return filterFaqs(searchTerm.value);
};

/*
const showTab = (index) => {
  if (index == 0) {
    return "active show";
  }
};
*/
const searchTermRef = ref(null);
const dropdownRef = ref(null);
const searchIconVisible = ref(true);

const closeDropdown = (event) => {
  const searchTermInput = searchTermRef.value;
  const dropdown = dropdownRef.value;
  if (searchTermInput.contains(event.target) || dropdown.contains(event.target)) {
    // L'evento click è avvenuto all'interno della dropdown o del componente v-model searchTerm, non fare niente.
    return;
  }

  // L'evento click è avvenuto al di fuori della dropdown e del componente v-model searchTerm, chiudi e svuota la dropdown.
  faqsFiltered.value = null;
};

onMounted(() => {
  document.addEventListener("click", closeDropdown);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", closeDropdown);
});

/*
const handlerLinkWarranty = computed(() => {
  return (
    "https://www.poltronesofa.com/public/pdf/LibrettoManutenzione_" +
    authStore.lang +
    ".pdf"
  );
});
*/
</script>

<template class="bg-cover">
  <div v-if="!isLoaded">
    <!-- Mostra il componente di caricamento finché isLoaded è false -->
    <LoadingPage :loading="loading" />
  </div>
  <div v-else>
    <!-- Mostra il contenuto del div quando isLoaded è true -->

    <section class="d-flex flex-column">
      <div
        class="container container-xxl mt-5"
        v-if="authStore?.assistances && authStore.assistances.length > 0"
      >
        <div class="row">
          <div class="col-sm-12 col-sm-12 text-md-right text-center">
            <router-link
              to="/assistances"
              class="btn btn-outline-primary btn-md rounded-50 fs-16 text-nowrap shadow-hover-lg-1"
              >Vedi le tue assistenze
            </router-link>
          </div>
        </div>
      </div>

      <div class="d-flex pt-4 pb-3 mb-0 mt-1 h position-relative">
        <div class="container text-center mt-4">
          <h1 class="fs-25 fs-lg-42 mb-5">
            {{ $t("pages.home.welcome", { nameOfUser: authUser.value.name }) }} <br />
            {{ $t("pages.home.title", { nameOfUser: authUser.value.name }) }}
            <br class="d-sm-block d-md-none" />
          </h1>

          <div class="form-group position-relative">
            <input
              v-model="searchTerm"
              type="text"
              class="form-control form-control-lg bg-white rounded-resp"
              id="search"
              aria-describedby="search"
              :placeholder="$t('pages.home.search-placeholder')"
              @keyup.tab="search()"
              @keyup.enter="search()"
              @keyup="search()"
              ref="searchTermRef"
              @focus="searchIconVisible = false"
              @blur="searchIconVisible = true"
            />
            <i
              v-show="searchIconVisible"
              class="far fa-search position-absolute fs-20"
              style="top: 19px; right: 19px"
            ></i>
            <div v-if="faqsFiltered && searchTerm" ref="dropdownRef">
              <div class="dropdown bootstrap-select form-control">
                <ul
                  class="dropdown-menu p-0 blur-area rounded-20 show"
                  role="presentation"
                >
                  <li
                    :data-id="faqFiltered.id"
                    v-for="faqFiltered in faqsFiltered"
                    :key="faqFiltered.id"
                    :value="faqFiltered.id"
                    style="cursor: pointer"
                  >
                    <a
                      role="option"
                      class="dropdown-item text-break order-bottom rounded-0 py-3 ws-normal border-bottom"
                      id="bs-select-1-0"
                      tabindex="0"
                      aria-setsize="2"
                      aria-posinset="1"
                      @click="
                        disabledScroll();
                        openFaq();
                        openFaqCanvans(faqFiltered.attributes);
                      "
                      ><span class="text-dark font-weight-700">
                        {{ faqFiltered.attributes.question }}</span
                      ></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div
            class="col-sm-12 col-md-6 col-lg-4 d-flex align-items-stretch"
            role="presentation"
            v-for="faq in faqs"
            :key="faq.id"
          >
            <!--
            <div class="card mb-5 border-0">
              <router-link
                :to="{ name: 'faqs-category', params: { idCategory: faq.id } }"
              >
                <img
                  :src="`/images/backgrounds/faq-category/${faq.attributes.slug}.jpg`"
                />

                <div
                  class="px-3 py-1 bg-red text-white bg-hover-secondary hover-white position-absolute fs-18 cursor-pointer"
                  style="bottom: 10px; left: 10px; color: #000"
                  :class="{ active: faq.id === 1 }"
                  :id="`${faq.attributes.slug}-tab`"
                  data-toggle="tab"
                  :data-target="`#${faq.attributes.slug}`"
                  role="tab"
                  :aria-controls="`${faq.attributes.slug}`"
                  aria-selected="true"
                >
                  {{ faq.attributes.value }}
                </div>
              </router-link>
            </div>
-->
            <router-link
              :to="{ name: 'faqs-category', params: { idCategory: faq.id } }"
              class="w-100"
            >
              <div
                class="card mb-5 border-0 w-100 position-relative"
                :style="{
                  backgroundImage: `url('${faq.attributes.url_image}')`,
                  backgroundPosition: 'bottom',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }"
              >
                <div
                  class="px-3 py-1 bg-red text-white text-center bg-hover-secondary hover-white fs-18 cursor-pointer m-2"
                  style="
                    bottom: 10px;
                    left: 10px;
                    color: #000;
                    margin-top: 150px !important;
                  "
                  :class="{ active: faq.id === 1 }"
                  :id="`${faq.attributes.slug}-tab`"
                  data-toggle="tab"
                  :data-target="`#${faq.attributes.slug}`"
                  role="tab"
                  :aria-controls="`${faq.attributes.slug}`"
                  aria-selected="true"
                >
                  {{ faq.attributes.value }}
                </div>
              </div>
            </router-link>
          </div>
          <div class="tab-content shadow-none container" id="myTabContent" v-if="faqs">
            <div
              class="tab-pane fade"
              :id="`${faq.attributes.slug}`"
              role="tabpanel"
              :aria-labelledby="`${faq.attributes.slug}-tab`"
              v-for="faq in faqs"
              :key="faq.id"
            >
              <div class="accordion accordion-02" :class="faq.id ? 'mt-4' : ''">
                <div class="container text-center py-8">
                  <h3 class="fs-20 fs-lg-32 text-primary">
                    {{ faq.attributes.value }} <br class="d-sm-block d-md-none" />
                  </h3>
                  <span class="heading-divider mx-auto mb-7"></span>
                </div>

                <FaqAccordion
                  :faqs="faq.attributes.faqs.data"
                  :openFaq="openFaq"
                  :openFaqCanvans="openFaqCanvans"
                  suf="page"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FaqDetails :class="showFaqClass" :faq="faq" @closeFaq="closeFaq" />
  </div>
</template>
