import{ref,onMounted } from 'vue';

import {http, headers} from '../services/http';
import Swal from 'sweetalert2';
import { useI18n } from "vue-i18n";

import { useRouter } from 'vue-router';
import { useAuthStore } from "@/stores";

export  function useUser(){
    
const { t } = useI18n();
const currentStep = ref(0);
const loading = ref([]);

const route = ref('');
const router = useRouter();
const authStore = useAuthStore();

onMounted(() => {
  route.value = router.currentRoute.value.path; 
});

const sendActivation = (form) => {
    loading.value = true;
    const data = { username: form.toLowerCase().trim() };
    const url = "/user/send_activation_mail";
    const header = {...headers.json, ...headers.accessToken, ...headers.cors, ...headers.language }
    return http(process.env.VUE_APP_BASE_URL, header)
    .post(url, data)
    .then(() => {
        Swal.fire({
            title: t("modal.user.send-activation.success.title"),
            text: t("modal.user.send-activation.success.description"),
            icon: 'success'
        });
      })
    .catch(error => {
        console.log(error);
        if (error) {
           Swal.fire({
            title: t("modal.user.send-activation.error.title"),
            text: t("modal.user.send-activation.error.description") + error.response.data.message,
            icon: 'error'
        });
        }
    })    
    .finally(() => {
        loading.value =  false;
    });
}

const changePasswordEmail = (username) => {
    loading.value = true;

    const data = { username: username.toLowerCase().trim() };
    const url = "/user/change_password_mail";
    const header = {...headers.json, ...headers.accessToken, ...headers.cors, ...headers.language }
    return http(process.env.VUE_APP_BASE_URL, header)
    .post(url, data)
    .then(() => {
        Swal.fire({
            title: t("modal.user.change-password-email.success.title"),
            text: t("modal.user.change-password-email.success.description"),
            icon: 'success'
        });
      })
    .catch(error => {
        console.log(error);
        if (error.response.status == 401){
            expireTokenAuth();
            return 
        }
        Swal.fire({
            title: t("modal.user.change-password-email.error.title"),
            html:  t("modal.user.change-password-email.error.description") + error.response.data.message,
            icon: 'error'
        });
    })    
    .finally(() => {
        loading.value =  false;
    });
}

const expireTokenAuth = () => {
    authStore.logout()
    Swal.fire({
        title: t('modal.expire-session.title'),
        text: t('modal.expire-session.description'),
        icon: 'error'
    });
}



const updateLanguage = (data) => {
    loading.value = true;

    const url = "/user/change_lang";
    const header = {...headers.json, ...headers.apiKey, ...headers.cors, ...headers.language }
    return http(process.env.VUE_APP_BASE_URL, header)
    .post(url, data)
    .then(() => {
        window.location.reload();
        /*
        Swal.fire({
            title:'Modifica effettuata con successo',
            text: "La tua lingua di default è stata modifica con successo",
            icon: 'success'
        }).then(() => {
            window.location.reload();
          });
          */
      })
    .catch(error => {
        console.log(error);
        Swal.fire({
            title: t("modal.user.update-language.error.title"),
            text:  t("modal.user.update-language.error.description") + error.response.data.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
    })    
    .finally(() => {
        loading.value =  false;
    });
}

const changePassword = (password) => {
    loading.value = true; 

    let data;
    let url;
    let header;

    if (route.value === '/profile') {
     data = {
        "username": authStore.user.mail,
        "old_password": password.oldPassword,
        "password": password.newPassword,
        "confirm_password": password.repeatNewPassword
      }
      url = "/user/change_password_logged_user";
      header = {...headers.json, ...headers.apiKey, ...headers.cors, ...headers.language }

    }else{
        data = {
            "token": password.token,
            "username": password.username,
            "password": password.password,
            "confirm_password": password.confirmPassword
          }
          url = "/user/change_password";
          header = {...headers.json, ...headers.accessToken, ...headers.cors, ...headers.language }
      }

/*
const data = {
    "token": password.token,
    "username": password.username,
    "password": password.password,
    "confirm_password": password.confirmPassword
  }
  const url = "/user/change_password";
  const header = {...headers.json, ...headers.apiKey, ...headers.cors }
*/
    return http(process.env.VUE_APP_BASE_URL, header)
    .post(url, data)
    .then(() => {
        Swal.fire({
            title: t("modal.user.change-password.success.title"),
            text: t("modal.user.change-password.success.description"),
            icon: 'success'
        });
        return {'success':true};
      })
    .catch(error => {
        console.log(error);
        Swal.fire({
            title: t("modal.user.change-password.error.title"),
            text:  t("modal.user.change-password.error.description") + error.response.data.message,
            icon: 'error'
        });
        return {'success':false};
    })    
    .finally(() => {
        loading.value =  false;
    });
}



const postRegistrationUser = (user) => {
    loading.value = true;
    const data = {
        "language": "it",
        "mail": user.email.toLowerCase().trim(),
        "name": user.name,
        "lastname": user.lastName,
        "confirm_mail": user.confirmEmail.toLowerCase().trim(),
        "password": user.password,
        "confirm_password": user.confirmPassword,
        "phone_number": user.phoneNumber,
        "privacy_website": user.privacy
      }

   
    const url = "/user/create_user";
    const header = {...headers.json, ...headers.cors, ...headers.language }
    return http(process.env.VUE_APP_BASE_URL, header)
    .post(url, data)
    .then(() => {
        Swal.fire({
            title: t("modal.user.post-registration-user.success.title"),
            text: t("modal.user.post-registration-user.success.description"),
            icon: 'success',
            confirmButtonText: t("modal.user.post-registration-user.success.button"),
        }).then((result) => {
            if (result.value) {
              window.location.href = `/`
            }
          });
      })
    .catch(error => {
        console.log(error.response.data.message);
        Swal.fire({
            title: t("modal.user.post-registration-user.error.title"),
            html: error.response.data.message,
            icon: 'error'
        }).then((result) => {
            if (result.value) {
              window.location.href = `/`
            }
          });
    })    
    .finally(() => {
        loading.value =  false;
    });
}



    const randomImages = () =>{
        const number = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
        const image ="/images/backgrounds/login_background_" + number +".jpg";
        return image;
    }

    const changeStep = (step) =>{
        currentStep.value = step
    }

    return{
        updateLanguage,
        changePasswordEmail,
        changePassword,
        sendActivation,
        changeStep,
        randomImages,
        currentStep,
        postRegistrationUser,
        expireTokenAuth
    }
}