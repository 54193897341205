<template>
  <div class="alert" :class="type === error ? 'alert-danger' : 'alert-success'"  role="alert">
  <h4 class="alert-heading">Attenzione!</h4>
  <p>Errore</p>
  <hr>
  <p class="mb-0">{{msg}}</p>
</div>
  </template>
  <script>
  export default {
    name: "errorHandler",
    props: {
      msg: String,
      error: String,
      
    },
  };
  </script>