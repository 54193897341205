<script setup>
import { defineEmits, defineProps } from "vue";
import { helpers } from "../../../helpers/helpers.js";
import { useAssistanceStore } from "@/stores";
//import Swal from "sweetalert2";

const emitCloseFaq = defineEmits(["closeFaq"]);
defineProps({
  faq: {
    type: Object,
    required: true,
  },
});
const { enabledscroll, scrollToTop } = helpers();

const assistanceStore = useAssistanceStore();
const openAssistance = (faq) => {
  console.log(faq);
  assistanceStore.setFaq(faq);
  scrollToTop();
};
/*
const callAlert = (faq_id) => {
  console.log(faq_id);
  Swal.fire({
    title: "Grazie!",
    icon: "success",
  });
};
*/
</script>
<template>
  <div class="canvas-sidebar faq-canvas">
    <div
      class="canvas-overlay"
      @click="
        emitCloseFaq('closeFaq');
        enabledscroll();
      "
    ></div>

    <div class="h-100">
      <div class="card border-0 h-100">
        <div class="card-header text-right pt-4 d-flex align-middle mb-2">
          <div class="font-weight-bold justify-content-start d-flex align-middle">
            <i class="fal fa-question-circle text-red-gradient fs-20 mr-1"></i>
            <span>Dettaglio faq</span>
          </div>

          <span
            class="canvas-close d-inline-block fs-24 ml-auto lh-1 text-primary justify-content-end"
            @click="
              emitCloseFaq('closeFaq');
              enabledscroll();
            "
            ><i class="fal fa-times"></i
          ></span>
        </div>
        <div class="card-body px-3 pt-0 overflow-y-auto">
          <div class="media mb-6">
            <div class="media-body mt-2">
              <div class="mb-4">
                <span
                  class="fs-16 mb-1 text-dark font-weight-bold"
                  v-if="faq?.attributes?.question"
                >
                  {{ faq?.attributes?.question }}
                </span>
                <span class="fs-16 mb-1 text-dark font-weight-bold" v-else>
                  {{ faq?.question }}
                </span>
              </div>
              <p v-if="faq?.attributes?.answer">{{ faq?.attributes?.answer }}</p>
              <p v-else>{{ faq?.answer }}</p>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="mb-2">
            <!--
            <p class="lh-0 mb-2 p-0 font-weight-700">
              {{ $t("components.faq.label") }}
            </p>
            -->
            <ul class="navbar-nav flex-row align-items-center flex-wrap m-0 p-0">
              <!--
              <li class="nav-item">
                <button
                  class="btn btn-outline-success btn-sm mr-2 disable-before font-weight-400 rounded-lg"
                  @click="callAlert(faq.id)"
                >
                  {{ $t("components.faq.yes") }}
                </button>
              </li>
-->
              <li class="nav-item">
                <router-link
                  @click="
                    openAssistance(faq.id);
                    emitCloseFaq('closeFaq');
                    enabledscroll();
                  "
                  to="/open-assistance"
                  class="btn btn-outline-primary btn-sm mr-2 disable-before font-weight-400 rounded-lg"
                  >{{ $t("components.faq.open-assistance") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
