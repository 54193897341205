// eslint-disable-next-line no-unused-vars
import { watch, Ref } from 'vue'
import { useAssistanceStore } from '@/stores/assistanceStore'
import { useAssistances } from "../use/useAssistances";
import { useAuthStore } from "@/stores";
import useFiles from "@/composables/useFiles";
const { getSumFileDimension, isExtensionNotSupported } = useFiles();
/**
 * 
 * @param {{files: Ref<File[]>, clearAllFiles: () => void, onSuccess: () => void|Promise<void>}} param0 
 */
export default function ({ files, clearAllFiles, onSuccess }) {
  const assistanceStore = useAssistanceStore()
  const authStore = useAuthStore();

  const { postAssistance, successCreateAssistance } = useAssistances();

  /**
   * 
   * @param {Event | string} payload
   */
  const selectOrderNumber = (payload) => {
    if (typeof payload === 'string')
      return assistanceStore.setOrderNumber(payload)

    const { value } = payload.target
    assistanceStore.setOrderNumber(value)
  }

  /**
   * 
   * @param {Event} e 
   */
  function checkCode(e) {
    
    const { checked, attributes } = e.target

    const attr = attributes.getNamedItem('label')
    if (!attr?.value)
      throw new Error('No valid code')

    return checked
      ? assistanceStore.addRowCodes(attr.value)
      : assistanceStore.removeRowCodes(attr.value)
  }

  const moveBack = () => {
    if (assistanceStore.order.step > 1)
      assistanceStore.setStep(assistanceStore.order.step - 1)
  }

  const moveAhead = async () => {
    if (assistanceStore.order.step < 4)
      return assistanceStore.setStep(assistanceStore.order.step + 1)
      console.log("step >= 4", assistanceStore.order.step,assistanceStore.currentStepComplete, assistanceStore.orderAssistanceBody)

    if (assistanceStore.order.step === 4 && assistanceStore.currentStepComplete && assistanceStore.orderAssistanceBody){
      assistanceStore.saveAssistance = true;
      await postAssistance({
        ...assistanceStore.orderAssistanceBody,
        username: authStore.user.mail,
        codeCountry: 'IT',
        files: files.value
      })
      if (successCreateAssistance.value) {
        clearAllFiles()
        assistanceStore.saveAssistance = false;        
        assistanceStore.initializeOrder()
        return onSuccess()
      }
      assistanceStore.saveAssistance = false;
    }
      
  }

  watch(
    () => files.value.length,
    (f) => {
      assistanceStore.order.numberOfFiles = f
    }, { immediate: true },
  )
  watch(
    () => files.value,
    (f) => {
      assistanceStore.order.dimensionOfFiles = getSumFileDimension(f)  
      assistanceStore.order.extensionNotSupported = isExtensionNotSupported(f)   
    }, { immediate: true },
  )

  return {
    selectOrderNumber,
    checkCode,
    moveBack,
    moveAhead,
  }
}