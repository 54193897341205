<template>
  <div v-if="isEmptyObject(props.checkData)">
    <div class="col-sm-12 fs-18 font-weight-500 m-0 p-0 lh-1 text-center">
      {{ props.message }}
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  checkData: {
    type: Object,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
});

const isEmptyObject = (obj) => {
  return obj == undefined || obj == null || Object.keys(obj).length == 0;
};
</script>
