import{ref} from 'vue';
import {http,headers} from '../services/http.js';
export  function useWashes(){


const washes = ref([]);
const showWashClass = ref("");
const loading = ref([]);
const wash = ref([]);

const loadWashes = () =>{
    loading.value = true;
    const url = "/orders/rows";
    const header = {...headers.json, ...headers.cors, ...headers.apiKey, ...headers.language };
    http(process.env.VUE_APP_BASE_URL,header)
    .get(url)
    .then(res => {
        washes.value = [...res.data]
    })
    .catch(error => {
        if (error.response) {
            console.log("Please check your internet connection.");
        }
    })
    .finally(() => {
        loading.value =  false
    });
}

const openWashCanvans = (dataWash) => {
     wash.value = dataWash;
}

const  openWash = () =>{
    
    return showWashClass.value = "show";
}
const  closeWash = () =>{
    return showWashClass.value = "";
}

    return{
        wash,
        loading,
        washes,
        showWashClass,
        loadWashes,
        openWash,
        closeWash,
        openWashCanvans,
        
    }
}