export default function (payload) {
  /**
   * @return {Promise<File>}
   */
  const takeScreenshot = () =>
    new Promise((resolve, reject) => {
      const video = document.querySelector(`#${payload.videoId}`)
      const canvas = document.querySelector(`#${payload.canvasId}`)

      if (!video)
        return reject(new Error('No video element has been initialized.'))

      if (!canvas)
        return reject(new Error('No canvas element has been initialized.'))

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const ctx = canvas.getContext('2d')

      if (!ctx)
        return reject(new Error('Cannot get canvas context'))

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

      return canvas.toBlob((img) => {
        if (!img)
          return reject(new Error('Video did not capture any image.'))

          const snapshot = new Blob([img], { type: 'image/png' })

          resolve(new File([snapshot], `snapshot-${new Date().getTime()}.png`, {
            type: 'image/png',
          }))
      })
    })

  return { takeScreenshot }
}
