export default {
  "seo": {
    "index": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone de soutien - poltronesofà"])}
    }
  },
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "commons": {
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])}
  },
  "pages": {
    "login": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous besoin d'assistance ?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous créer un compte ?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "confirm-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription réussie"])},
      "recover-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas reçu d'e-mail de confirmation"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de compte"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai déjà un compte"])},
      "back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la connexion"])},
      "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
      "report-a-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])}
    },
    "register": {
      
    },
    "home": {
      "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue ", _interpolate(_named("nameOfUser"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment pouvons-nous vous aider ?"])},
      "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par sujet"])},
      "button": {
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
        "assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes demandes d'assistance"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
        "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions de lavage"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'accueil"])},
        "download-warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le manuel"])},
        "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
        "home-area-riservata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d'aide"])}
      }
    },
    "orders": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
      "total-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur totale commande"])},
      "to-pay-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde à régler"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande d'assistance"])},
      "ticket-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes ouvertes"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
      "no-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "go-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller aux messages"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
      "add-order-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une commande à la liste"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
      "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
      "payed-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande payée correctement"])}
    },
    "order": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande n°."])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "show-ticket-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter les demandes d'assistances ouvertes"])},
      "message-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverez la liste de vos achats ci-dessous"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num. d'article"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "code-article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'article"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])},
      "wash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavage"])},
      "warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande d'assistance"])},
      "show-all-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter toutes les commandes"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])}
    },
    "order-payment-response": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
      "error-contact-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de l'enregistrement du paiement. Contacter le magasin de référence"])}
    },
    "assistances": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes demandes d'assitances"])},
      "ticket-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de demande"])},
      "data-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
      "order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
      "no-assistances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'assistance ouverte"])},
      "inserita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entré"])},
      "respinta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejetée"])},
      "chiusa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrure"])},
      "inlavorazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
      "inattesadielementi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente d'éléments"])},
      "invisione (attesa rientro)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la vision"])}
    },
    "open-assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande d'assistance"])},
      "assistance-hour-not-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'assistance n'est pas disponible entre 01h00 et 04h00."])},
      "global-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez créer une demande d'assistance ? Consultez <a href='/home' style='text-decoration: underline;'>nos questions</a> fréquentes.<br />Il est également recommandé d'introduire une nouvelle demande d'assistance à partir d'un téléphone portable."])},
      "no-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de commandes sélectionnables. Veuillez vérifier qu'un service n'a pas déjà été ouvert."])},
      "step-one": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la commande de l'achat"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la commande"])},
        "find-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne trouvez pas la commande dans la liste ci-dessus ? Cliquez ici et suivez nos instructions pour la récupérer et pour l'associer à votre compte."])},
        "has-assistances-opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, il y a un support ouvert sur cette commande. Il n'est pas possible de continuer. <u style='color: #d41317'>Rendez-vous sur votre page d'assistance</u>"])},
        "is-not-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande n'est pas éligible au service car elle n'a pas été livrée. Veuillez contacter le magasin concerné pour obtenir des informations sur la livraison."])}
      },
      "step-two": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'article"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'article pour lequel vous voulez créer une demande d'assistance"])}
      },
      "step-three": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrire le problème et joindre photos et vidéos"])},
        "label-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Décrivez votre probème et joignez un minimum de ", _interpolate(_named("maxFiles")), " photos et vidéos (maximum ", _interpolate(_named("maxSize")), ").\nLes fichiers suivants sont autorisés : ", _interpolate(_named("ext")), "."])},
        "label-relax-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cas de dysfonctionnement d'un mécanisme de relaxation, veuillez nous envoyer une vidéo montrant le problème."])},
        "label-description-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
        "label-warning-file-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, au moins 3 fichiers doivent être téléchargés."])},
        "label-warning-file-dimension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, la taille du fichier dépasse la limite de 30MB au total"])},
        "label-warning-file-extension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veuillez noter que les extensions de fichiers suivantes ne sont pas prises en charge : ", _interpolate(_named("ext"))])}
      },
      "step-four": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification"])},
        "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier les données et soumettre"])},
        "sub-title-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier les données saisies et envoyer la demande"])}
      }
    },
    "wash": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions de lavage"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu principal"])}
    },
    "faq-category": {
      "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons trouvé ces solutions possibles"])},
      "button-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous revenez au menu principal"])},
      "call-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne parvenez pas à résoudre ce problème, nous vous recommandons de contacter le magasin où vous avez effectué votre achat."])}
    },
    "profile": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
      "second-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
      "label-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la langue par défaut"])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])}
      },
      "text-accordion": {
        "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser cette option pour changer la langue par défaut de votre espace privé"])}
      }
    }
  },
  "header": {
    "menu": {
      "poltronesofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poltronesofà"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu principal"])},
      "my-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
      "my-tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes demandes d'assistance"])},
      "open-ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande d'assistance"])},
      "maintenance-booklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livret d'entretien"])},
      "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions fréquentes"])},
    "text-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poltronesofà S.p.A., C.F. e P.IVA. 03613140403, Valsamoggia (BO) - Loc. Crespellano, Via Lunga n. 16, Registro delle Imprese di Bologna REA BO – 462239, Capitale sociale i.v. Euro 250.000,00 "])},
    "text-link-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
    "text-link-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "text-link-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])},
    "text-link-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
    "text-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © poltronesofa S.p.A 2023 - Tous droits réservés"])}
  },
  "error": {
    "form-validation": {
      "name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ prénom est obligatoire"])},
      "lastname-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ nom est obligatoire"])},
      "telephone-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ numéro de téléphone est obligatoire"])},
      "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ adresse e-mail est obligatoire"])},
      "email-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse mail saisie n'est pas valide "])},
      "email-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse mail doit correspondre"])},
      "email-confirm-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmation n'est pas valide"])},
      "password-lenght": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au minimum 8 caractères"])},
      "password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est requis"])},
      "old-password-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ancien mot de passe est requis"])},
      "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe doivent correspondre"])},
      "privacy-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est nécessaire d'accepter la politique de confidentialité"])},
      "passwords-match-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est nécessaire de confirmer le mot de passe"])}
    }
  },
  "components": {
    "faq": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces informations vous ont-elles été utiles ?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
      "open-assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une demande d'assistance"])}
    },
    "open-assistance": {
      "open": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Joindre un minimum de ", _interpolate(_named("maxFiles")), " photos et vidéos"])}
    },
    "store-detail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du magasin : "])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ville :"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse :"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["téléphone :"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
      "indications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indications :"])},
      "timetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horaires :"])},
      "go-to-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aller à la carte"])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appeler le magasin"])}
    },
    "assistance-chat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de service: "])},
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour: "])},
      "text-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre message"])},
      "response-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez une réponse dans les 4 jours ouvrables."])}
    },
    "order-row-detail": {
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "other-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres données"])},
      "article-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code article"])}
    },
    "order-row-wash": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions de lavage"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres informations utiles"])},
      "composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition"])}
    }
  },
  "payment_status_axepta_fr": {
    "0000": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation terminée avec succès"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement a été effectué avec succès. Votre commande est soldee.\nPour continuer à naviguer, veuillez retourner au menu principal."])}
    },
    "0200": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'authentification"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données introduites ne sont pas correctes. Veuillez réessayer."])}
    },
    "0351": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement non autorisé"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépassement du plafond de la carte de paiement"])}
    },
    "0305": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation refusée"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "0392": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation refusée"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de système dans le centre de données de la banque. Veuillez réessayer plus tard."])}
    },
    "4005": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation refusée"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai de réception."])}
    }
  },
  "payment_status_be": {
    "authorized": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation terminée avec succès"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement a été effectué avec succès. La facture est entièrement payée. Pour continuer à naviguer, veuillez retourner au menu principal."])}
    },
    "canceled": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'authentification"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données introduites ne sont pas correctes. Veuillez réessayer."])}
    },
    "3ds_authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'authentification 3DS"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification 3D-secure a échoué : la transaction a été interrompue."])}
    },
    "token_expired": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton expiré"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeton a expiré"])}
    },
    "transaction_aborted": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction rompue"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'opération a été interrompue par le payeur."])}
    },
    "validation_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la validation"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "amount_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant non valide"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantité ne respecte pas les restrictions pour cette action. Par exemple, elle peut dépasser la quantité de captures autorisée."])}
    },
    "authentication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'authentification"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe saisi est erroné."])}
    },
    "card_check_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de carte ou CVC invalide"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. Veuillez saisir le numéro de carte ou de CVC correct."])}
    },
    "card_cvc_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC non valide"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. Veuillez saisir le code CVC correct."])}
    },
    "card_cvc_required": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC non inséré mais nécessaire"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. Veuillez saisir le code CVC."])}
    },
    "communication_failed": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La comunicazione con il processore è fallita."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "currency_invalid": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code devise incorrect"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La devise ne correspond pas à la devise de la transaction de référence."])}
    },
    "permission_denied": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission refusée"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "transaction_declined": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie geweigerd"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "update_card_information": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des informations relatives à la carte"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données de la carte doivent être mises à jour pour que le paiement puisse être effectué avec succès."])}
    },
    "check_store_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de l'enregistrement du paiement."])}
  },
  "modal": {
    "assistance": {
      "add-order-number": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande correctement importée"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande est maintenant visible dans votre espace réservé."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de saisie des commandes"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordre ne peut pas être récupéré"])}
        }
      },
      "post-assistance": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance incluse"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous confirmons que votre assistance a été saisie avec succès."])}
        },
        "error-response": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de saisie de service"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de recevoir une réponse du serveur."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de saisie de ticket"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "auth": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de saisie des données"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "expire-session": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification a expiré"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter"])}
    },
    "user": {
      "post-registration-user": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre utilisateur a été enregistré avec succès"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte aux lettres pour compléter votre inscription"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à la connexion"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de création d'un utilisateur"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "send-activation": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été envoyée"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte de réception ou votre courrier indésirable, et cliquez sur le lien que vous trouverez dans le message."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password-email": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été envoyée"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte de réception ou votre courrier indésirable, et cliquez sur le lien que vous trouverez dans le message."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'envoi de données"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "update-language": {
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du changement de langue"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "change-password": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa modification a été couronnée de succès"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la modification du mot de passe"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "error-no-token-username": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander à nouveau l'e-mail de changement de mot de passe."])}
        }
      },
      "check_store_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de l'enregistrement du paiement."])}
    }
  },
  "form": {
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "emailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez votre email dans la commande pour l'importer directement"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe"])},
      "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
      "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérer l'e-mail et controler la boîte mail. Vous recevrez un e-mail avec toutes les instructions."])},
      "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer le mot de passe"])},
      "email-not-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous venez de créer votre compte et que vous n'avez pas encore reçu l'e-mail, veuillez controler dans votre boîte spam ou insérer ici l'e-mail que vous avez utilisé pour créer votre compte et vérifier votre boîte de réception"])},
      "resend-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la confirmation à nouveau"])},
      "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédeer avec la création du compte pour recevoir des mises-à-jour sur vos commandes ou vos demandes d'assistance"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous créer un compte ?"])},
      "contant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de portable"])},
      "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'e-mail"])},
      "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancer"])},
      "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir en arrière"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvetage en cours..."])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
      "warranty-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de garantie"])},
      "warrantyCodeTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "add-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associer à "])},
      "read-understood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et compris"])},
      "link-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le lien pour signaler un problème"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ est obligatoire"])},
      "privacy": {
        "part-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je déclare avoir lu et compris,"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
        "part-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément aux articles 13 et 14 du règlement (UE) 2016/679"])}
      }
    },
    "placeholder": {
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez votre e-mail"])}
    },
    "field": {
      "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données"])}
    }
  }
}