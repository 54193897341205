import { defineStore } from 'pinia';
import Swal from 'sweetalert2';
import {http,headers} from '../services/http.js';
import { helpers} from '../helpers/helpers.js';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        //user: JSON.parse(localStorage.getItem('user')),
        user: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        lang: localStorage.getItem('lang'),
        isSubmitting: false
        //returnUrl: null
        // eslint-disable-next-line no-unused-vars
    }),   
    getters:{
        getLang:(state) =>{
            return state.lang;
        }
    },
    actions: {
        async login(username, password, redirect="") {      
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            this.isSubmitting = true;
            const url = "/token_v2";
            const header = {...headers.json, ...headers.xwwwform, ...headers.cors, ...headers.language };
            http(process.env.VUE_APP_BASE_URL, header)
            .post(url, form)
            .then(res => {
                    const { parseJwt } = helpers();
                    const user = parseJwt(res.data.access_token);
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('lang', this.matchLanguage(user.lang));
                    localStorage.setItem('token', res.data.access_token);
                    const expirationDate = new Date();
                    expirationDate.setDate(expirationDate.getDate() + 1);
                    const cookievalue = "username="+user.mail+"&nome="+user.name
                    const domain = ".poltronesofa.com";
                    document.cookie = `poltronesofa.com.UserSettings=${cookievalue}; domain=${domain};path=/; expires=${expirationDate.toUTCString()}`;
                    this.user = user;
                    this.token = res.data.access_token;
                    this.lang = this.matchLanguage(user.lang);
                    //router.push('/home');
                    //const url = new URL('/home', window.location.origin)
                    //window.location.href = url.toString()
                    if (redirect != null && redirect != ""){                        
                        window.location.assign(redirect);                                               
                    } else {
                        window.location.assign("/home"); 
                    }
                    
            })           
            .catch(error => {
                if (error) {
                    Swal.fire({
                     title: error.response.data.title,
                     html: error.response.data.message,
                     icon: error.response.data.errorLevelStr.toLowerCase()
                 });
                 }
                if (error.response) {
                    console.log("Please check your internet connection.");
                }
            })
            .finally(() => {
                this.isSubmitting = false;
            });
        },
        async changeLanguage(lang) {
            localStorage.setItem('lang', lang);
        },
        matchLanguage(lang){
            if(lang ==  null){
                return navigator.language.substring(0,2)
            }
            else if(lang=='00'){
                return 'it'
            }
            return lang.toLowerCase();
        },
        logout() {
            this.user = null;
            this.lang = null;

            this.token = null;            
            //this.returnUrl = null;
            localStorage.removeItem('token');
            localStorage.removeItem('lang');
            localStorage.removeItem('user');
            this.router.push('/');
        }
    },
    watch: {
        token (token) {
            localStorage.setItem('token', token);
        }
    }
    /*
    */
});
