import { ref } from 'vue'
import useConstraints from './useConstraints'

export default function (payload) {
  const { initConstraints, constraints } = useConstraints(payload)

  const cameraStream = ref(null)

  const stopCameraStream = () => new Promise((resolve) => {
    cameraStream.value?.getTracks().forEach((t) => {
      if (t.readyState === 'live')
        t.stop()
    })

    const pullStop = setInterval(() => {
      const isLive = cameraStream.value?.getTracks().reduce((s, t) => s || t.readyState === 'live', false) ?? false

      if (!isLive) {
        cameraStream.value = null
        clearInterval(pullStop)
        return resolve()
      }
    }, 100)
  })

  const startCameraStream = async () => {
    if (cameraStream.value)
      await stopCameraStream()

    await initConstraints()

    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      try {
        cameraStream.value = await navigator.mediaDevices.getUserMedia(constraints.value)
      }
      catch (error) {
        throw new Error('Cannot Initialize media stream')
      }
    }
  }

  return {
    cameraStream,
    startCameraStream,
    stopCameraStream,
  }
}
