import { computed, ref } from 'vue'
import { getFileExtention } from "../utilities/file.js";
import config from "../config";

export default function () {
  const files = ref([])

  /**
   * 
   * @param {File|File[]|{resource: File, thumbnail?: File | undefined }} data 
   */
  const addToFiles = (data) => {

    if (Array.isArray(data)) {
      data.forEach((d) => {
        files.value.push({ resource: d })
      })
      return
    }

    if ('resource' in data) {
      files.value.push({ ...data })
      return
    }
    console.log(files.value);
    files.value.push({ resource: data })
  }

  /**
   * 
   * @param {number} idx 
   */
  const removeFromFiles = (idx) => {
    if (files.value.length > idx)
      files.value.splice(idx, 1)
  }

  const clearAllFiles = () => {
    files.value = []
  }

  /**
   * 
   * @param {File} file 
   */
  const getImagePreview = (file) => {
    if (file.type.match(/^image/))
      return URL.createObjectURL(file)

    if (file.type.match(/^video/))
      return '/images/icons/video.png'

    if (file.type.match(/pdf/))
      return '/images/icons/pdf.png'

    return '/images/not-found.png'
  }

  const getSumFileDimension= (files)=>{
    let sumDim = 0;
    for(let i = 0; i < files.length; i++){
      sumDim += files[i].size;
    }
    return sumDim;
  }

  const isExtensionNotSupported = (files)=>{
    let usedExtensionNotSupported = [];
    for(let i = 0; i < files.length; i++){
      let ext = getFileExtention(files[i].name.toLowerCase());
      let isSupportedImg = config.assistance.extensions.image.includes(ext);
      let isSupportedVideo = config.assistance.extensions.video.includes(ext);
      let isSupportedFile = config.assistance.extensions.file.includes(ext);
      if (!(isSupportedImg || isSupportedVideo || isSupportedFile)){
        usedExtensionNotSupported.push(ext);
      }     
    }
    return usedExtensionNotSupported;
  }

  const preview = computed(() => (i) => {
    if (typeof i === 'number' && files.value.length > i) {
      const file = files.value[i]
      console.log(file.resource.name);
      return file.thumbnail ? getImagePreview(file.thumbnail) : getImagePreview(file.resource)
    }

    return typeof i === 'number' ? undefined : getImagePreview(i)
  })

  const onlyFiles = computed(() => files.value.map(f => f.resource))

  return {
    files,
    preview,
    onlyFiles,
    addToFiles,
    removeFromFiles,
    clearAllFiles,
    getSumFileDimension,
    isExtensionNotSupported
  }
}
