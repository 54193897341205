<template>
  <div :class="onlyButton ? undefined : 'd-flex align-items-center'" @click="openInput">
    <i
      class="fal fa-plus-circle attachment fs-30 text-primary"
      aria-hidden="true"
      v-bind="$attrs"
    />
    <label
      v-if="!onlyButton"
      class="text-heading text-dark font-weight-600 m-0 cursor-pointer"
    >
      {{
        $t("components.open-assistance.open", {
          maxFiles: config.assistance.maxFiles,
        })
      }}
    </label>
  </div>
</template>

<script>
import { defineComponent, defineProps } from "vue";

export default defineComponent({
  inheritAttrs: false,
});
</script>

<script setup>
const props = defineProps({
  onlyButton: Boolean,
  inputId: String,
});

const openInput = () => {
  const i = document.querySelector(`#${props.inputId}`);
  if (i) {
    i.click();
  }
};
</script>

<!-- <script setup>
import { defineProps } from "vue";
const props = defineProps({
  class: String,
  onlyButton: Boolean,
});

const addFiles = () => {
  document.getElementById("multiple-image-preview-files").click();
};
</script> -->
