<template>
  <Head>
    <title>Area supporto di poltronesofà</title>
    <meta
      name="description"
      content="Scopri i nostri divani di qualità e le nostre poltrone fatti a mano in Italia. Configura il divano o la poltrona in base al tuo gusto e acquistali a prezzi convenienti."
    />
    <link rel="icon" href="/images/icons/favicon.png" />
  </Head>

  <main id="content"><router-view /></main>
</template>

<script>
import { createHead } from "@vueuse/head";
import { useI18n } from "vue-i18n";
import { pinia } from "./pinia";

export default {
  name: "App",
  setup() {
    const head = createHead();

    const { t } = useI18n();
    pinia.use(({ store }) => {
      store.t = t;
    });

    return { head };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-variables";
@import "@/assets/scss/theme-variables";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "@/assets/scss/components";
@import "@/assets/scss/custom";
</style>
