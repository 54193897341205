<script setup>
import { defineEmits, defineProps } from "vue";
import { helpers } from "../../../helpers/helpers.js";
import { useAuthStore } from "@/stores";
import { storeToRefs } from "pinia";
const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);

function manteinanceBooklet (){
  let langUrl = "it";
  if (authUser.value.lang != null){
    switch (authUser.value.lang.trim()) {    
      case "FR":
      case "EN":
      langUrl = authUser.value.lang.toLowerCase();
        break
      case "DE":
      case "NL":
      langUrl = "en";
        break;
    } 
  } else {
    if (navigator.language != null && navigator.language.split('-') != null && navigator.language.split('-')[0] != null){
      let langTemp = navigator.language.split('-')[0];
      switch (langTemp){
        case "en":
        case "fr":
        case "it":
          langUrl = langTemp;
      }
    }
  }  
  window.open('https://www.poltronesofa.com/public/pdf/LibrettoManutenzione_'+langUrl+'.pdf', '_blank');
}

const emitCloseCanvans = defineEmits(["closeCanvans"]);
defineProps(["text"]);
const { enabledscroll } = helpers();
</script>

<template>
  <div class="canvas-sidebar menu-canvas">
    <div
      class="canvas-overlay"
      @click="
        emitCloseCanvans('closeCanvans');
        enabledscroll();
      "
    ></div>
    <div class="h-100">
      <div class="card border-0 h-100">
        <div class="px-6 text-right pt-4">
          <span
            class="canvas-close d-inline-block fs-24 mb-1 ml-auto lh-1 text-primary"
            @click="
              emitCloseCanvans('closeCanvans');
              enabledscroll();
            "
            ><i class="fal fa-times"></i
          ></span>
        </div>

        <div class="card-body px-6 pt-0 overflow-y-auto">
          <div class="media mb-6">
            <div class="media-body mt-2 mr-10">
              <div class="row mx-n2">
                <div
                  class="col-12 px-1"
                  v-for="(menu, index) in text.menu"
                  :key="menu.id"
                > 
                  <div v-if ="menu.id == 6">
                    <div class="media align-items-center mb-3 pb-2 mb-2 fs-16 text-nowrap bg-white border-bottom" style="cursor: pointer;" @click="manteinanceBooklet()" target="_blank">
                      <img :src="menu.icon" alt="Libretto" class="w-30px" />
                        <div class="media-body ml-5 text-left">
                          <h5 class="m-0 fs-16">{{ $t("header.menu.maintenance-booklet") }}</h5>
                        </div>
                    </div>
                  </div>
                  <div v-else-if ="menu.id == 5">
                    <a @click="authStore.logout()" href="#" >
                      <div class="media align-items-center mb-3 pb-2 mb-2 fs-16 text-nowrap bg-white" :class="{ 'border-bottom': index !== text.menu.length - 1 }">
                        <img :src="menu.icon" alt="Libretto" class="w-30px" />
                        <div class="media-body ml-5 text-left">
                          <h5 class="m-0 fs-16">{{ $t("header.menu.logout") }}</h5>
                        </div>
                        
                      </div>
                    </a> 
                  </div>                                 
                  <div v-else>
                    <router-link
                      :to="menu.link"
                      @click="
                        emitCloseCanvans('closeCanvans');
                        enabledscroll();
                      "
                    >
                      <div
                        class="media align-items-center mb-3 pb-2 mb-2 fs-16 text-nowrap bg-white"
                        :class="{ 'border-bottom': index !== text.menu.length - 1 }"
                      >
                        <img :src="menu.icon" alt="Libretto" class="w-30px" />
                        <div class="media-body ml-5 text-left">
                          <h5 class="m-0 fs-16">{{ menu.label }}</h5>
                        </div>
                      </div>
                    </router-link>
                  </div>                  
                </div>
              </div>                        
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
