import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { computed, ref } from 'vue'

export const useAssistanceStore = defineStore('assistance', () => {
  const saveAssistance = ref(false);
  const order = useSessionStorage('assistance', {
    step: 1,
    orderNumber: '',
    orderRowCodes: [],
    description: '',
    numberOfFiles: 0,
    dimensionOfFiles: 0,
    extensionNotSupported: [],
    faqId: '',
    hasAssistancesOpened: false,
    isDelivered: true
  })

  const stepIsComplete = computed(() => (step) => {
    let complete = true
    if (step === 1){
      complete = complete && !!order.value.orderNumber && !order.value.hasAssistancesOpened && order.value.isDelivered
    }
    if (complete && step === 2)
      complete = complete && order.value.orderRowCodes.length > 0
    if (complete && step >= 3){
      complete = complete && order.value.numberOfFiles >= 3 && !!order.value.description && order.value.dimensionOfFiles <= 30*1024*1024 && (order.value.extensionNotSupported.length == 0)
    }
      

    return complete
  })

  const currentStepComplete = computed(() => stepIsComplete.value(order.value.step))

  const orderAssistanceBody = computed(() => {
    if (order.value.step !== 4)
      return null

    const [orderYear, orderType, orderNro] = order.value.orderNumber.split("/", 3)

    const payload = {
      orderNro,
      orderType,
      orderYear,
      rows: order.value.orderRowCodes.join(","),
      description: order.value.description,
    }

    if (order.value.faqId)
      payload.faqId = order.value.faqId

    return payload
  })


  const hasRowCode = computed(() => 
  /**
   * 
   * @param {string|number} code 
   */
  (code) => order.value.orderRowCodes.includes(`${code}`))

  const initializeOrder = () => {
    order.value = {
      step: 1,
      orderNumber: '',
      orderRowCodes: [],
      description: '',
      numberOfFiles: 0,
      dimensionOfFiles: 0,
      extensionNotSupported: [],
      faqId: '',
    }
  }

  /**
   * 
   * @param {number} value 
   */
  function setStep(value) {
    if (value < 1 || value > 4)
      throw new Error('Step value out of range.')
    if (value > 1 && !stepIsComplete.value(1))
      throw new Error('Must select order number.')

    if (value > 2 && !stepIsComplete.value(2))
      throw new Error('You must select at least 1 order code.')

    if (value > 3 && !stepIsComplete.value(3))
      throw new Error('Must have a description and at least 3 files.')

    order.value.step = value
  }

  /**
   * 
   * @param {string} value 
   */
  const setOrderNumber = (value, hasAssistancesOpened = false, isDelivered = true) => {
    /*
    if (!value.match(/^(19)|(20)[0-9]{2}\/[0-9a-zA-Z]{2}\/[0-9]$/))
      throw new Error(`${value} has not valid patters ##/XX/####`)
*/
    debugger;
    order.value.orderNumber = value
    order.value.hasAssistancesOpened = hasAssistancesOpened
    order.value.isDelivered = isDelivered
  }

  /**
   * 
   * @param {string} code 
   */
  const addRowCode = (code) => {
    if (code.match(/^.*-.*$/)) {
      const idx = code.indexOf('-')
      code = code.substring(idx+1)
    }
      
    if (!order.value.orderRowCodes.includes(code))
      order.value.orderRowCodes.push(code)
  }


  /**
   * 
   * @param {string} code 
   */
  const removeRowCode = (code) => {
    const idx = order.value.orderRowCodes.findIndex(c => c === code)
    if (idx >= 0)
      order.value.orderRowCodes.splice(idx, 1)
  }

  /**
   * 
   * @param {string | string[]} data 
   */
  const addRowCodes = (data) => {
    if (typeof data === 'string')
      return addRowCode(data)
    data.forEach(addRowCode)
  }

  /**
   * 
   * @param {string | string[]} data 
   */
  const removeRowCodes = (data) => {
    if (typeof data === 'string')
      return removeRowCode(data)

    data.forEach(removeRowCode)
  }

  return {
    order,
    currentStepComplete,
    saveAssistance,
    stepIsComplete,
    hasRowCode,
    orderAssistanceBody,
    initializeOrder,
    setStep,
    setOrderNumber,
    addRowCodes,
    removeRowCodes,
  }
})
