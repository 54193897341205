import { ref, computed } from 'vue'
import { defu } from 'defu'

export default function ({ cameraLabel }) {
  const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

  const baseContraints = computed(() => ({
    video: {
      height: {
        min: 600,
        ideal: 720,
        max: 720,
      },
      facingMode: isMobile
        ? {
            exact: 'environment',
          }
        : undefined,
    },
    audio: true,
  }))

  const constraints = ref(defu({}, baseContraints.value))
  const selectedDeviceId = ref(null)
  const devicesInfo = ref([])

  function updateSelectedDevice() {
    if (devicesInfo.value.length === 1) {
      selectedDeviceId.value = devicesInfo.value[0].deviceId
      return
    }

    const cameraId = isMobile
    ? devicesInfo.value.find(d => d.label.match(/back/))?.deviceId
    : devicesInfo.value.find(d => d.label.match(cameraLabel))?.deviceId

    if (!cameraId) {
      console.error(`Cannot find camera that match ${cameraLabel}`)
      throw new Error(`Cannot find camera that match ${cameraLabel}`)
    }

    selectedDeviceId.value = cameraId

    constraints.value = defu({
      video: {
        deviceId: cameraId,
      },
      audio: {
        deviceId: cameraId,
      },
    }, baseContraints.value)
  }

  async function initConstraints() {
    try {
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        constraints.value = defu({}, baseContraints)

        await navigator.mediaDevices.getUserMedia(constraints.value)

        const info = await navigator.mediaDevices.enumerateDevices()
        devicesInfo.value = [...info.filter(i => i.kind === 'videoinput')]

        updateSelectedDevice()
        return
      }

      throw new Error('cannot initialize camera.')
    }
    catch (error) {
      throw new Error('cannot initialize camera.')
    }
  }

  return {
    initConstraints,
    constraints,
  }
}
