<script setup>
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

import { helpers } from "../../helpers/helpers.js";
import { useOrders } from "../../use/useOrders.js";
import { useWashes } from "../../use/useWashes.js";
import TitlePage from "../../components/TitlePage";
import LoadingPage from "../../components/LoadingPage";
import WashDetails from "../../layouts/partials/side/WashDetail.vue";
import RowDetails from "../../layouts/partials/side/RowDetails.vue";
import { useAuthStore } from "@/stores";
import { useAssistanceStore } from "@/stores/assistanceStore";

const { scrollToTop, disabledScroll } = helpers();
const authStore = useAuthStore();
const assistanceStore = useAssistanceStore();
const route = useRoute();
const router = useRouter();
const {
  order,
  loading,
  showOrder,
  row,
  showRowClass,
  openRowCanvans,
  openRow,
  closeRow,
  downloadWarranty,
} = useOrders();

const { wash, showWashClass, openWashCanvans, openWash, closeWash } = useWashes();

/**
 *
 * @param {{article: string}} rowCode
 */
const openAssistance = (order) => {
  assistanceStore.initializeOrder();
  assistanceStore.setOrderNumber(order.head.orderNumber,false,true);
  assistanceStore.setStep(2);

  return router.push("/open-assistance");
};

showOrder(route.params.year, route.params.type, route.params.nro);
</script>

<template>
  <TitlePage :title="$t('pages.order.title')" />
  <LoadingPage :loading="loading" />

  <section class="pb-9" v-if="order && order.head">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-1">
          <h2 class="fs-md-25 fs-20 font-weight-800">
            {{ $t("pages.order.order-number") }} {{ order.head.orderNumber }}
          </h2>
          <div class="font-weight-bold mb-1">
            <i class="far fa-map-marker text-primary pr-1"></i>
            <a
              :href="'https://maps.google.com/?q=' + order.head.store.details.address"
              target="_blank"
              class="mr-4 mb-2 text-capitalize"
              >{{
                $filters.capitalizeFirstLetter(
                  order.head.store.details.name.toLowerCase()
                )
              }}</a
            >
          </div>
          <div class="media-body pb-1" style="overflow: auto; white-space: nowrap">
            <ul class="list-inline d-flex mb-0 d-inline-block justify-content-center">
              <li
                class="list-inline-item h-100 list-group-item d-inline-block"
                v-if="order.head.date"
              >
                <p class="fs-18 font-weight-600 m-0 p-0 lh-1">
                  {{ $t("pages.order.data") }}
                </p>
                <span class="fs-16 fw-bold fs-6 text-gray-400">{{
                  moment(order.head.date).format("DD/MM/YYYY")
                }}</span>
              </li>
              <li
                class="list-inline-item h-100 list-group-item d-inline-block"
                v-if="order.head.total"
              >
                <p class="fs-18 font-weight-600 m-0 p-0 lh-1">
                  {{ $t("pages.order.total") }}
                </p>
                <span class="fs-16 fw-bold fs-6 text-gray-400"
                  >{{ order.head.currencySymbol }} {{ order.head.total }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>    
    <div class="text-center mt-2" v-if="order.head.support.hasOpenAssistances">
      <router-link
        to="/assistances"
        @click="scrollToTop"
        class="btn btn-outline-success btn-lg rounded-lg rounded-50"
        >{{ $t("pages.order.show-ticket-open") }}</router-link
      >
    </div>
    <div class="container mt-5">
      <p class="mb-4">
        {{ $t("pages.order.message-label") }}
      </p>
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-12" v-for="row in order.rows" :key="row.row">
              <div
                class="card p-4 border shadow-hover-1 mb-6 flex-column flex-lg-row no-gutters"
              >
                <!--
                    <div class="col-lg-3 mr-lg-5 col-md-6 col-8 mx-auto mb-5">
                      <img
                        :src="row.image"
                        class="card-img"
                        @error="row.image = '/images/not-found.png'"
                      />
                    </div>
-->
                <div class="media-body mt-lg-0">
                  <h5 class="mt-2 mb-3 lh-15 fs-md-20 fs-18">
                    {{ $filters.capitalizeFirstLetter(row.title.toLowerCase()) }}
                  </h5>

                  <hr class="my-2" />
                  <div class="row">
                    <div class="col-lg-4 col-6 mb-5">
                      <ul class="list-inline d-flex mb-0 d-inline-block">
                        <li class="list-inline-item">
                          <span
                            class="fs-md-18 fs-14 font-weight-600 m-0 p-0 lh-1 d-block"
                            >{{ $t("pages.order.code-article") }}</span
                          >
                          <span
                            class="fs-md-16 fs-14 fw-bold fs-6 text-gray-400 d-block"
                            >{{ row.article }}</span
                          >
                        </li>
                      </ul>
                    </div>

                    <div class="col-3">
                      <ul class="list-inline d-flex mb-0 d-inline-block">
                        <li class="list-inline-item">
                          <span
                            class="fs-md-18 fs-14 font-weight-600 m-0 p-0 lh-1 d-block"
                            >{{ $t("pages.order.quantity") }}</span
                          >
                          <span
                            class="fs-md-16 fs-14 fw-bold fs-6 text-gray-400 d-block"
                            >{{ row.qty }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col-3">
                      <ul class="list-inline d-flex mb-0 d-inline-block">
                        <li class="list-inline-item">
                          <span
                            class="fs-md-18 fs-14 font-weight-600 m-0 p-0 lh-1 d-block"
                            >{{ $t("pages.order.price") }}</span
                          >
                          <span class="fs-md-16 fs-14 fw-bold fs-6 text-gray-400 d-block"
                            >{{ order.head.currencySymbol }} {{ row.articlePrice }}</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr class="my-2" />
                  <ul class="p-0 m-0">
                    <li
                      class="d-flex align-items-center p-0"
                      v-for="comp in row.composition"
                      :key="comp.id"
                    >
                      <span class="bullet mr-4"></span>{{ comp }}
                    </li>
                  </ul>
                  <div class="position-relative py-md-1 py-6">
                    <div class="d-flex align-items-center">
                      <div class="media-body" style="overflow: auto; white-space: nowrap">
                        <ul class="d-flex nav row-product d-inline-block pb-3">
                          <li class="nav-item mr-5 d-inline-block">
                            <span
                              @click="
                                disabledScroll();
                                openRow();
                                openRowCanvans(row);
                              "
                              class="fs-16 fs-6 text-success-400 cursor-pointer text-primary"
                              >{{ $t("pages.order.detail")
                              }}<span class="heading-divider-xs"></span
                            ></span>
                          </li>
                          <li
                            class="nav-item mr-5 d-inline-block"
                            v-if="row.washingInstructions"
                          >
                            <span
                              @click="
                                disabledScroll();
                                openWash();
                                openWashCanvans(row);
                              "
                              class="fs-16 fs-6 text-success-400 cursor-pointer text-primary"
                              >{{ $t("pages.order.wash") }}
                              <span class="heading-divider-xs"></span
                            ></span>
                          </li>

                          <li class="nav-item mr-5 d-inline-block">
                            <span
                              class="fs-16 fs-6 text-success-400 cursor-pointer text-primary"
                              @click="downloadWarranty(row, authStore.user.mail)"
                              >{{ $t("pages.order.warranty")
                              }}<span class="heading-divider-xs"></span
                            ></span>
                          </li>
                          <!--
                              <li class="nav-item mr-5 d-inline-block ml-auto">
                                <a
                                  href="order.html"
                                  class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                                  >Apri un ticket</a
                                >
                              </li>
                              -->
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="text-center mt-md-0 mt-2">
                    <button
                      v-if="!order.head.support.hasOpenAssistances"
                      @click="openAssistance(order, row)"
                      active-class="active"
                      class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
                    >
                      {{ $t("pages.order.open-ticket") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pb-7 text-center mt-7">
    <router-link
      to="/orders"
      class="btn btn-outline-primary btn-lg rounded-lg rounded-50"
      @click="scrollToTop"
      >{{ $t("pages.order.show-all-order") }}</router-link
    >
  </section>
  <RowDetails :class="showRowClass" :row="row" @closeRow="closeRow" />
  <WashDetails :class="showWashClass" :wash="wash" @closeWash="closeWash" />
</template>
