import { onMounted, onUnmounted } from 'vue';

export default {
  name: 'clickOutside',
  inserted(el, binding) {
    const closeDiv = binding.value;
    console.log("invoco chiudo da direttiva");
    const handleClick = e => {
      if (!el.contains(e.target)) {
        closeDiv();
      }
    };
    onMounted(() => {
      document.addEventListener('click', handleClick);
    });
    onUnmounted(() => {
      document.removeEventListener('click', handleClick);
    });
  },
};
