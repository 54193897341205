<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores";
import { useFaqs } from "../use/useFaqs.js";

import FaqAccordion from "../components/FaqAccordion.vue";
import FaqDetails from "../layouts/partials/side/FaqDetail.vue";

const authStore = useAuthStore();

const { faqs, faq, allFaqs, openFaq, closeFaq, showFaqClass, openFaqCanvans } = useFaqs();
allFaqs();

const searchQuery = ref();
console.log(searchQuery);

const showTab = (index) => {
  if (index == 0) {
    return "active show";
  }
};

/*
const handlerLinkWarranty = computed(() => {
  return (
    "https://www.poltronesofa.com/public/pdf/LibrettoManutenzione_" +
    authStore.lang +
    ".pdf"
  );
});
*/
</script>
<template class="bg-cover">
  <section class="d-flex flex-column bg-search" v-if="authStore.user.assistances >= 1">
    <div class="container container-xxl mt-5">
      <div class="row">
        <div class="col-sm-12 col-sm-12 text-md-right text-center">
          <router-link
            to="/assistances"
            class="btn btn-outline-primary btn-md rounded-50 fs-16 text-nowrap shadow-hover-lg-1"
            >Vedi le tue assistenze
          </router-link>
        </div>
      </div>
    </div>

    <div class="d-flex pt-4 pb-3 mb-0 mt-1 h position-relative">
      <div class="container container-md text-center mt-4">
        <h1 class="fs-30 fs-lg-42">
          {{ $t("pages.home.title_part_one") }} <br class="d-sm-block d-md-none" />
          {{ $t("pages.home.title_part_two") }}
        </h1>
        <p class="fs-16 fs-md-18">{{ $t("pages.home.sub_title") }}</p>
        <span class="heading-divider mx-auto mb-7"></span>

        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <input
                  v-model="searchQuery"
                  type="text"
                  class="form-control form-control-xl bg-white shadow rounded-resp"
                  id="search"
                  aria-describedby="search"
                  placeholder="Cerca nel nostro centro assistenza"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center">
          <ul
            class="nav nav-tabs"
            id="myTab"
            role="tablist"
            style="
              overflow-x: auto;
              overflow-y: hidden;
              flex-wrap: nowrap;
              padding: 10px 0 10px 0;
            "
          >
            <li
              class="nav-item mx-2"
              role="presentation"
              v-for="faq in faqs"
              :key="faq.id"
            >
              <button
                class="btn btn-outline-primary btn-md rounded-50 fs-16 text-nowrap bg-white"
                :class="{ active: faq.id === 1 }"
                :id="`${faq.attributes.value}-tab`"
                data-toggle="tab"
                :data-target="`#${faq.attributes.value}`"
                role="tab"
                :aria-controls="`${faq.attributes.value}`"
                aria-selected="true"
              >
                {{ faq.attributes.value }}
                <span class="badge badge-pill badge-lightV2 ml-2">{{
                  faq.attributes.faqs.data.length
                }}</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content shadow-none container" id="myTabContent" v-if="faqs">
          <div
            class="tab-pane fade"
            :id="`${faq.attributes.value}`"
            role="tabpanel"
            :aria-labelledby="`${faq.attributes.value}-tab`"
            v-for="(faq, index) in faqs"
            :key="faq.id"
            :class="showTab(index)"
          >
            <div class="accordion accordion-02" :class="faq.id ? 'mt-4' : ''">
              <h3 class="text-dark line-height-base font-weight-300">
                <router-link :to="{ path: '/faqs-category/' + faq.id }"
                  >{{ faq.attributes.value }}
                </router-link>
              </h3>
              <span class="heading-divider mb-7"></span>
              <FaqAccordion
                :faqs="faq.attributes.faqs.data"
                :openFaq="openFaq"
                :openFaqCanvans="openFaqCanvans"
                suf="page"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FaqDetails :class="showFaqClass" :faq="faq" @closeFaq="closeFaq" />
</template>

<style scoped>
.swiper-button-prev,
.swiper-button-next {
  --swiper-theme-color: #ffffff;
}

@media (min-width: 992px) {
  .container-sm {
    max-width: 768px;
  }
}
</style>
