<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from 'vue';
import TitlePage from "../components/TitlePage";
import { useI18n } from "vue-i18n";
const { t } = useI18n();


const route = useRoute();
const paymentStatus = ref({order: '', status : '', description: '', descriptionDetail: '', error: ''});

paymentStatus.value.order = route.query.order;
paymentStatus.value.status = route.query.status;
if (route.query.note != null && route.query.note.indexOf('KO-')!== -1){
  paymentStatus.value.error = t("payment_status_be.check_store_error")
}

onMounted(()=>{
  paymentStatus.value.description = t("payment_status_be." + paymentStatus.value.status.toLowerCase() + ".code");
  paymentStatus.value.descriptionDetail = t("payment_status_be." + paymentStatus.value.status.toLowerCase() + ".desc");
});


</script>

<template>
  <TitlePage :title="$t('pages.order-payment-response.title')" />
  <div class="container">    
    <h3 class="fs-4 text-center">{{  paymentStatus.description }}</h3>
    <br>
    <h5 class="fs-4 text-center payment-detail">{{ paymentStatus.descriptionDetail }}</h5>
    <div class="alert alert-danger text-center" role="alert" v-if="paymentStatus.error != null && paymentStatus.error != ''">
      {{ paymentStatus.error }}
    </div>
    <section class="text-center mt-7">
    <router-link
      to="/orders"
      class="fs-16 fw-bold fs-6 text-success-400 rounded-50 btn btn-outline-primary"
      >{{ $t("pages.order.show-all-order") }}</router-link
    >
  </section>
  </div>  
</template>

<style scoped>
.payment-detail {
  white-space: break-spaces;
  line-height: 1.5;
}
</style>